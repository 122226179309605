import { DATATABLES_STATE, RESET_DATATABLES_STATE } from '../../constants';
import { PER_PAGE } from '../../../utils/constants';

const INIT_STATE = {
    dataTableState: {
        myTasks: {
            page: 0,
            rowsPerPage: PER_PAGE,
        },
        collaborators: {
            page: 0,
            rowsPerPage: PER_PAGE,
        },
        projects: {
            page: 0,
            rowsPerPage: PER_PAGE,
        },
        projectTasks: {
            page: 0,
            rowsPerPage: PER_PAGE,
        },
        staffing: {
            page: 0,
            rowsPerPage: PER_PAGE,
        },
        prospects: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        opportunities: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        suppliers: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        purchases: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        clients: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        orders: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        accounts: {
            page: 0,
            params: {
                size: PER_PAGE,
                page: 0,
                sort: 'id,asc',
                'activated.equals': true,
            },
            rowsPerPage: PER_PAGE,
        },
        offers: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        interviews: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        candidates: {
            page: 0,
            params: {
                sort: 'id,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
        applications: {
            page: 0,
            params: {
                sort: 'applicationDate,desc',
                size: PER_PAGE,
                'isFavorite.equals': false,
                'isArchived.equals': false,
            },
            rowsPerPage: PER_PAGE,
        },
        favoriteApplications: {
            page: 0,
            params: {
                sort: 'applicationDate,desc',
                size: PER_PAGE,
                'isFavorite.equals': true,
                'isArchived.equals': false,
            },
            rowsPerPage: PER_PAGE,
        },
        myApplications: {
            page: 0,
            params: {
                sort: 'applicationDate,desc',
                size: PER_PAGE,
                'isFavorite.equals': false,
                'isArchived.equals': false,
            },
            rowsPerPage: PER_PAGE,
        },
        archivedApplications: {
            page: 0,
            params: {
                sort: 'applicationDate,desc',
                size: PER_PAGE,
                'isFavorite.equals': false,
                'isArchived.equals': true,
            },
            rowsPerPage: PER_PAGE,
        },
        newApplications: {
            page: 0,
            params: {
                sort: 'applicationDate,desc',
                size: PER_PAGE,
            },
            rowsPerPage: PER_PAGE,
        },
    },
};

export default function (state = INIT_STATE, action) {
    if (action.type === DATATABLES_STATE) {
        return setDataTableState(state, action.dataTableState);
    } else if (action.type === RESET_DATATABLES_STATE)
        return resetDataTableState(state);
    return state;
}

const setDataTableState = (state, dataTableState) => {
    const updated = {
        ...state.dataTableState,
        ...dataTableState,
    };

    localStorage.setItem('dataTableState', JSON.stringify(updated));

    return {
        ...state,
        dataTableState: updated,
    };
};

const resetDataTableState = (state) => {
    return {
        ...state,
        ...INIT_STATE,
    };
};
