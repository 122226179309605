import {axios} from '../../../index';
import {
    AUTOHIRING_CAMPAIGN_ENDPOINT,
    AUTOHIRING_CANDIDATE_CAMPAIGN_RESULT_ENDPOINT,
    AUTOHIRING_CANDIDATE_INVITATIONS_ENDPOINT
} from '../endpoints';

export const getCampaigns = params => {
    return axios
        .get(
            AUTOHIRING_CAMPAIGN_ENDPOINT,
            {
                params: params || {
                    size: 1000,
                    page: 0
                }
            }
        )
        .then(response => ({
            list: response.data,
            count: parseInt(response.headers['x-total-count'])
        }));
};

export const getCandidateCampaignResults = (id, params) => {
    return axios
        .get(
            `${AUTOHIRING_CANDIDATE_CAMPAIGN_RESULT_ENDPOINT}/${id}` ,
            {
                params: params || {
                    size: 1000,
                    page: 0
                }
            }
        )
        .then(response => ({
            list: response.data,
            count: parseInt(response.headers['x-total-count'])
        }));
};

export const postInvitation = invitation => {
    return axios
        .post(AUTOHIRING_CANDIDATE_INVITATIONS_ENDPOINT, invitation)
        .then(response => response.data);
};
