import React, {Component, Fragment} from 'react';
import NovyHeader from './NovyHeader';
import NovyFooter from './NovyFooter';
import NovySideBar from './NovySideBar';
import {isEmpty} from "lodash";
import history from '../../routes/history';

class NovyLayout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeModule: this.getActiveModule(),
            isSideBarOpen: true
        };

        this.toggleSideBar = this.toggleSideBar.bind(this);
        this.resize = this.resize.bind(this);


    }

    getActiveModule = () => {
        let activeModule = this.props.navs.items.find(nav => this.props.location && (this.props.location.pathname).includes(nav.url));
        return activeModule || this.props.navs.items[1];
    }

    onActiveModuleChanged = () => {
        this.setState({activeModule: this.getActiveModule()});
    }

    logout = (e) => {
        e.preventDefault();
        localStorage.clear();
        this.props.logout && this.props.logout();
        history.push('/login');
    };

    toggleSideBar = () => {
        this.setState({isSideBarOpen: !this.state.isSideBarOpen}, ()=>{
            if(this.props.sideBarChanged)this.props.sideBarChanged(this.state.isSideBarOpen);
        });
    };
    hideSideBar = (e) => {
        if (typeof window !== 'undefined' && window.innerWidth < 768) {
            if (!e.target.closest('.navbar-toggler')) {
                this.setState({isSideBarOpen: false});
            }
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({isSideBarOpen: window.innerWidth > 768});

    }


    render() {

        const {navs, children, image, user = {}} = this.props;
        return ( !user.authorities ? <Fragment/> :
            <div className="app">
                <header>
                    <NovyHeader toggleSideBar={this.toggleSideBar} isSideBarOpen={this.state.isSideBarOpen} onActiveModuleChanged={this.onActiveModuleChanged} image={image}
                        navs={navs} history={history} location={this.props.location} onLogout={e => this.logout(e)} user={user} activeModule={!isEmpty(this.getActiveModule()) ? this.getActiveModule() : this.getActiveModule()}
                    />
                </header>
                <div className="app-body">
                    <NovySideBar  isOpen={this.state.isSideBarOpen} onClickOut={this.hideSideBar} onActiveModuleChanged={this.onActiveModuleChanged} navs={navs} history={history} {...this.props}
                                  toggleSideBar={this.toggleSideBar} navConfig={!isEmpty(this.getActiveModule()) ? this.getActiveModule().sideBarNavs : this.getActiveModule().sideBarNavs}
                                  image={image} activeModule={!isEmpty(this.getActiveModule()) ? this.getActiveModule() : this.getActiveModule()} auth={user.permissions}
                    />
                    <main className={'main ' + (this.state.isSideBarOpen ? '' : 'ml-4 novy_small_side_bar_main')}>
                       {children}
                    </main>
                </div>
                <footer>
                    <NovyFooter version={this.props.version}/>
                </footer>
            </div>
        );
    }
}

export default NovyLayout;
