import * as types from '../../constants';
import { SUCCESS, REQUEST, FAILURE } from '../../ActionType';
import { AUTH_TOKEN_KEY } from '../../../utils/constants';

const initialState = {
    loading: false,
    user: {},
    collaborator: {},
    error: undefined,
    userNotFound: false,
    collaboratorNotFound: false,

    // Novy builtin auth
    isAuthenticated: false,
    errorMessage: null,
    password_requested: false,
    password_changed: false,
};

const Auth = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case REQUEST(types.GET_USER):
            return {
                ...state,
                loading: true,
                error: undefined,
                userNotFound: false,
            };
        case SUCCESS(types.GET_USER):
            return getUserSuccess(state, response);
        case FAILURE(types.GET_USER):
            return {
                ...state,
                loading: false,
                error: action.error,
                userNotFound: action.userNotFound,
            };
        case REQUEST(types.GET_CURRENT_COLLABORATOR):
            return {
                ...state,
                loading: true,
                error: undefined,
                collaboratorNotFound: false,
            };
        case SUCCESS(types.GET_CURRENT_COLLABORATOR):
            return {
                ...state,
                loading: false,
                collaborator: response,
                collaboratorNotFound: false,
            };
        case FAILURE(types.GET_CURRENT_COLLABORATOR):
            return {
                ...state,
                error: action.error,
                collaboratorNotFound: action.collaboratorNotFound,
            };

        // Novy built-in auth
        case REQUEST(types.LOGIN_USER):
            demandLogin();
            return { ...state, loading: true };
        case SUCCESS(types.LOGIN_USER):
            return authLogin(state, response);
        case FAILURE(types.LOGIN_USER):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };
        case REQUEST(types.LOGOUT_USER):
            return logout(state);
        case SUCCESS(types.REQUEST_PASSWORD):
            return { ...state, password_requested: true, error: false };
        case FAILURE(types.REQUEST_PASSWORD):
            return { ...state, error: action.error };
        case SUCCESS(types.RESET_PASSWORD):
            return { ...state, password_changed: true };
        case FAILURE(types.RESET_PASSWORD):
            return { ...state, error: action.error };
        case REQUEST(types.CHANGE_PASSWORD):
            return { ...state, password_changed: false, error: null };
        case SUCCESS(types.CHANGE_PASSWORD):
            return { ...state, password_changed: true, error: null };
        case FAILURE(types.CHANGE_PASSWORD):
            return { ...state, error: action.error, password_changed: false };

        default:
            return state;
    }
};

const getUserSuccess = (state, response) => {
    return {
        ...state,
        user: { ...response, permissions: JSON.parse(response.permissions) },
    };
};

const demandLogin = () => {
    localStorage.clear();
};
const authLogin = (state, response) => {
    const jwtToken = response.id_token;
    localStorage.clear();
    localStorage.setItem(AUTH_TOKEN_KEY, jwtToken);
    state = Object.assign({}, state, {
        isAuthenticated: true,
        loading: false,
        errorMessage: null,
    });
    return state;
};

const logout = (state) => {
    localStorage.clear();
    state = Object.assign(
        {},
        {
            isAuthenticated: false,
            user: {},
        }
    );
    state.isAuthenticated = false;

    return state;
};

export default Auth;
