import {call, put} from 'redux-saga/effects';
import * as services from '../../../services/Notifications';
import { takeLatest, takeEvery } from 'redux-saga/effects';

import {
    EMAIL_NOTIFICATIONS,
    NOTIFICATIONS,
    NOTIFICATIONS_COUNT,
    EMAIL_MODELS,
    SENT_MAIL_HISTORY
} from '../../constants';
import {FAILURE, SUCCESS, LIST, POST, PUT, DELETE, REQUEST, GET} from '../../../../redux/ActionType';

export function* list(payload) {
    try {
        const response = yield call(services.getNotifications, payload.params);
        yield put({ type: SUCCESS(LIST(NOTIFICATIONS)), response });
    } catch(error) {
        yield put({ type: FAILURE(LIST(NOTIFICATIONS)), error });
    }
}

export function* get(payload) {
    try {
        const response = yield call(services.getNotification, payload.id);
        yield put({ type: SUCCESS(GET(NOTIFICATIONS)), response });
    } catch(error) {
        yield put({ type: FAILURE(GET(NOTIFICATIONS)), error });
    }
}

export function* count(payload) {
    try {
        const response = yield call(services.getNotificationsCount, payload.params);
        yield put({ type: SUCCESS(GET(NOTIFICATIONS_COUNT)), response });
    } catch(error) {
        yield put({ type: FAILURE(GET(NOTIFICATIONS_COUNT)), error });
    }
}

export function* post(payload) {
    try {
        const response = yield call(services.postNotification, payload.notification);
        yield put({ type: SUCCESS(POST(NOTIFICATIONS)), response });
    } catch(error) {
        yield put({ type: FAILURE(POST(NOTIFICATIONS)), error });
    }
}

export function* postEmail(payload) {
    try {
        const response = yield call(services.postEmailNotification, payload.notification);
        yield put({ type: SUCCESS(POST(EMAIL_NOTIFICATIONS)), response });
    } catch(error) {
        yield put({ type: FAILURE(POST(EMAIL_NOTIFICATIONS)), error });
    }
}

export function* update(payload) {
    try {
        const response = yield call(services.putNotification, payload.notification);
        yield put({ type: SUCCESS(PUT(NOTIFICATIONS)), response });
        yield put({ type: REQUEST(LIST(NOTIFICATIONS)),params: {'collabId.equals':payload.notification.collabId, sort:'done,desc'} });
    } catch(error) {
        yield put({ type: FAILURE(PUT(NOTIFICATIONS)), error });
    }
}

export function* destroy(payload) {
    try {

        yield call(services.destroyNotification, payload.id);
        yield put({ type: SUCCESS(DELETE(NOTIFICATIONS)), response:payload.id });
    } catch(error) {
        yield put({ type: FAILURE(DELETE(NOTIFICATIONS)), error });
    }
}

export function* getEmailModelsSaga(payload) {
    try {
        const response = yield call(services.getEmailModels, payload.params);
        yield put({ type: SUCCESS(LIST(EMAIL_MODELS)), response });
    } catch(error) {
        yield put({ type: FAILURE(LIST(EMAIL_MODELS)), error });
    }
}

export function* getSentMailHistoriesSaga(payload) {
    try {
        const response = yield call(services.getSentMailHistories, payload.params);
        yield put({ type: SUCCESS(LIST(SENT_MAIL_HISTORY)), response });
    } catch(error) {
        yield put({ type: FAILURE(LIST(SENT_MAIL_HISTORY)), error });
    }
}
export function* watchNotifications() {
    yield takeLatest(REQUEST(LIST(NOTIFICATIONS)), list);
    yield takeLatest(SUCCESS(POST(NOTIFICATIONS)), list);
    yield takeLatest(SUCCESS(DELETE(NOTIFICATIONS)), list);
    yield takeLatest(REQUEST(GET(NOTIFICATIONS)), get);
    yield takeLatest(REQUEST(GET(NOTIFICATIONS_COUNT)), count);
    yield takeLatest(REQUEST(POST(NOTIFICATIONS)), post);
    yield takeLatest(REQUEST(POST(EMAIL_NOTIFICATIONS)), postEmail);
    yield takeLatest(REQUEST(PUT(NOTIFICATIONS)), update);
    yield takeEvery(REQUEST(DELETE(NOTIFICATIONS)), destroy);
    yield takeLatest(REQUEST(LIST(EMAIL_MODELS)), getEmailModelsSaga);
    yield takeLatest(REQUEST(LIST(SENT_MAIL_HISTORY)), getSentMailHistoriesSaga);


}
