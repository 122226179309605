import React, { Component, Fragment } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { t } from "../../../i18n";
import Dropzone from "../Dropzone";
import { NovyLoading } from "../NovyLayout";
import { maxLength1000, renderTextArea, required, requiredText} from "../../utils";
import StateButton from "./../StateButton";
import Select from "react-select";

export const NEW_ISSUE = false;
export const RESOLVED_ISSUE = true;

class ReportIssue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageFile: []
    };
  }

  handleFormSubmit = values => {
    const issue = {
      user: this.props.userEmail,
      fromUrl: window.location.pathname,
      ...values,
      resolved: values.resolved ? values.resolved === RESOLVED_ISSUE : undefined
    };
    if (issue.id) this.props.onSubmit(issue);
    else {
      const fd = new FormData();
      fd.append("image", this.state.imageFile[0]);
      fd.append("issue", JSON.stringify(issue));
      this.props.onSubmit(fd);
    }
  };

  handleDrop = newImageFile => this.setState({ imageFile: newImageFile });

  renderImage = () => {
    const { isFileLoading, fileURL } = this.props;
    return isFileLoading ? (
      <NovyLoading />
    ) : fileURL ? (
      <a href={fileURL} target="_blank" rel="noreferrer noopener">
        <img src={fileURL} alt="Capture d'écran" className="mw-100" />
      </a>
    ) : null;
  };

  displayPreview = () => {
    const url = URL.createObjectURL(this.state.imageFile[0]);
    return <img className="mt-3 w-100" alt="Aperçu" src={url} />
  }

  clearValues = () => {
    this.setState({imageFile: []});
    this.props.reset();
  }
  renderSelect2Async = ({
    input,
    loadOptions,
    placeholder,
    options,
    noOptionsMessage,
    meta: { touched, warning, error }
  }) => (
    <div>
      <Select
        {...input}
        value={
          options.find(e => e.value === input.value)
            ? options.find(e => e.value === input.value)
            : ""
        }
        name={input.name}
        options={options}
        loadOptions={loadOptions}
        noOptionsMessage={() => noOptionsMessage}
        loadingMessage={() => t("loading_message")}
        onChange={value => {
          return input.onChange(value.value);
        }}
        onBlur={() => input.onBlur(input.value)}
        placeholder={placeholder}
      />
      {touched &&
        ((error && (
          <span className="text-danger">
            {" "}
            <i className="fa fa-exclamation-circle" /> {error}{" "}
          </span>
        )) ||
          (warning && <span>{warning}</span>))}
    </div>
  );

  render() {
    const {
      handleSubmit,
      issueTypes = [],
      title,
      submitButton,
      toggle,
      modal,
      isSubmitting,
      isAdmin
    } = this.props;

    return (
      <Modal isOpen={modal} toggle={toggle}>
        <form onSubmit={handleSubmit(this.handleFormSubmit)}>
          <ModalHeader toggle={toggle}>
            {title ? title : t("report_issue")}
          </ModalHeader>
          <ModalBody>
            <fieldset className="form-group">
              <label className="novy-label-required">
                {t("type_of_issue")} :
              </label>
              <Field
                className="form-control"
                name="typeId"
                validate={required}
                component={this.renderSelect2Async}
                options={
                  issueTypes ?
                  issueTypes.map(it => ({
                    value: it.id,
                    label: capitalize(it.title)
                  })) : []
                }
                placeholder={t("type_of_issue")}
                >
              </Field>
            </fieldset>
            {isAdmin && (
              <fieldset className="form-group">
                <label className="novy-label-required">{t("status")} :</label>
                <Field
                  className="form-control"
                  name="resolved"
                  component={this.renderSelect2Async}
                  options={
                    statusOptions ?
                    statusOptions.map((status) => ({
                      value: status.value,
                      label: capitalize(status.label)
                    })) : []
                  }
                  >
                </Field>
              </fieldset>
            )}
            <fieldset className="form-group">
              <label className="novy-label-required">{t("comment")} :</label>
              <Field
                className="form-control"
                name="comment"
                component={renderTextArea}
                label={t("comment")}
                validate={[requiredText, maxLength1000]}
              />
            </fieldset>
            <fieldset className="form-group">
              <label>{t("screenshot")} :</label>
              {!isAdmin && (
                <Fragment>
                  <div className='m-0 border rounded border-info' >
                    <Dropzone
                      placeholder={t("drag_and_drop_screenshot")}
                      onDrop={this.handleDrop}
                    />
                  </div>
                  {this.state.imageFile[0] ? this.displayPreview() : null}
                </Fragment>
              )}
              {isAdmin && this.renderImage()}
            </fieldset>
          </ModalBody>
          <ModalFooter>
            <Button
              className="novy-btn-secondary mr-1 border-0"
              onClick={this.clearValues}
              disabled={(this.props.pristine && this.state.imageFile.length < 1) || this.props.submitting}
              type="button"
            >
              {t("clearValues")}
            </Button>
            <StateButton
              isLoading={isSubmitting}
              className="novy-btn-primary border-0"
              type="submit"
              disabled={this.props.pristine && this.state.imageFile.length < 1}
            >
              {submitButton ? submitButton : t("report")}
            </StateButton>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export const ReportIssueButton = ({ toggleReportIssueModal }) => (
  <Button
    color="danger"
    onClick={toggleReportIssueModal}
    className="novy-report-issue-btn"
    type="button"
  >
    <i className="fa fa-bug" aria-hidden="true"></i>
  </Button>
);

const statusOptions = [
  {
    value: NEW_ISSUE,
    label: t("new")
  },
  {
    value: RESOLVED_ISSUE,
    label: t("resolved")
  }
];

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export default reduxForm({ form: "reportIssue", enableReinitialize: true })(
  ReportIssue
);
