import {
    AGENCY,
    ARCHIVED_COLLABORATOR,
    COLLABORATOR,
    COLLABORATOR_DETAIL,
    COLLABORATOR_STATUE,
    STATUE_PROFESSIONNEL,
    CONTRACT_TYPE,
    JOB,
    SERVICE,
    ACTIVE_COLLABORATOR,
    COLLABORATORS_AS_RESOURCES,
    CODE,
    COLLABORATOR_TASKS,
    JAVA_COLLABORATOR_TASKS,
    TASK_RAF,
    COLLABORATOR_PROFILE,
    COLLABORATOR_REDUCED_PROFILE,
    COLLABORATORS_AGENCIES
} from '../../constants';
import {CLEAR, DELETE, FAILURE, GET, POST, PUT, REQUEST, SUCCESS, LIST} from '../../../../../App/redux/ActionType';

const INIT_STATE = {
    isLoading: false,
    list: [],
    collaborator: {
        tasks: []
    },
    collaboratorProfile: {},
    loggedCollaborator: {},
    jobs: [],
    services: [],
    contractType: [],
    collaboratorStatue: [],
    statueProfessionnel: [],
    agency: [],
    codes: [],
    emails: [],
    archived: [],
    activeted: [],
    listActive: {list: [], count: 0},
    fetched: false,
    tasks: [],
    collaboratorTasks : [],
    isTaskRafLoading :false,
    collaborators_resources: [],
    collaboratorsAgencies : []
};

function deleteSuccess(state, id) {
    const list = state.list.filter(e => e.id !== id);
    return { ...state, list, isLoading: false };
}

export default function (state = INIT_STATE, action) {
    const response = action.response;
    switch (action.type) {

        case REQUEST(GET(COLLABORATOR)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(COLLABORATOR)):
            return {...state, isLoading: false, list: response};
        case FAILURE(GET(COLLABORATOR)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(ARCHIVED_COLLABORATOR)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(ARCHIVED_COLLABORATOR)):
            return {
                ...state, isLoading: false, archived: response, fetched: true
            };
        case FAILURE(GET(ARCHIVED_COLLABORATOR)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(ACTIVE_COLLABORATOR)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(ACTIVE_COLLABORATOR)):
            return {
                ...state, isLoading: false, activeted: response, fetched: true
            };
        case FAILURE(GET(ACTIVE_COLLABORATOR)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(LIST(ACTIVE_COLLABORATOR)):
            return { ...state, isLoading: true };
        case SUCCESS(LIST(ACTIVE_COLLABORATOR)):
            return { ...state, isLoading: false, listActive: response };
        case FAILURE(LIST(ACTIVE_COLLABORATOR)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(COLLABORATOR_DETAIL)):
            return { ...state, isLoading: true };

        case REQUEST(GET(JAVA_COLLABORATOR_TASKS)):
            return { ...state, isLoading: true };

        case SUCCESS(GET(COLLABORATOR_DETAIL)):
            return { ...state, isLoading: false, collaborator: response };

        case REQUEST(GET(COLLABORATOR_PROFILE)):
            return { ...state, isLoadingProfile: true };
        case SUCCESS(GET(COLLABORATOR_PROFILE)):
            return { ...state, isLoadingProfile: false, collaboratorProfile: response };
        case FAILURE(POST(COLLABORATOR_PROFILE)):
            return { ...state, isLoadingProfile: false, ProfileErrorMessage: action.error.message };
        
        case REQUEST(GET(COLLABORATOR_REDUCED_PROFILE)):
            return { ...state, isLoadingProfile: true };
        case SUCCESS(GET(COLLABORATOR_REDUCED_PROFILE)):
            return { ...state, isLoadingProfile: false, collaboratorREducedProfile: response };
        case FAILURE(POST(COLLABORATOR_REDUCED_PROFILE)):
            return { ...state, isLoadingProfile: false, ProfileErrorMessage: action.error.message };

        case SUCCESS(GET(JAVA_COLLABORATOR_TASKS)):
            return { ...state, isLoading: false, collaboratorTasks : response.list, collaboratorTasksCount: response.count };

        case SUCCESS(GET('LOGGED_'+COLLABORATOR_DETAIL)):
            return { ...state, isLoading: false, loggedCollaborator: response };
        case FAILURE(GET(COLLABORATOR_DETAIL)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case FAILURE(GET(JAVA_COLLABORATOR_TASKS)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(POST(COLLABORATOR)):
            return { ...state, isLoading: true };
        case SUCCESS(POST(COLLABORATOR)):
            return { ...state, isLoading: false, collaborator: response };
        case FAILURE(POST(COLLABORATOR)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(PUT(COLLABORATOR)):
            return { ...state, isLoading: true };
        case SUCCESS(PUT(COLLABORATOR)):
            return { ...state, isLoading: false, collaborator: response };
        case FAILURE(PUT(COLLABORATOR)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(DELETE(COLLABORATOR)):
            return { ...state, isLoading: true };
        case SUCCESS(DELETE(COLLABORATOR)):
            return deleteSuccess(state, response);
        case FAILURE(DELETE(COLLABORATOR)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(JOB)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(JOB)):
            return { ...state, isLoading: false, jobs: response };
        case FAILURE(GET(JOB)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(CONTRACT_TYPE)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(CONTRACT_TYPE)):
            return { ...state, isLoading: false, contractType: response };
        case FAILURE(GET(CONTRACT_TYPE)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(SERVICE)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(SERVICE)):
            return { ...state, isLoading: false, services: response };
        case FAILURE(GET(SERVICE)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(COLLABORATOR_STATUE)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(COLLABORATOR_STATUE)):
            return { ...state, isLoading: false, collaboratorStatue: response };
        case FAILURE(GET(COLLABORATOR_STATUE)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(STATUE_PROFESSIONNEL)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(STATUE_PROFESSIONNEL)):
            return { ...state, isLoading: false, statueProfessionnel: response };
        case FAILURE(GET(STATUE_PROFESSIONNEL)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(COLLABORATORS_AGENCIES)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(COLLABORATORS_AGENCIES)):
            return { ...state, isLoading: false, collaboratorsAgencies: response };
        case FAILURE(GET(COLLABORATORS_AGENCIES)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(COLLABORATORS_AS_RESOURCES)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(COLLABORATORS_AS_RESOURCES)):
            return {...state, isLoading: false, collaborators_resources: response};
        case FAILURE(GET(COLLABORATORS_AS_RESOURCES)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case REQUEST(GET(CODE)):
            return { ...state, isLoading: true };
        case SUCCESS(GET(CODE)):
            return {
                ...state, isLoading: false,
                codes: response.map(collaborator => {
                    return collaborator.code;
                }),
                emails: response.map(collaborator => {
                    return collaborator.email;
                })};
        case FAILURE(GET(CODE)):
            return { ...state, isLoading: false, ErrorMessage: action.error.message };

        case CLEAR(COLLABORATOR_DETAIL):
            return { ...state, collaborator: {}, errorMessage: null, list: [], isLoading: false };

        case SUCCESS(GET(COLLABORATOR_TASKS)):
            return { ...state, tasks: response };

        case REQUEST(PUT(TASK_RAF)):
			return { ...state, isTaskRafLoading: true };
		case SUCCESS(PUT(TASK_RAF)):
			return { ...state, isTaskRafLoading: false, collaborator: response };
		case FAILURE(PUT(TASK_RAF)):
			return { ...state, isTaskRafLoading: false, ErrorMessage: action.error.message, TaskRafUpdateFailed : true };

        default:
            return state;
    }
}
