import {FAILURE, REQUEST, SUCCESS, GET} from '../../../../redux/ActionType';
import { DASHBOARD_LATEST_JOB_APPLICATIONS, DASHBOARD_LATEST_JOB_OPPORTUNITIES, DASHBOARD_NOTIFICATIONS } from '../../constants';

const initialState = {
    // Lastest job applications
    latestJobApplications: [],
    loadingJA: false,
    errorJA: undefined,
    // Lastest job opportunities
    latestJobOpportunities: [],
    loadingJO: false,
    errorJO: undefined,
    // Notifications
    notifications: [],
    notificationsCount: 0,
    loadingNotifications: false,
    errorNotifications: undefined
};

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        // Lastest job applications
        case REQUEST(GET(DASHBOARD_LATEST_JOB_APPLICATIONS)):
            return {...state, loadingJA: true, errorJA: undefined};

        case SUCCESS(GET(DASHBOARD_LATEST_JOB_APPLICATIONS)):
            return {...state, loadingJA: false, latestJobApplications: action.payload};

        case FAILURE(GET(DASHBOARD_LATEST_JOB_APPLICATIONS)):
            return {...state, loadingJA: false, errorJA: action.payload};

        // Lastest job opportunities
        case REQUEST(GET(DASHBOARD_LATEST_JOB_OPPORTUNITIES)):
            return {...state, loadingJO: true, errorJO: undefined};

        case SUCCESS(GET(DASHBOARD_LATEST_JOB_OPPORTUNITIES)):
            return {...state, loadingJO: false, latestJobOpportunities: action.payload};

        case FAILURE(GET(DASHBOARD_LATEST_JOB_OPPORTUNITIES)):
            return {...state, loadingJO: false, errorJO: action.payload};

        // Notifications
        case REQUEST(GET(DASHBOARD_NOTIFICATIONS)):
            return {...state, loadingNotifications: true, errorNotifications: undefined};

        case SUCCESS(GET(DASHBOARD_NOTIFICATIONS)):
            return {...state, loadingNotifications: false, notifications: action.payload.list, notificationsCount: action.payload.total};

        case FAILURE(GET(DASHBOARD_NOTIFICATIONS)):
            return {...state, loadingNotifications: false, errorNotifications: action.payload};

        default:
            return state;
    }
};
