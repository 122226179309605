import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    LIST,
    FAILURE,
    SUCCESS,
    REQUEST
} from '../../../../redux/ActionType';
import { JOB_APPLICATIONS_BY_TECHNOLOGY } from '../../constants';
import * as services from '../../../services/JobApplications';
function* list({ payload }) {
    try {
        const params = payload.params
            ? payload.params
            : yield select(state => state.dataTables.dataTableState.favoriteApplications.params);
        const response = yield call(services.getJobApplicationsByTechnology, payload.technologyId, params);
        yield put({
            type: SUCCESS(LIST(JOB_APPLICATIONS_BY_TECHNOLOGY)),
            payload: { ...response }
        });
    } catch (error) {
        yield put({ type: FAILURE(LIST(JOB_APPLICATIONS_BY_TECHNOLOGY)), payload: error });
    }
}

export function* watchFavoriteJobApplications() {
    yield takeLatest(REQUEST(LIST(JOB_APPLICATIONS_BY_TECHNOLOGY)), list);
}
