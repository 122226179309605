import {CLEAR, FAILURE, LIST, POST, REQUEST, SUCCESS} from '../../../../redux/ActionType';
import {AUTOHIRING_CAMPAIGNS, AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS, AUTOHIRING_INVITATIONS} from '../../constants';

const initialState = {
    loading: false,
    listCampaigns: [],
    listCampaignResults: [],
    campaign: {},
    invitation: {},
    errorMessage: null,
    count: 0
};

const Autohiring = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case REQUEST(LIST(AUTOHIRING_CAMPAIGNS)):
            return { ...state, loading: true };
        case SUCCESS(LIST(AUTOHIRING_CAMPAIGNS)):
            return {
                ...state,
                loading: false,
                listCampaigns: response.list,
                count: response.count
            };
        case FAILURE(LIST(AUTOHIRING_CAMPAIGNS)):
            return { ...state, errorMessage: action.error.message, loading: false };


        case REQUEST(LIST(AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS)):
            return { ...state, loading: true };
        case SUCCESS(LIST(AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS)):
            return {
                ...state,
                loading: false,
                listCampaignResults: response.list,
                count: response.count
            };
        case FAILURE(LIST(AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS)):
            return { ...state, listCampaignResults:[], errorMessage: action.error.message, loading: false };


        case REQUEST(POST(AUTOHIRING_INVITATIONS)):
            return { ...state, loading: true };
        case SUCCESS(POST(AUTOHIRING_INVITATIONS)):
            return { ...state, loading: false, invitation: response };
        case FAILURE(POST(AUTOHIRING_INVITATIONS)):
            return { ...state, errorMessage: action.error.message, loading: false };

        case CLEAR(AUTOHIRING_CAMPAIGNS):
            return {
                ...state,
                campaign: {},
                errorMessage: null,
                listCampaigns: [],
                loading: false,
                count: 0
            };

        case CLEAR(AUTOHIRING_INVITATIONS):
            return {
                ...state,
                invitation: {},
                errorMessage: null,
                loading: false,
                count: 0
            };

        case CLEAR(AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS):
            return {
                ...state,
                listCampaignResults: [],
                errorMessage: null,
                loading: false,
                count: 0
            };

        default:
            return state;
    }
};
export { Autohiring };
