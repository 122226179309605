import { stopSubmit } from 'redux-form';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { get as lodashGet } from 'lodash';
import { t } from '../../../../i18n';
import * as services from '../../../services/Candidates';

import {
    CANDIDATES,
    CANDIDATES_COUNT,
    PARSER_ATTACHMENT,
    PARSER_ATTACHMENT_FR,
} from '../../constants';
import {
    ARCHIVE,
    DELETE,
    FAILURE,
    GET,
    LIST,
    POST,
    PUT,
    REQUEST,
    SUCCESS,
} from '../../../../redux/ActionType';

// Foreign key constaints
const CANDIDATE_EMAIL = 'candidate_email';

export function* list(payload) {
    try {
        const params = payload.params
            ? payload.params
            : yield select(
                  (state) => state.dataTables.dataTableState.candidates.params
              );
        const response = yield call(services.getCandidates, params);
        yield put({ type: SUCCESS(LIST(CANDIDATES)), response });
        if (payload.onSuccess) {
            payload.onSuccess(response.list);
        }
    } catch (error) {
        yield put({ type: FAILURE(LIST(CANDIDATES)), error });
    }
}

export function* get(payload) {
    try {
        const response = yield call(services.getCandidate, payload.id);
        yield put({ type: SUCCESS(GET(CANDIDATES)), response });
        if (payload.onSuccess) payload.onSuccess(response);
    } catch (error) {
        yield put({ type: FAILURE(GET(CANDIDATES)), error });
    }
}

export function* count(payload) {
    try {
        const response = yield call(
            services.getCandidatesCount,
            payload.params
        );
        yield put({ type: SUCCESS(GET(CANDIDATES_COUNT)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(CANDIDATES_COUNT)), error });
    }
}

export function* post(payload) {
    try {
        const response = yield call(services.postCandidate, payload.candidate);
        yield put({ type: SUCCESS(POST(CANDIDATES)), response });
        if (payload.onSuccess) {
            payload.onSuccess();
        }
    } catch (error) {
        yield put({ type: FAILURE(POST(CANDIDATES)), error });
        if (lodashGet(error, 'response.status') === 409) {
            const errors = {};
            const errorKey = lodashGet(error, 'response.data.errorKey', '');
            if (errorKey.toLowerCase().indexOf(CANDIDATE_EMAIL) > -1) {
                errors.email = t('candidate_email_already_exists');
            }
            yield put(stopSubmit('recruitment/candidates/form', errors));
        }
    }
}

export function* update(payload) {
    try {
        const response = yield call(services.putCandidate, payload.candidate);
        yield put({ type: SUCCESS(PUT(CANDIDATES)), response });
        if (payload.onSuccess) {
            payload.onSuccess();
        }
    } catch (error) {
        yield put({ type: FAILURE(PUT(CANDIDATES)), error });
        if (lodashGet(error, 'response.status') === 409) {
            const errors = {};
            const errorKey = lodashGet(error, 'response.data.errorKey', '');
            if (errorKey.toLowerCase().indexOf(CANDIDATE_EMAIL) > -1) {
                errors.email = t('candidate_email_already_exists');
            }
            yield put(stopSubmit('recruitment/candidates/form', errors));
        }
    }
}

export function* archive(payload) {
    try {
        const response = yield call(services.archiveCandidate, payload.id);
        yield put({ type: SUCCESS(ARCHIVE(CANDIDATES)), response });
        if (payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({ type: FAILURE(ARCHIVE(CANDIDATES)), error });
    }
}

export function* destroy(payload) {
    try {
        yield call(services.destroyCandidate, payload.id);
        yield put({ type: SUCCESS(DELETE(CANDIDATES)), response: payload.id });
        if (payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({ type: FAILURE(DELETE(CANDIDATES)), error });
    }
}

export function* postAttachmentForParser(payload) {
    try {
        const response = yield call(
            services.postAttachmentForParser,
            payload.attachment,
            payload.onSuccess,
            payload.onProgress
        );
        yield put({ type: SUCCESS(POST(PARSER_ATTACHMENT)), response });
    } catch (error) {
        yield put({ type: FAILURE(POST(PARSER_ATTACHMENT)), error });
    }
}

export function* postAttachmentForParserFR(payload) {
    try {
        const response = yield call(
            services.postAttachmentForParserFR,
            payload.attachment,
            payload.onSuccess,
            payload.onProgress
        );

        yield put({ type: SUCCESS(POST(PARSER_ATTACHMENT_FR)), response });
    } catch (error) {
        yield put({ type: FAILURE(POST(PARSER_ATTACHMENT_FR)), error });
    }
}

export function* watchCandidates() {
    yield takeLatest(REQUEST(LIST(CANDIDATES)), list);
    yield takeLatest(SUCCESS(PUT(CANDIDATES)), list);
    yield takeLatest(SUCCESS(POST(CANDIDATES)), list);
    yield takeLatest(REQUEST(GET(CANDIDATES)), get);
    yield takeLatest(REQUEST(GET(CANDIDATES_COUNT)), count);
    yield takeLatest(REQUEST(POST(CANDIDATES)), post);
    yield takeLatest(REQUEST(PUT(CANDIDATES)), update);
    yield takeLatest(REQUEST(ARCHIVE(CANDIDATES)), archive);
    yield takeEvery(REQUEST(DELETE(CANDIDATES)), destroy);
    yield takeEvery(REQUEST(POST(PARSER_ATTACHMENT)), postAttachmentForParser);
    yield takeEvery(
        REQUEST(POST(PARSER_ATTACHMENT_FR)),
        postAttachmentForParserFR
    );
}
