import {CLEAR, DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS} from '../../../../redux/ActionType';
import {
    INTERVIEW_MEDIUMS,
    INTERVIEW_LOCATIONS,
    INTERVIEW_TYPES, INTERVIEWERS,
    INTERVIEWS,
    INTERVIEWS_COUNT
} from '../../constants';
import moment from 'moment';

const initialState = {
    loading: false,
    loadingforNew: false,
    list: [],
    interview:{interviewDate:moment().toDate(), jobApplicationId:null},
    errorMessage:null,
    count:0,
    Types:{list:[]},
    Locations:{list:[]},
    Mediums:{list:[]},
    Interviewers:{list:[],count:0}

};

function deleteSuccess(state, id) {
    const list=state.list.filter(e=>e.id!==id);
    state = Object.assign({},state,{list,count:state.count-1});
    return state;
}

const Interviews = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {

        case REQUEST(LIST(INTERVIEWS)):
            return {...state, loading: true};
        case SUCCESS(LIST(INTERVIEWS)):
            return {...state, loading: false, list: response.list, count: response.count};
        case FAILURE(LIST(INTERVIEWS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(LIST(INTERVIEWERS)):
            return {...state, loading: true};
        case SUCCESS(LIST(INTERVIEWERS)):
            return {...state, loading: false, Interviewers: {list: response, count:response.count}};
        case FAILURE(LIST(INTERVIEWERS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(LIST(INTERVIEW_TYPES)):
            return {...state };
        case SUCCESS(LIST(INTERVIEW_TYPES)):
            return {...state, Types: {list: response}};
        case FAILURE(LIST(INTERVIEW_TYPES)):
            return {...state, errorMessage: action.error.message, loading: false};
        case REQUEST(PUT(INTERVIEW_TYPES)):
            return {...state, loading: true};
        case SUCCESS(PUT(INTERVIEW_TYPES)):
            return {...state, loading: false, interviewType: {}};
        case FAILURE(PUT(INTERVIEW_TYPES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(INTERVIEW_TYPES)):
            return {...state, loading: true};
        case SUCCESS(POST(INTERVIEW_TYPES)):
            return {...state, loading: false, interviewType:{}};
        case FAILURE(POST(INTERVIEW_TYPES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(INTERVIEW_TYPES)):
            return {...state, loading: true};
        case SUCCESS(DELETE(INTERVIEW_TYPES)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(INTERVIEW_TYPES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(INTERVIEW_TYPES):
            return {...state, Types:initialState.Types, errorMessage:null, list:[], loading:false};




        case REQUEST(LIST(INTERVIEW_LOCATIONS)):
            return {...state};
        case SUCCESS(LIST(INTERVIEW_LOCATIONS)):
            return {...state, Locations: {list: response}};
        case FAILURE(LIST(INTERVIEW_LOCATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};
        case REQUEST(PUT(INTERVIEW_LOCATIONS)):
            return {...state, loading: true};
        case SUCCESS(PUT(INTERVIEW_LOCATIONS)):
            return {...state, loading: false, interviewLocation: {}};
        case FAILURE(PUT(INTERVIEW_LOCATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(INTERVIEW_LOCATIONS)):
            return {...state, loading: true};
        case SUCCESS(POST(INTERVIEW_LOCATIONS)):
            return {...state, loading: false,interviewLocation:{}};
        case FAILURE(POST(INTERVIEW_LOCATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(INTERVIEW_LOCATIONS)):
            return {...state, loading: true};
        case SUCCESS(DELETE(INTERVIEW_LOCATIONS)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(INTERVIEW_LOCATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(INTERVIEW_LOCATIONS):
            return {...state, Locations:initialState.Locations, errorMessage:null, list:[], loading:false};



        case REQUEST(LIST(INTERVIEW_MEDIUMS)):
            return {...state};
        case SUCCESS(LIST(INTERVIEW_MEDIUMS)):
            return {...state, Mediums: {list: response}};
        case FAILURE(LIST(INTERVIEW_MEDIUMS)):
            return {...state, errorMessage: action.error.message, loading: false};
        case REQUEST(PUT(INTERVIEW_MEDIUMS)):
            return {...state, loading: true};
        case SUCCESS(PUT(INTERVIEW_MEDIUMS)):
            return {...state, loading: false, interviewMedium: {}};
        case FAILURE(PUT(INTERVIEW_MEDIUMS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(INTERVIEW_MEDIUMS)):
            return {...state, loading: true};
        case SUCCESS(POST(INTERVIEW_MEDIUMS)):
            return {...state, loading: false,interviewMedium:{}};
        case FAILURE(POST(INTERVIEW_MEDIUMS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(INTERVIEW_MEDIUMS)):
            return {...state, loading: true};
        case SUCCESS(DELETE(INTERVIEW_MEDIUMS)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(INTERVIEW_MEDIUMS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(INTERVIEW_MEDIUMS):
            return {...state, Mediums:initialState.Mediums, errorMessage:null, list:[], loading:false};




        case REQUEST(GET(INTERVIEWS)):
            return {...state, loading: true, interview:{}};
        case SUCCESS(GET(INTERVIEWS)):
            return {...state, loading: false, interview: response.interview};
        case FAILURE(GET(INTERVIEWS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(GET(INTERVIEWS_COUNT)):
            return {...state, loading: true};
        case SUCCESS(GET(INTERVIEWS_COUNT)):
            return {...state, loading: false, Count: response};
        case FAILURE(GET(INTERVIEWS_COUNT)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(PUT(INTERVIEWS)):
            return {...state, loading: true};
        case SUCCESS(PUT(INTERVIEWS)):
            return {...state, loading: false, interview: {}};
        case FAILURE(PUT(INTERVIEWS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(INTERVIEWS)):
            return {...state, loading: true, loadingforNew: true};
        case SUCCESS(POST(INTERVIEWS)):
            return {...state, loading: false, interview: initialState.interview, loadingforNew: false};
        case FAILURE(POST(INTERVIEWS)):
            return {...state, errorMessage: action.error.message, loading: false, loadingforNew: false};

        case REQUEST(DELETE(INTERVIEWS)):
            return {...state, loading: true};
        case SUCCESS(DELETE(INTERVIEWS)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(INTERVIEWS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(INTERVIEWS):
            return {...state, interview:initialState.interview, jobApplicationId:null, errorMessage:null, list:[], loading:false, count:0};
        default:
            return state;
    }
};
export {Interviews};
