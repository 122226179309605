import React, { Component, Suspense, Fragment } from 'react';
import { connect } from 'react-redux';
import {  Router, Switch, Route } from 'react-router-dom';
import history from './App/routes/history';
import NovyLoading from './App/components/NovyLayout/NovyLoading';
import NovyLayout  from './App/components/NovyLayout/index';
import Loadable from 'react-loadable';
import { withNamespaces } from 'react-i18next';
import { Container } from 'reactstrap';
import { isKeycloakDisabled } from './keycloak/config';
import PrivateRoute from '../src/App/components/PrivateRoute';
import navs from './HeaderNavs';
import { preferencesAction } from './redux/actions/preferences';
import { recruitmentRoutes } from '../src/App/routes';
import ReportIssueModal, {
    ReportIssueButton,
} from '../src/App/components/ReportIssue';
import { getCurrentUser, logout } from './redux/actions/auth';
import { dataTableAction } from './redux/actions/datatables';
import { clearPageTitle, onSideBarChanged } from './redux/actions/layout';
import NovyBreadcrumbs from '../src/App/components/NovyBreadcrumbs';
import AccessDeniedWarning from '../src/App/components/AccessDeniedWarning';
import { getProfileImage } from '../src/App/utils';

const loading = () => <NovyLoading />;
const Login = Loadable({
    loader: () => import('../src/App/pages/Login'),
    loading,
});
const PasswordReset = Loadable({
    loader: () => import('../src/App/pages/Login/PasswordReset'),
    loading,
});
const ForgotPassword = Loadable({
    loader: () => import('../src/App/pages/Login/ForgotPassword'),
    loading,
});

const routes = [...recruitmentRoutes];

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _isMounted: false,
            reportIssueModal: false,
            submittingIssue: false,
            navs: navs,
            routes: routes,
        };
    }

    async componentWillMount() {
        this.props.clearPageTitle();
        const data = require('./static/version.txt');
        const currentVersion = localStorage.getItem('version');

        fetch(data).then((result) =>
            result.text().then((r) => {
                const version = r.split('\n').join('');
                this.state._isMounted && this.setState({ version });
                if (currentVersion === version) {
                    if (localStorage.getItem('preferences')) {
                        this.props.preferencesAction(
                            JSON.parse(localStorage.getItem('preferences'))
                        );
                    } else {
                        this.props.preferencesAction({
                            absence_activity_view: 'calendar',
                            absence_activity_calendar: 1,
                            mImputations_calendar: 0,
                            Imputations_calendar: 0,
                            user_calendar: 1,
                            projects: [],
                        });
                    }
                    if (localStorage.getItem('dataTableState')) {
                        this.props.dataTableAction(
                            JSON.parse(localStorage.getItem('dataTableState'))
                        );
                    }
                } else {
                    localStorage.clear();
                    localStorage.setItem('version', version);
                }
            })
        );
    }

    componentDidMount() {
        this.setState({ _isMounted: true });
        const { isAuthenticated } = this.props;
        if (!isKeycloakDisabled || isAuthenticated) {
            this.props.getCurrentUser();
        }
        if (
            isKeycloakDisabled &&
            !isAuthenticated &&
            history.location.pathname.substr(0, 6) !== '/reset'
        ) {
            history.push('/login');
        }
    }

    componentDidUpdate(prevProps) {
        if (
            !prevProps.currentCollaborator.id &&
            this.props.currentCollaborator.id
        ) {
            // this.props.listIssueTypes();
        }
    }

    componentWillUnmount() {
        this.props.clearPageTitle();
        this.setState({ _isMounted: false });
    }

    handleIssueSubmit = (issueAndFile) => {
        this.state._isMounted && this.setState({ submittingIssue: true });
        const successCallback = () => {
            this.toggleReportIssueModal();
            this.state._isMounted && this.setState({ submittingIssue: false });
        };
        const errorCallback = () => {
            this.state._isMounted && this.setState({ submittingIssue: false });
        };
        this.props.postIssue(issueAndFile, successCallback, errorCallback);
    };

    toggleReportIssueModal = () => {
        this.setState({ reportIssueModal: !this.state.reportIssueModal });
    };

    logout = () => {
        this.props.clearPageTitle();

        if (isKeycloakDisabled) {
            this.props.logout();
        } else {
            this.props.keycloak.logout();
        }
    };

    componentWillReceiveProps(props) {
        let updatedNavs = { ...navs };

        this.setState({
            navs: updatedNavs,
            routes: routes,
        });
    }

    renderNovyLayout = () => {
        const {
            issueTypes,
            user,
            isUserLoading,
            currentCollaborator,
            accessDenied,
        } = this.props;
        if (accessDenied) return <AccessDeniedWarning />;
        if (!this.state.version || isUserLoading || !currentCollaborator.id)
            return <NovyLoading />;
        return (
            <NovyLayout
               {...this.props}
                sideBarChanged={(open) => this.props.onSideBarChanged(open)}
                navs={this.state.navs}
                user={{
                    ...user,
                    image: getProfileImage(
                        currentCollaborator.id,
                        currentCollaborator.image
                    ),
                    job: currentCollaborator.job,
                }}
                logout={this.logout}
                image={getProfileImage(
                    currentCollaborator.id,
                    currentCollaborator.image
                )}
                version={this.state.version}
                location={history.location}
            >
                <Container fluid>
                    <NovyBreadcrumbs
                        className="bg-transparent"
                        appRoutes={this.state.routes}
                    />
                    <ReportIssueButton
                        toggleReportIssueModal={this.toggleReportIssueModal}
                    />
                    <ReportIssueModal
                        issueTypes={issueTypes}
                        onSubmit={this.handleIssueSubmit}
                        toggle={this.toggleReportIssueModal}
                        modal={this.state.reportIssueModal}
                        userEmail={user.email}
                        isSubmitting={this.state.submittingIssue}
                    />
                    <Suspense fallback={<NovyLoading />}>
                        <Switch>
                            {routes.map((route, idx) => {
                                return route.component && route.private ? (
                                    <PrivateRoute
                                        {...this.props}
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        user={user}
                                        history={this.props.history}
                                        component={route.component}
                                        permission={route.permission}
                                        fallbackPath={route.fallbackPath}
                                    />
                                ) : null;
                            })}
                        </Switch>
                    </Suspense>
                </Container>
            </NovyLayout>
        );
    };

    renderAuthRoutes = () => {
        return (
            <Fragment>
                <Route path="/login" exact name="Login" component={Login} />
                <Route
                    path="/reset/finish/:key"
                    exact
                    name="Reset"
                    component={PasswordReset}
                />
                <Route
                    path="/reset"
                    exact
                    name="Forgot"
                    component={ForgotPassword}
                />
            </Fragment>
        );
    };

    render() {
        return (
            <Suspense fallback={<NovyLoading />}>
                <Router history={history}>
                    <Switch>
                        {!isKeycloakDisabled || this.props.isAuthenticated
                            ? this.renderNovyLayout()
                            : this.renderAuthRoutes()}
                    </Switch>
                </Router>
            </Suspense>
        );
    }
}
const mapStateToProps = (state) => {
    const currentCollaborator = state.Auth.collaborator || {};
    return {
        user: state.Auth.user,
        currentCollaborator,
        keycloak: state.keycloak,
        isUserLoading: state.Auth.loading,
        collabImage: currentCollaborator.image,
        accessDenied:
            state.Auth.userNotFound || state.Auth.collaboratorNotFound,
        isAuthenticated: state.Auth.isAuthenticated,
    };
};

export default withNamespaces()(
    connect(mapStateToProps, {
        preferencesAction,
        getCurrentUser,
        clearPageTitle,
        dataTableAction,
        onSideBarChanged,
        logout,
    })(App)
);
