import {axios} from '../../../index';
import {
    AGENCIES_ENDPOINT,
    CITIES_ENDPOINT,
    COUNTRIES_ENDPOINT,
    ELASTICSEARCH_RECRUITMENT_REINDEX,
    ELASTICSEARCH_SUPPLIERS_REINDEX,
    TECHNOLOGIES_ENDPOINT
} from './endpoints';

export const getCities = (params) => {
    return axios.get(CITIES_ENDPOINT, { params: {...params?{ 'countryId.equals': params['countryId.equals'] }:null, size: 1000, sort:'id,desc'} })
        .then(response => ({ list: response.data }));
};

export const postCity = (city) => {
    return axios.post(CITIES_ENDPOINT, city)
        .then(response => response.data);

};
export const putCity = (city) => {
    return axios.put(CITIES_ENDPOINT, city)
        .then(response => response.data);
};
export const destroyCity = (id) => {
    return axios.delete(`${CITIES_ENDPOINT}/${id}`)
        .then(response => response.data);
};

export const getCountries = (params) => {
    return axios.get(COUNTRIES_ENDPOINT,{ params: {...params?{ 'countryId.equals': params['countryId.equals'] }:null, size: 1000, sort:'id,desc'} })
        .then(response => ({ list: response.data }));
};

export const postCountry = (country) => {
    return axios.post(COUNTRIES_ENDPOINT, country)
        .then(response => response.data);

};
export const putCountry = (country) => {
    return axios.put(COUNTRIES_ENDPOINT, country)
        .then(response => response.data);
};
export const destroyCountry = (id) => {
    return axios.delete(`${COUNTRIES_ENDPOINT}/${id}`)
        .then(response => response.data);
};

export const getTechnologies = (params) => {
    return axios.get(TECHNOLOGIES_ENDPOINT,{params:{...params, size:1000, sort:'id,desc'}})
        .then(response => {
            return({ list: response.data, count:response.headers['x-total-count'] });
        });
};
export const postTechnology = (technology) => {
    return axios.post(TECHNOLOGIES_ENDPOINT, technology)
        .then(response => response.data);

};
export const putTechnology = (technology) => {
    return axios.put(TECHNOLOGIES_ENDPOINT, technology)
        .then(response => response.data);
};
export const destroyTechnology = (id) => {
    return axios.delete(`${TECHNOLOGIES_ENDPOINT}/${id}`)
        .then(response => response.data);
};

export const getAgencies = () =>{
    return axios.get(AGENCIES_ENDPOINT, {params:{size: 1000, sort:'id,desc'}}).then(response =>({list:response.data}));
};

export const elasticSearchRecruitmentReindex = () => {
    return axios.post(ELASTICSEARCH_RECRUITMENT_REINDEX)
};

export const elasticSearchSuppliersReindex = () => {
    return axios.post(ELASTICSEARCH_SUPPLIERS_REINDEX)
};
