import {CLEAR, DELETE, FAILURE, LIST, POST, PUT, REQUEST, SUCCESS} from '../../../../redux/ActionType';
import {
    AGENCIES,
    CITIES,
    COUNTRIES,
    TECHNOLOGIES
} from '../../constants';
import {combineReducers} from 'redux';

function deleteSuccess(state, id) {
    const list=state.list.filter(e=>e.id!==id);
    state = Object.assign({},state,{list,count:state.count-1});
    return state;
}

const Cities = (state = {
    loading: false,
    list: []
}   , action) => {
    const response = action.response;
    switch (action.type) {

        case REQUEST(LIST(CITIES)):
            return {...state, loading: true};
        case SUCCESS(LIST(CITIES)):
            return {...state, loading: false, list: response.list};
        case FAILURE(LIST(CITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(PUT(CITIES)):
            return {...state, loading: true};
        case SUCCESS(PUT(CITIES)):
            return {...state, loading: false, city: {}};
        case FAILURE(PUT(CITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(CITIES)):
            return {...state, loading: true};
        case SUCCESS(POST(CITIES)):
            return {...state, loading: false,city:{}};
        case FAILURE(POST(CITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(CITIES)):
            return {...state, loading: true};
        case SUCCESS(DELETE(CITIES)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(CITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(CITIES):
            return {...state, errorMessage:null, list:[], loading:false};

        default:
            return state;

    }
};

const Countries = (state = {
    loading: false,
    list: []
}   , action) => {
    const response = action.response;
    switch (action.type) {

        case REQUEST(LIST(COUNTRIES)):
            return {...state, loading: true};
        case SUCCESS(LIST(COUNTRIES)):
            return {...state, loading: false, list: response.list};
        case FAILURE(LIST(COUNTRIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(PUT(COUNTRIES)):
            return {...state, loading: true};
        case SUCCESS(PUT(COUNTRIES)):
            return {...state, loading: false, country: {}};
        case FAILURE(PUT(COUNTRIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(COUNTRIES)):
            return {...state, loading: true};
        case SUCCESS(POST(COUNTRIES)):
            return {...state, loading: false, country:{}};
        case FAILURE(POST(COUNTRIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(COUNTRIES)):
            return {...state, loading: true};
        case SUCCESS(DELETE(COUNTRIES)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(COUNTRIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(COUNTRIES):
            return {...state, errorMessage:null, list:[], loading:false};

        default:
            return state;
    }
};

const Technologies = (state = {
    loading: false,
    list: []
}   , action) => {

    const response = action.response;
    switch (action.type) {

        case REQUEST(LIST(TECHNOLOGIES)):
            return {...state, loading: true};
        case SUCCESS(LIST(TECHNOLOGIES)):
            return {...state, loading: false, list: response.list};
        case FAILURE(LIST(TECHNOLOGIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(PUT(TECHNOLOGIES)):
            return {...state, loading: true};
        case SUCCESS(PUT(TECHNOLOGIES)):
            return {...state, loading: false, technology: {}};
        case FAILURE(PUT(TECHNOLOGIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(TECHNOLOGIES)):
            return {...state, loading: true};
        case SUCCESS(POST(TECHNOLOGIES)):
            return {...state, loading: false, technology:{}};
        case FAILURE(POST(TECHNOLOGIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(TECHNOLOGIES)):
            return {...state, loading: true};
        case SUCCESS(DELETE(TECHNOLOGIES)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(TECHNOLOGIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(TECHNOLOGIES):
            return {...state, errorMessage:null, list:[], loading:false};

        default:
            return state;
    }
};

const Agencies = (state = {
    loading: false,
    list: []
}   , action) => {

    const response = action.response;
    switch (action.type) {

        case REQUEST(LIST(AGENCIES)):
            return {...state, loading: true};
        case SUCCESS(LIST(AGENCIES)):
            return {...state, loading: false, list: response.list};
        case FAILURE(LIST(AGENCIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(PUT(AGENCIES)):
            return {...state, loading: true};
        case SUCCESS(PUT(AGENCIES)):
            return {...state, loading: false, agency: {}};
        case FAILURE(PUT(AGENCIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(AGENCIES)):
            return {...state, loading: true};
        case SUCCESS(POST(AGENCIES)):
            return {...state, loading: false, agency:{}};
        case FAILURE(POST(AGENCIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(AGENCIES)):
            return {...state, loading: true};
        case SUCCESS(DELETE(AGENCIES)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(AGENCIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(AGENCIES):
            return {...state, errorMessage:null, list:[], loading:false};

        default:
            return state;
    }
};


export default combineReducers({
    Cities,
    Countries,
    Technologies,
    Agencies
});

