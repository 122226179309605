import { capitalize } from 'lodash';
import React, { Fragment } from 'react';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import { axios } from '../../index';
import pluralize from 'pluralize';
import { t } from '../../i18n';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { addValidator } from 'redux-form-validators';
import cs from 'classnames';
import fr from 'react-phone-number-input/locale/fr';
import Toggle from 'react-toggle';
import { Multiselect } from 'react-widgets';
import Autosuggest from 'react-autosuggest';

export const dateFormat = (date) => {
    return date ? moment(date).format('DD-MM-YYYY') : '-';
};

export const dateTimeFormat = (date) => {
    return date ? moment(date).utc(false).format('DD-MM-YYYY - HH:mm') : '-';
};

export const fullNameFormat = ({
    title = '',
    firstname = '',
    lastname = '',
}) => {
    let out = '';

    if (title !== 'OTHER') {
        out = `${title}. ${capitalize(firstname)} ${lastname.toUpperCase()}`;
    } else {
        out = `${capitalize(firstname)} ${lastname.toUpperCase()}`;
    }

    return out;
};

export const getBase64 = (url) => {
    return axios
        .get(url, {
            responseType: 'arraybuffer',
        })
        .then((response) =>
            Buffer.from(response.data, 'binary').toString('base64')
        );
};

export const getProfileImage = (id, image) =>
    `${process.env.REACT_APP_SERVER_API_URL}/collaboratorjava/api/collaborators/${id}/image/${image}`;
export const getFileContentType = (doc) => {
    if (!doc) return;
    const extension = doc.name.split('.').slice(-1)[0];
    switch (extension) {
        case 'pdf':
            return 'application/pdf';
        case 'png':
            return 'image/png';
        case 'jpg':
            return 'image/jpg';
        case 'jpeg':
            return 'image/jpeg';
        case 'doc':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'odt':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        default:
            return extension;
    }
};

export const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

export const capitalizeCaracters = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const parseFilterList = (tableState, columns, filters) => {
    tableState.filterList.forEach((filter, index) => {
        if (!filter.length) return;
        let columnName = columns[index].name.includes('.')
            ? columns[index].name.split('.')[
                  columns[index].name.split('.').length - 1
              ]
            : columns[index].name;
        if (
            !columnName.includes('positions') &&
            !columnName.includes('requirements') &&
            pluralize.isPlural(columnName)
        )
            columnName = pluralize.singular(columnName);
        if (columnName.includes('assignedToId')) {
            filters['assignedTo.contains'] = filter.join(',');
        } else if (filter.includes(t('interview_after'))) {
            filters['interviewDate.greaterOrEqualThan'] = new Date();
        } else if (filter.includes(t('interview_before')))
            filters['interviewDate.lessThan'] = new Date();
        else
            switch (columns[index].options.filterType) {
                case 'switch':
                    if (columns[index].options.inclusive) {
                        filters[columnName + '.in'] = filter.join(',');
                    } else {
                        filters[columnName + '.equals'] =
                            Array.isArray(filter) && filter.length > 0
                                ? filter[0]
                                : '';
                    }
                    break;
                case 'checkbox':
                    filters[columnName + '.in'] = filter.join(',');
                    break;
                case 'multiselect':
                    filters[columnName + '.in'] = filter.join(',');
                    break;
                case 'select':
                    filters[columnName + '.equals'] = filter.join(',');
                    break;
                case 'dateRange':
                    if (filter.length > 1) {
                        if (filter[0])
                            filters[columnName + '.greaterOrEqualThan'] =
                                filter[0];
                        if (filter[1])
                            filters[columnName + '.lessOrEqualThan'] =
                                filter[1];
                    } else if (filter.length > 0) {
                        if (filter[0])
                            filters[columnName + '.greaterOrEqualThan'] =
                                filter[0];
                    }
                    break;
                case 'date':
                    filters[columnName + '.equals'] = filter.join(',');
                    break;
                case 'textfield':
                    filters[columnName + '.contains'] = filter.join(',');
                    break;
                default:
                    break;
            }
    });
};

export const escapeRegExp = (string) => {
    return string.replace(/[.~&&||<>!":\\/-=*+?^${}()|[\]\\]/g, '\\$&');
};

export const sortTemplate = (
    columns,
    { activeColumn, announceText, searchText } = { announceText: 'desc' }
) => {
    announceText =
        announceText && announceText.includes('ascending') ? 'asc' : 'desc';

    activeColumn = activeColumn || activeColumn === 0 ? activeColumn : 'none';

    return `${
        searchText
            ? ''
            : activeColumn === 'none'
            ? 'id'
            : columns[activeColumn].name
    },${announceText}`;
};

var timeoutID = 0;

export const timeout = (callback, delay = 250) => {
    if (typeof callback === 'function') {
        if (timeoutID) clearTimeout(timeoutID);

        timeoutID = setTimeout(callback, [delay]);
    }
};

export const maxLength10 = (value) =>
    value && value.length > 10
        ? `${t('error_max_size_first_part')} 10 ${t(
              'error_max_size_second_part'
          )}`
        : undefined;

export const maxLength50 = (value) =>
    value && value.length > 50
        ? `${t('error_max_size_first_part')} 50 ${t(
              'error_max_size_second_part'
          )}`
        : undefined;

export const minLength2 = (value) =>
    value && value.length < 2
        ? 'Doit contenir 2 caractères ou plus'
        : undefined;

export const phoneValidator = addValidator({
    validator: function (options, value) {
        if (!value) return undefined;
        if (!isValidPhoneNumber(value)) {
            return {
                id: 'form.errors.custom',
                defaultMessage: t('invalid_phone_number'),
                values: { count: options.digits },
            };
        }
    },
});

export const renderInputField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
}) => (
    <div>
        <input
            {...input}
            cs
            className={cs('novy-input', {
                'form-control': true,
                'is-invalid': touched && error,
                'is-valid': touched,
            })}
            placeholder={label}
            type={type}
        />
        {touched &&
            ((error && (
                <span className="text-danger">
                    {' '}
                    <i className="fa fa-exclamation-circle" /> {error}{' '}
                </span>
            )) ||
                (warning && <span>{warning}</span>))}
    </div>
);

export const renderPhoneField = ({
    input,
    input: { value, onChange, onBlur },
    placeholder,
    country,
    meta: { touched, error, warning },
}) => {
    return (
        <div>
            <PhoneInput
                {...input}
                placeholder={placeholder}
                value={value}
                country={country}
                international={false}
                inputClassName={cs('novy-input-mw85', {
                    'form-control': true,
                    'is-invalid': touched && error,
                    'is-valid': touched && !error,
                })}
                labels={fr}
                countryOptions={['MA', 'FR', 'ES', '|', '...']}
                className={cs('novy-input', { 'col-12 ml-0 px-0 row': true })}
                onChange={(e) => {
                    onChange(e || '');
                }}
                onBlur={onBlur}
            />

            {touched &&
                ((error && (
                    <span className="text-danger">
                        {' '}
                        <i className="fa fa-exclamation-circle" /> {error}{' '}
                    </span>
                )) ||
                    (warning && <span>{warning}</span>))}
        </div>
    );
};

export const renderToggleInput = (field) => (
    <div className="d-flex align-content-center">
        <Toggle
            id={'toggle' + field.label}
            className="align-self-center"
            defaultChecked={field.defaultChecked}
            checked={!!field.input.value}
            onChange={field.input.onChange}
            disabled={field.disabled}
        />
        <label className="pl-3 m-0" htmlFor={'toggle' + field.label}>
            {field.label}
        </label>
    </div>
);

export const passwordsMatch = (value, allValues) =>
    value !== allValues.password ? t('password_doesnt_match') : undefined;
export const required = (value) => (value ? undefined : t('required'));

export const requiredTag = (value) =>
    value && value.length > 0 ? undefined : t('required');

export const requiredText = (value) =>
    value && value.trim().length > 0 ? undefined : t('required');

export const email = (value) =>
    value &&
    !/^[A-Z0-9._%+-]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Adresse e-mail invalide'
        : undefined;
export const maxValue30 = (value) =>
    value && value > 30 ? t('must_be_smaller') + ' 30 ' : undefined;
export const minValue0 = (value) =>
    value && value < 0 ? t('must_be_bigger') + ' 0 ' : undefined;
export const minValue1 = (value) =>
    value && value < 1 ? t('must_be_bigger') + ' 1 ' : undefined;
export const number = (value) =>
    value && isNaN(Number(value)) ? t('must_be_number') : undefined;
export const renderBigTextArea = ({
    input,
    label,
    rows,
    meta: { touched, error, warning },
}) => (
    <div>
        <textarea
            minLength="2"
            {...input}
            style={{ minHeight: 200 }}
            className={cs('novy-input', {
                'form-control': true,
                'is-invalid': touched && error,
                'is-valid': touched,
            })}
            placeholder={label}
            rows={rows}
        >
            <Fragment />
        </textarea>
        {touched &&
            ((error && (
                <span className="text-danger">
                    {' '}
                    <i className="fa fa-exclamation-circle" /> {error}{' '}
                </span>
            )) ||
                (warning && <span>{warning}</span>))}
    </div>
);

export const renderMultiselect = ({
    input,
    label,
    data,
    valueField,
    textField,
    meta: { touched, warning, error },
}) => (
    <div>
        <Multiselect
            {...input}
            onBlur={() => input.onBlur()}
            value={input.value || null} // requires value to be an array
            data={data}
            placeholder={label}
            valueField={valueField}
            textField={textField}
            messages={t('multiselect', { returnObjects: true })}
        />
        {touched &&
            ((error && (
                <span className="text-danger">
                    {' '}
                    <i className="fa fa-exclamation-circle" /> {error}{' '}
                </span>
            )) ||
                (warning && <span>{warning}</span>))}
    </div>
);

export const applicationDate = (value) =>
    value &&
    moment(value).year() >= moment().year() - 40 &&
    moment(value) <= moment()
        ? undefined
        : t('invalid_application_date');

export const disponibilityDate = (value) =>
    value &&
    (moment(value) < moment().startOf('day') || moment(value).year() > 2050)
        ? t('invalid_disponibility_date')
        : undefined;

export const renderSelectField = ({
    input,
    meta: { touched, warning, error },
    children,
}) => {
    return (
        <div className="field">
            <div className="control">
                <div
                    className={
                        'select ' +
                        (touched ? (error ? 'is-danger' : 'is-success') : '')
                    }
                >
                    <select
                        className={cs('novy-input', {
                            'form-control': true,
                            'is-invalid': touched && error,
                            'is-valid': touched,
                        })}
                        {...input}
                    >
                        {children}
                    </select>
                    {touched &&
                        ((error && (
                            <span className="text-danger">
                                {' '}
                                <i className="fa fa-exclamation-circle" />{' '}
                                {error}{' '}
                            </span>
                        )) ||
                            (warning && <span>{warning}</span>))}
                </div>
            </div>
        </div>
    );
};
export const renderTimeDateField = ({
    input,
    label,
    meta: { touched, error, warning },
}) => {
    /*Todo change DatePicker to French*/
    return (
        <div>
            <DatePicker
                {...input}
                className={cs('novy-input', {
                    'form-control': true,
                    'is-invalid': touched && error,
                    'is-valid': touched,
                })}
                placeholder={label}
                id="datepicker"
                onChangeRaw={() => {
                    let s = document.getElementById('datepicker');
                    s.value = moment(input.value).format('dd/MM/YYYY HH:mm');
                }}
                selected={
                    input.value
                        ? moment(input.value, 'dd/MM/YYYY HH:mm')
                              .toDate()
                              .valueOf()
                        : null
                }
                showTimeSelect
                normalize={(value, previousValue) => {
                    if (value == null || !value.isValid()) {
                        return previousValue;
                    }
                    return value;
                }}
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="dd/MM/yyyy HH:mm"
                locale="fr"
                timeCaption="time"
            />
            {touched &&
                ((error && (
                    <span className="text-danger">
                        {' '}
                        <i className="fa fa-exclamation-circle" /> {error}{' '}
                    </span>
                )) ||
                    (warning && <span>{warning}</span>))}
        </div>
    );
};

export const maxLength255 = (value) =>
    value && value.length > 255
        ? `${t('error_max_size_first_part')} 255 ${t(
              'error_max_size_second_part'
          )}`
        : undefined;

export const upper = (value) => value && value.toUpperCase();
export const renderAutoComplete = ({
    input,
    meta: { touched, error, warning },
    suggestions,
    onValueChange,
}) => {
    return (
        <div>
            <Autosuggest
                className={cs('novy-input', {
                    'form-control': true,
                    'is-invalid': touched && error,
                    'is-valid': touched,
                })}
                suggestions={suggestions}
                getSuggestionValue={(suggestion) => suggestion.label}
                renderSuggestion={(suggestion) => <div>{suggestion.label}</div>}
                onSuggestionsFetchRequested={onValueChange}
                onSuggestionsClearRequested={() => {}}
                inputProps={{
                    autocomplete: false,
                    value: input.value,
                    onChange: (event, { newValue }) => input.onChange(newValue),
                    className: cs({
                        'form-control': true,
                        'is-invalid': touched && error,
                        'is-valid': touched,
                    }),
                }}
            />
            {touched &&
                ((error && (
                    <span className="text-danger">
                        {' '}
                        <i className="fa fa-exclamation-circle" /> {error}{' '}
                    </span>
                )) ||
                    (warning && <span>{warning}</span>))}
        </div>
    );
};

export const dateOfBirth = (value) => {
    if (!value) return undefined;
    return moment(value).year() >= moment().year() - 70 &&
        moment(value).year() <= moment().year() - 18
        ? undefined
        : t('invalid_birth_date');
};

export const maxLength1000 = (value) =>
    value && value.length > 1000
        ? `${t('error_max_size_first_part')} 1000 ${t(
              'error_max_size_second_part'
          )}`
        : undefined;

export const renderTextArea = ({
    input,
    label,
    rows,
    meta: { touched, error, warning },
}) => (
    <div>
        <textarea
            minLength="2"
            {...input}
            className={cs('novy-input', {
                'form-control': true,
                'is-invalid': touched && error,
                'is-valid': touched,
            })}
            placeholder={label}
            rows={rows}
        >
            <Fragment />
        </textarea>
        {touched &&
            ((error && (
                <span className="text-danger">
                    {' '}
                    <i className="fa fa-exclamation-circle" /> {error}{' '}
                </span>
            )) ||
                (warning && <span>{warning}</span>))}
    </div>
);
