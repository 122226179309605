import {
    FAILURE,
    LIST,
    REQUEST,
    SUCCESS
} from '../../../../redux/ActionType';
import { ARCHIVED_JOB_APPLICATIONS } from '../../constants';

const initialState = {
    list: [],
    count: 0,
    loading: false,
    error: undefined
};

export const archivedJobApplications = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case REQUEST(LIST(ARCHIVED_JOB_APPLICATIONS)):
            return {
                ...state,
                loading: true,
                error: undefined
            };

        case SUCCESS(LIST(ARCHIVED_JOB_APPLICATIONS)):
            return {
                ...state,
                loading: false,
                error: undefined,
                ...payload
            };

        case FAILURE(LIST(ARCHIVED_JOB_APPLICATIONS)):
            return {
                ...state,
                loading: false,
                error: payload
            };

        default:
            return state;
    }
};
