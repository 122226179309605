import { all } from 'redux-saga/effects';
import { watchShared } from './shared';
import { watchCandidates } from './Candidates';
import {
    watchJobApplications,
    watchMyJobApplications,
    watchArchivedJobApplications,
    watchNewJobApplications,
    watchFavoriteJobApplications,
} from './JobApplications';
import { watchJobOpportunities } from './JobOpportunities';
import { watchInterviews } from './Interviews';
import { watchInterviewers } from './Interviewers';
import { watchNotifications } from './Notifications';
import { watchCollaborators } from './Collaborators';
import { watchAutohiring } from './Autohiring';
import { watchDashboard } from './Dashboard';
import { watchAgencies } from './RT/Agencies';

export function* watchRecruitment() {
    yield all([
        watchCandidates(),
        watchCollaborators(),
        watchJobApplications(),
        watchMyJobApplications(),
        watchArchivedJobApplications(),
        watchNewJobApplications(),
        watchFavoriteJobApplications(),
        watchShared(),
        watchJobOpportunities(),
        watchInterviews(),
        watchNotifications(),
        watchInterviewers(),
        watchAutohiring(),
        watchDashboard(),
        watchAgencies(),
    ]);
}
