export const REQUEST_PASSWORD = 'REQUEST_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const EMAIL_NOTIFICATIONS = 'EMAIL_NOTIFICATIONS';
export const NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT';
export const LOGIN_USER = 'LOGIN_USER';
export const GET_USER = 'GET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const ACTIVE_MODULE_CHANGE = 'ACTIVE_MODULE_CHANGE';
export const EMAIL_MODELS = 'EMAIL_MODELS';
export const SENT_MAIL_HISTORY = 'SENT_MAIL_HISTORY';

export const GET_IMPUTATIONS = 'GET_IMPUTATIONS';
export const GET_IMPUTATIONS_BY_COLLABORATOR = 'GET_IMPUTATIONS_BY_COLLABORATOR';

export const TOTAL_IMPUTATIONS_BY_TASK = 'TOTAL_IMPUTATIONS_BY_TASK';

export const SAVE_IMPUTATIONS = 'SAVE_IMPUTATIONS';

export const COLLABORATORS_AGENCIES = 'COLLABORATORS_AGENCIES';

export const IMPUTATION = 'IMPUTATION';

export const VALIDATE = 'VALIDATE';

export const VERIFY_VALIDATION = 'VERIFY_VALIDATION';

export const VALIDATION = 'VALIDATION';

export const RESUME_FILE_EXISTS = 'RESUME_FILE_EXISTS';

export const PROJECT = 'PROJECT';

export const PROJECT_CHARGES = 'PROJECT_CHARGES';

export const ACTIVITIES = 'ACTIVITIES';

export const ACTIVITIES_TYPE = 'ACTIVITIES_TYPE';

export const CONSUMED_LOAD = 'CONSUMED_LOAD';
export const PROJECT_RAF = 'PROJECT_RAF';
export const PROJECT_ESTIMATED_LOAD = 'PROJECT_ESTIMATED_LOAD';

export const TASKS_BY_COLLABORATOR = 'TASKS_BY_COLLABORATOR';
export const TASKS_BY_COLLABORATOR_PROJECT = 'TASKS_BY_COLLABORATOR_PROJECT';

export const CLIENT = 'CLIENT';

export const PROJECT_TYPE = 'PROJECT_TYPE';
export const PROJECT_STATUS = 'PROJECT_STATUS';
export const PROJECT_DETAIL = 'PROJECT_DETAIL';
export const COLLAB_PROJECTS = 'COLLAB_PROJECTS';
export const TOTAL_IMPUTATIONS_BY_PROJECTS = 'TOTAL_IMPUTATIONS_BY_PROJECTS';

export const TASK = 'TASK';
export const COLLABORATOR = 'COLLABORATOR';
export const ARCHIVED_COLLABORATOR = 'ARCHIVED_COLLABORATOR';
export const ACTIVE_COLLABORATOR = 'ACTIVE_COLLABORATOR';
export const COLLABORATOR_DETAIL = 'COLLABORATOR_DETAIL';
export const IMPUTATION_TASK = 'IMPUTATION_TASK';

export const ATTACHMENT = 'ATTACHMENT';

export const JOB = 'JOB';
export const SERVICE = 'SERVICE';
export const CONTRACT_TYPE = 'CONTRACT_TYPE';
export const COLLABORATOR_STATUE = 'COLLABORATOR_STATUE';
export const STATUE_PROFESSIONNEL = 'STATUE_PROFESSIONNEL';

export const ABSENCE = 'ABSENCE';
export const ABSENCE_TYPE = 'ABSENCE_TYPE';
export const ACTIVATE_ABSENCE = 'ACTIVATE_ABSENCE';

export const AGENCY = 'AGENCY';

export const CURRENT_ABSENCE = 'CURRENT_ABSENCE';

export const FILTER_IMPUTATIONS = 'FILTER_IMPUTATIONS';

export const SAVE_FILTER_IMPUTATIONS = 'SAVE_FILTER_IMPUTATIONS';

export const ABSENCES_ACTIVITIES = 'ABSENCES_ACTIVITIES';

export const COLLABORATORS_AS_RESOURCES = 'COLLABORATORS_AS_RESOURCES';

export const USER_ACTION = 'USER_ACTION';

export const USER_AUTHORITIES_ACTION = 'USER_AUTHORITIES_ACTION';

export const EDIT_USER_ON_MODAL = 'EDIT_USER_ON_MODAL';

export const CURRENT_MONTH_IMPUTATIONS = 'CURRENT_MONTH_IMPUTATIONS';

export const CODE = 'CODE';

export const RESUME = 'RESUME';
export const TECHNO = 'TECHNO';
export const ROLE = 'ROLE';

export const DOCUMENT = 'DOCUMENT';
export const DOCUMENT_TYPE = 'DOCUMENT_TYPE';

export const STAFFING = 'STAFFING';

export const STAFFING_STATUS = 'STAFFING_STATUS';

export const COLLABORATOR_TASKS = 'COLLABORATOR_TASKS';

export const JAVA_COLLABORATOR_TASKS = 'JAVA_COLLABORATOR_TASKS';

export const ALL_COLLABORATOR_TASKS = 'ALL_COLLABORATOR_TASKS';

export const TASK_RAF = 'TASK_RAF';
export const DEPARTMENT = 'DEPARTMENT';

export const DROP_DOCUMENT = "DROP_DOCUMENT";
export const CLEAR_DOCUMENT = "CLEAR_DOCUMENT";

export const TASKS_PARSE = "TASKS_PARSE";

export const PARSER_ATTACHMENT_TASKS = 'PARSER_ATTACHMENT_TASKS';
export const COLLABORATOR_PROFILE = "COLLABORATOR_PROFILE";

export const GET_LOAD_PLANS = 'GET_LOAD_PLANS';
export const DELETE_LOAD_PLANS='DELETE_LOAD_PLANS';
export const SAVE_LOAD_PLAN="SAVE_LOAD_PLAN";
export const FILTER_LOAD_PLAN="FILTER_LOAD_PLAN";
export const SAVE_FILTER_LAOD_PLANS="SAVE_FILTER_LAOD_PLANS";
export const LOAD_PLAN_DETAIL="LOAD_PLAN_DETAIL";
export const COLLABORATOR_REDUCED_PROFILE="COLLABORATOR_REDUCED_PROFILE";
