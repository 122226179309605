import React, { useEffect } from 'react';
import {Route} from 'react-router-dom';
import {t} from '../../../i18n';
import {get} from 'lodash';
import { Redirect } from 'react-router-dom';

export const PrivateRouteComponent = props => {

    const {
        component: Component,
        user,
        permission,
        fallbackPath,
        ...rest
    } = props;

    const isAuthorized = hasPermission(user.permissions, permission);

    const RenderRedirect = props => {
        if(isAuthorized) {
            return <Component {...props}/>;
        }

        if(fallbackPath) {
            return <Redirect to={fallbackPath} />;
        }

        return (
            <div className="insufficient-authority">
                <div className="alert alert-danger">
                    {t('you_are_not_authorized_to_access')}
                </div>
            </div>
        );
    };

    if (!Component)
        throw new Error(
            `A component needs to be specified for private route for path ${
                rest.path
            }`
        );

    return <Route {...rest} render={() => <RenderRedirect {...props} />} />;
};
const hasPermission = (permissions, permission) => {
    if(Array.isArray(permission)) {
        return permission.some(p => hasPermission(permissions, p));
    }
    return get(permissions, 'modulePermissions.' + permission, false);
};

export const PrivateRoute = PrivateRouteComponent;

export default PrivateRoute;
