export const REQUEST_PASSWORD = 'REQUEST_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const EMAIL_NOTIFICATIONS = 'EMAIL_NOTIFICATIONS';
export const NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT';
export const LOGIN_USER = 'LOGIN_USER';
export const GET_USER = 'GET_USER';
export const GET_CURRENT_COLLABORATOR = 'GET_CURRENT_COLLABORATOR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const ACTIVE_MODULE_CHANGE = 'ACTIVE_MODULE_CHANGE';

export const PRERERENCES = 'PRERERENCES';

export const DATATABLES_STATE = 'DATATABLES_STATE';
export const RESET_DATATABLES_STATE = 'RESET_DATATABLES_STATE';

export const PAGE_TITLE = 'PAGE_TITLE';
