import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import translationEN from './lang/en/translation.json';
import translationFR from './lang/fr/translation.json';

import translationRecruitmentEN from '../src/lang/en/translation.json';
import translationRecruitmentFR from '../src/lang//fr/translation.json';

const enTranslation = {
    ...translationEN,
    ...translationRecruitmentEN,
};
const frTranslation = {
    ...translationFR,
    ...translationRecruitmentFR,
};

// the translations
const resources = {
    en: {
        translation: enTranslation,
    },
    fr: {
        translation: frTranslation,
    },
};

i18n.use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'fr',

        keySeparator: false,

        interpolation: {
            formatSeparator: ',',
            escapeValue: false, // react already safes from xss
        },
    });

export const t = (key, options) => {
    return options ? i18n.t(key, options) : i18n.t(key);
};

export default i18n;
