import { combineReducers } from 'redux';
import { Candidates } from './Candidates';
import {
    JobApplications,
    myJobApplications,
    archivedJobApplications,
    newJobApplications,
    favoriteJobApplications,
} from './JobApplications';
import { JobOpportunities } from './JobOpportunities';
import { Interviews } from './Interviews';
import { Interviewers } from './Interviewers';
import { Notifications } from './Notifications';
import Shared from './shared';
import { Collaborators } from './Collaborators';
import { Autohiring } from './Autohiring';
import dashboard from './Dashboard';

import { Agencies } from './RT/Agencies';
export default combineReducers({
    Candidates,
    JobApplications,
    myJobApplications,
    archivedJobApplications,
    favoriteJobApplications,
    newJobApplications,
    Collaborators,
    JobOpportunities,
    Interviews,
    Interviewers,
    Notifications,
    Shared,
    Autohiring,
    dashboard,
    Agencies,
});
