import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    LIST,
    FAILURE,
    SUCCESS,
    REQUEST
} from '../../../../redux/ActionType';
import { NEW_JOB_APPLICATIONS } from '../../constants';
import * as services from '../../../services/JobApplications';

function* list({ payload }) {
    try {
        const params = payload.params
            ? payload.params
            : yield select(state => state.dataTables.dataTableState.newApplications.params);
        const response = yield call(services.getNewJobApplications, params);
        yield put({
            type: SUCCESS(LIST(NEW_JOB_APPLICATIONS)),
            payload: { ...response }
        });
    } catch (error) {
        yield put({ type: FAILURE(LIST(NEW_JOB_APPLICATIONS)), payload: error });
    }
}

export function* watchNewJobApplications() {
    yield takeLatest(REQUEST(LIST(NEW_JOB_APPLICATIONS)), list);
}
