import {call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import * as services from '../../../services/JobOpportunities';

import {
    JOB_OPPORTUNITIES,
    JOB_OPPORTUNITIES_AS_RESOURCES,
    JOB_OPPORTUNITIES_COUNT,
    JOB_OPPORTUNITY_STATUS
} from '../../constants';
import {DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS} from '../../../../redux/ActionType';

export function* list(payload) {
    yield delay(100);

    try {
        const params = payload.params ? payload.params : yield select(state => state.dataTables.dataTableState.offers.params);
        const response = yield call(services.getJobOpportunities, params);
        yield put({ type: SUCCESS(LIST(JOB_OPPORTUNITIES)), response });
        if(payload.onSuccess) payload.onSuccess(response);
    } catch(error) {
        yield put({ type: FAILURE(LIST(JOB_OPPORTUNITIES)), error });
    }
}

export function* listAsResources(payload) {
    try {
        const response = yield call(services.getJobOpportunitiesAsResources, payload.params);
        yield put({ type: SUCCESS(LIST(JOB_OPPORTUNITIES_AS_RESOURCES)), response });
    } catch(error) {
        yield put({ type: FAILURE(LIST(JOB_OPPORTUNITIES_AS_RESOURCES)), error });
    }
}

export function* listStatus(payload) {
    let status = yield select( (state) => state.recruitment.JobOpportunities.Status.list);
    try {
        if(!status.length){
            const response = yield call(services.getJobOpportunityStatuses, payload.params);
            yield put({type: SUCCESS(LIST(JOB_OPPORTUNITY_STATUS)), response});
        }
        else
            yield put({type: SUCCESS(LIST(JOB_OPPORTUNITY_STATUS)), response: status});

    } catch (error) {
        yield put({type: FAILURE(LIST(JOB_OPPORTUNITY_STATUS)), error});
    }
}

export function* postStatus(payload) {
    try {
        const response = yield call(services.postJobOpportunityStatus, payload.jobOpportunityStatus);
        yield put({ type: SUCCESS(POST(JOB_OPPORTUNITY_STATUS)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(POST(JOB_OPPORTUNITY_STATUS)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* updateStatus(payload) {
    try {
        const response = yield call(services.putJobOpportunityStatus, payload.jobOpportunityStatus);
        yield put({ type: SUCCESS(PUT(JOB_OPPORTUNITY_STATUS)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(PUT(JOB_OPPORTUNITY_STATUS)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroyStatus(payload) {
    try {
        yield call(services.destroyJobOpportunityStatus, payload.id);
        yield put({ type: SUCCESS(DELETE(JOB_OPPORTUNITY_STATUS)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(JOB_OPPORTUNITY_STATUS)), error });
        if(payload.onFailure)payload.onFailure(error.response);
    }
}

export function* get(payload) {
    try {
        const response = yield call(services.getJobOpportunity, payload.id);
        yield put({ type: SUCCESS(GET(JOB_OPPORTUNITIES)), response });
        if(payload.onSuccess) payload.onSuccess(response);
    } catch(error) {
        yield put({ type: FAILURE(GET(JOB_OPPORTUNITIES)), error });
    }
}

export function* count(payload) {
    try {
        const response = yield call(services.getJobOpportunitiesCount, payload.params);
        yield put({ type: SUCCESS(GET(JOB_OPPORTUNITIES_COUNT)), response });
    } catch(error) {
        yield put({ type: FAILURE(GET(JOB_OPPORTUNITIES_COUNT)), error });
    }
}

export function* post(payload) {
    try {
        const response = yield call(services.postJobOpportunity, payload.jobOpportunity);
        yield put({ type: SUCCESS(POST(JOB_OPPORTUNITIES)), response });
        if (payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(POST(JOB_OPPORTUNITIES)), error });
    }
}

export function* update(payload) {
    try {
        const response = yield call(services.putJobOpportunity, payload.jobOpportunity);
        yield put({ type: SUCCESS(PUT(JOB_OPPORTUNITIES)), response });
        if (payload.onSuccess) payload.onSuccess(response);

    } catch(error) {
        yield put({ type: FAILURE(PUT(JOB_OPPORTUNITIES)), error });
    }
}

export function* destroy(payload) {
    try {
        yield call(services.destroyJobOpportunity, payload.id);
        yield put({ type: SUCCESS(DELETE(JOB_OPPORTUNITIES)), response:payload.id });
        if (payload.onSuccess) payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(DELETE(JOB_OPPORTUNITIES)), error });
    }
}

export function* watchJobOpportunities() {
    yield takeLatest(REQUEST(LIST(JOB_OPPORTUNITIES)), list);
    yield takeLatest(REQUEST(LIST(JOB_OPPORTUNITIES_AS_RESOURCES)), listAsResources);
    yield takeLatest(REQUEST(LIST(JOB_OPPORTUNITY_STATUS)), listStatus);
    yield takeLatest(REQUEST(POST(JOB_OPPORTUNITY_STATUS)), postStatus);
    yield takeLatest(REQUEST(PUT(JOB_OPPORTUNITY_STATUS)), updateStatus);
    yield takeLatest(REQUEST(DELETE(JOB_OPPORTUNITY_STATUS)), destroyStatus);
    yield takeLatest(REQUEST(GET(JOB_OPPORTUNITIES)), get);
    yield takeLatest(REQUEST(GET(JOB_OPPORTUNITIES_COUNT)), count);
    yield takeLatest(REQUEST(POST(JOB_OPPORTUNITIES)), post);
    yield takeLatest(REQUEST(PUT(JOB_OPPORTUNITIES)), update);
    yield takeEvery(REQUEST(DELETE(JOB_OPPORTUNITIES)), destroy);

}
