export const IMPUTATIONS_BY_COLLABORATOR = (collaboratorId, startDate, endDate) => 'collaboratorjava/api/imputations?date.greaterOrEqualThan=' + startDate + '&date.lessOrEqualThan=' + endDate + '&collaboratorId.equals=' + collaboratorId;

export const IMPUTATIONS = (startDate, endDate) => 'collaboratorjava/api/imputations?date.greaterOrEqualThan=' + startDate + '&date.lessOrEqualThan=' + endDate;

export const VALIDATE = 'collaborator/api/validations';

export const VERIFY_VALIDATION = (month, collaboratorId) => 'collaboratorjava/api/validations/collaborators/' + collaboratorId + '/month/' + month + '/verify-validation';
export const JAVA_VALIDATE = 'collaboratorjava/api/validations';

export const SAVE_IMPUTATIONS = 'collaboratorjava/api/imputations/save';

export const TASKS_BY_COLLABORATOR = collaboratorId =>
'collaboratorjava/api/collaborators/'+collaboratorId+'/tasks?isDone.equals=' + false;

 export const PHP_PROJECT_ENDPOINT = 'collaborator/api/projects';
 export const JAVA_PROJECT_ENDPOINT = 'collaboratorjava/api/projects';

 export const JAVA_PROJECT_LOAD_ENDPOINT = projectId => 'collaboratorjava/api/projects/'+projectId+'/projects-load';

 export const JAVA_PROJECT_RAF_ENDPOINT = projectId => 'collaboratorjava/api/projects/'+projectId+'/total-raf';

 export const JAVA_PROJECT_ESTIMATED_LOAD_ENDPOINT = projectId => 'collaboratorjava/api/projects/'+projectId+'/estimated-load';

 export const PARSER_ATTACHMENT_TASKS = 'collaboratorjava/api/tasks/attachment';


 export const PHP_PROJECT_CUSTOM_ENDPOINT = 'collaborator/api/customproject';
 export const JAVA_PROJECT_CUSTOM_ENDPOINT = 'collaboratorjava/api/projects';

 export const JAVA_IMPUTATION_ENDPOINT = 'collaboratorjava/api/imputations';


export const ABSENCE_ENDPOINT = 'collaborator/api/absences';
export const JAVA_ABSENCE_ENDPOINT = 'collaboratorjava/api/absences';
export const ABSENCE_TYPE_ENDPOINT = 'collaborator/api/r_t_absence_types';
export const JAVA_ABSENCE_TYPE_ENDPOINT = 'collaboratorjava/api/rtabsence-types';
export const ACTIVATE_ABSENCE = id => 'collaborator/api/absences/' + id + '/validate';
export const JAVA_ACTIVITY_TYPES = 'collaboratorjava/api/rtactivity-types';

export const JAVA_CLIENT_ENDPOINT = 'collaboratorjava/api/rtclients';

export const JAVA_PROJECT_TYPE_ENDPOINT = 'collaboratorjava/api/rtproject-types';

export const PROJECT_STATUS_ENDPOINT = 'collaboratorjava/api/rtproject-statues';

export const PROJECT_DETAIL_ENDPOINT = ProjectId =>
    'collaborator/api/projects/' + ProjectId;

export const JAVA_TASK_ENDPOINT = 'collaboratorjava/api/tasks';
/* export const ADD_TASK_ENDPOINT = 'collaborator/api/customTasks/add';
export const MODIFY_TASK_ENDPOINT = 'collaborator/api/customTasks/modify'; */

export const COLLABORATOR = 'collaborator/api/collaborators';
export const COLLABORATOR_JAVA = 'collaboratorjava/api/collaborators';
export const ACTIVITIES = 'collaborator/api/activities';
export const JAVA_ENDPOINT_ACTIVITIES = 'collaboratorjava/api/activities';
export const IMPUTATION_TASK = 'collaborator/api/imputations/tasks/';

export const JAVA_JOB_ENDPOINT = 'collaboratorjava/api/rtjobs';
export const JAVA_CONTRACT_TYPE_ENDPOINT = 'collaboratorjava/api/rtcontract-types';
export const JAVA_SERVICE_ENDPOINT = 'collaboratorjava/api/rtservices';
export const JAVA_COLLABORATOR_STATUE_ENDPOINT = 'collaboratorjava/api/rtcollaborator-statues';
export const STATUE_PROFESSIONNEL_ENDPOINT = 'collaboratorjava/api/rt-statut-professionnels';

export const JAVA_AGENCY_ENDPOINT = 'collaboratorjava/api/rt-agencies';

export const USER_ENDPOINT = 'api/users';

export const CURRENT_ABSENCE = 'collaboratorjava/api/statistics/absences';

export const FILTER_IMPUTATION = 'collaboratorjava/api/filter_imputations';

export const ABSENCES_ACTIVITIES = (start, end) => 'collaboratorjava/api/absences-activities?startDate.greaterOrEqualThan='+start+'&endDate.lessOrEqualThan='+end;

export const COLLABORATORS_AS_RESOURCES = 'collaboratorjava/api/collaborators';

export const CURRENT_MONTH_IMPUTATIONS = 'collaboratorjava/api/statistics/monthsImputations';

export const CODES = 'collaboratorjava/api/collaborators/code-email';

export const RESUME = 'collaboratorjava/api/resumes';
export const POST_RESUME = 'collaboratorjava/api/resumesCascade';
export const GET_RESUME = (collaboratorEmail) => 'collaborator/api/resumes?collaborator.email=' + collaboratorEmail;
export const GENERATE_RESUME = (id) => 'collaboratorjava/api/resumes/generate/'+id;
export const DOWNLOAD_RESUME = (id) => 'collaboratorjava/api/resumes/download/'+id;
export const RESUME_FILE_EXISTS_ENDPOINT = (collaboratorId) => 'collaborator/api/resumes/collaborator/'+collaboratorId+'/fileExists';
export const TECHNO = 'collaboratorjava/api/cv-technologies';
export const ROLE = 'collaboratorjava/api/cvroles';

export const DOCUMENT = 'collaborator/api/documents';
export const DOCUMENT_JAVA = 'collaboratorjava/api/documents';
export const DOCUMENT_DELETE = 'collaboratorjava/api/documents';

export const COLLABORATOR_DOCUMENTS = 'collaborator/api/documents?collaborator.email=';
export const DOWNLOAD_DOCUMENT = (id) => 'collaborator/api/documents/'+id+'/download';
export const DOWNLOAD_DOCUMENT_JAVA = (id) => 'collaboratorjava/api/documents/'+id+'/download';
export const EXPORT_XLS_CHARGES = 'collaboratorjava/api/project/imputations/charges/export';
export const EXPORT_IMPUTATION_XLS_CHARGES = 'collaboratorjava/api/imputations/charges/export';
export const STREAM_DOCUMENT = (id) => 'collaboratorjava/api/documents/'+id+'/stream';
export const DOCUMENT_TYPE = 'collaboratorjava/api/rtdocument-types';

export const STAFFING = 'collaboratorjava/api/staffings';

export const STAFFING_STATUS = 'collaboratorjava/api/rtstaffing-statuses';

export const COLLABORATOR_TASKS_ENDPOINT = '/collaborator/api/customTasks';

export const SEARCH_COLLABORATOR_TASKS_ENDPOINT = '/collaboratorjava/api/_search/tasks';

export const JAVA_COLLABORATOR_TASKS_ENDPOINT = '/collaboratorjava/api/tasks';

export const JAVA_COLLABORATOR_MY_TASKS_ENDPOINT = collaboratorId => '/collaboratorjava/api/collaborators/'+collaboratorId+'/tasks';

export const JAVA_COLLABORATORS__AGENCIES = '/collaboratorjava/api/collaborators-agencies';

export const ALL_COLLABORATOR_TASKS_ENDPOINT = '/collaborator/api/allCollaboratorTasks';

export const DEPARTMENT_ENDPOINT = '/collaborator/api/rtdepartments';
export const JAVA_DEPARTMENT_ENDPOINT = '/collaboratorjava/api/rtdepartments';

export const VALIDATIONS_ENDPOINT = '/collaboratorjava/api/validations';

export const JAVA_PROJECT_COLLAB_ENDPOINT = collaboratorId => '/collaboratorjava/api/collaborators/'+collaboratorId+'/projects';

export const JAVA_PROJECT_TOTAL_IMPUTATIONS_ENDPOINT = projectId => '/collaboratorjava/api/projects/'+projectId+'/total-imputation';

export const JAVA_TASK_TOTAL_IMPUTATIONS_ENDPOINT = taskId => '/collaboratorjava/api/tasks/'+taskId+'/total-imputation';

export const TASKS_BY_COLLABORATOR_PROJECT = (collaboratorId, projectId) =>
    'collaboratorjava/api/collaborators/' + collaboratorId + '/tasks?projectId.equals=' + projectId + '&isDone.equals=' + false + '&size=1000';

export const COLLABORATORS_REDUCED = 'collaboratorjava/api/collaborators-reduced';

export const SEARCH_COLLABORATORS = 'collaboratorjava/api/_search/collaborators';

//LoadPlan endpoints

export const SAVE_LOAD_PLANS = 'collaboratorjava/api/load-plan-collaborators/saveall';
export const LOAD_PLANS_COLLABORATORS = (startDate, endDate) => 'collaboratorjava/api/load-plan-collaborators?size=99999&date.greaterOrEqualThan=' + startDate + '&date.lessOrEqualThan=' + endDate;
export const LOAD_PLANS_COLLABORATORS_BY_COLLABORATOR = (collaboratorId, startDate, endDate) => 'collaboratorjava/api/load-plan-collaborators?size=99999&date.greaterOrEqualThan=' + startDate + '&date.lessOrEqualThan=' + endDate + '&collaboratorId.equals=' + collaboratorId;

export const LOAD_PLANS_ENDPOINT="collaboratorjava/api/load-plan-collaborators";

export const FILTER_LOAD_PLAN = 'collaboratorjava/api/filter-load-plan-collaborators';

export const ELASTICSEARCH_COLLABORATOR_REINDEX = 'collaboratorjava/api/elasticsearch/index';
