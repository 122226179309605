import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

import rootReducer from '../App/redux/reducers';
import rootSaga from '../App/redux/sagas';

const persistConfig = {
    key: 'root',
    whitelist:['Auth', 'layout'],
    storage,
    timeout: 86400,
};

const configureStore = (config) => {
    const persistedReducer = persistReducer(persistConfig, rootReducer(config.reducers));
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
    const persistor = persistStore(store);
    return {
        store,
        persistor,
        runSaga: sagaMiddleware.run(rootSaga,config.watchers),
    };
};
export default configureStore;
