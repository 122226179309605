import {call, put, select, takeEvery, takeLatest, all} from 'redux-saga/effects';
import * as services from '../../../services/JobApplications';

import {
    ALL_JOB_APPLICATION_STATUS,
    ATTACHMENT,
    DISPONIBILITY,
    JOB_APPLICATION_COMMENTS,
    JOB_APPLICATION_ASSIGNMENTS,
    JOB_APPLICATION_SOURCE,
    JOB_APPLICATION_STATUS,
    JOB_APPLICATION_TIMELINE,
    JOB_APPLICATIONS,
    JOB_APPLICATIONS_COUNT,
    REQUEST_UPDATE_JOB_APPLICATION,
    NEW_JOB_APPLICATIONS_ATTACHMENTS
} from '../../constants';
import {
    ARCHIVE,
    DELETE,
    FAILURE,
    FAVORITE,
    GET,
    LIST,
    POST,
    PUT,
    REQUEST,
    SUCCESS,
    UNFAVORITE,
    MULTIPLE
} from '../../../../redux/ActionType';
import {getJobApplicationComments, getJobApplicationAssignments, getJobApplicationTimeline} from '../../actions/JobApplications';

export function* list(payload) {
    try {
        const params = payload.params ? payload.params : yield select(state => state.dataTables.dataTableState.applications.params);
        const response = yield call(services.getJobApplications, params);
        yield put({type: SUCCESS(LIST(JOB_APPLICATIONS)), response});
    } catch (error) {
        yield put({type: FAILURE(LIST(JOB_APPLICATIONS)), error});
    }
}

export function* listStatus(payload) {
    try {
        const response = yield call(services.getJobApplicationsStatus, payload.params);
        yield put({type: SUCCESS(LIST(JOB_APPLICATION_STATUS)), response});

    } catch (error) {
        yield put({type: FAILURE(LIST(JOB_APPLICATION_STATUS)), error});
    }
}

export function* postStatus(payload) {
    try {
        const response = yield call(services.postJobApplicationStatus, payload.jobApplicationStatus);
        yield put({type: SUCCESS(POST(JOB_APPLICATION_STATUS)), response});
        if (payload.onSuccess) payload.onSuccess();

    } catch (error) {
        yield put({type: FAILURE(POST(JOB_APPLICATION_STATUS)), error});
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* updateStatus(payload) {
    try {
        const response = yield call(services.putJobApplicationStatus, payload.jobApplicationStatus);
        yield put({type: SUCCESS(PUT(JOB_APPLICATION_STATUS)), response});
        if (payload.onSuccess) payload.onSuccess();

    } catch (error) {
        yield put({type: FAILURE(PUT(JOB_APPLICATION_STATUS)), error});
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroyStatus(payload) {
    try {
        yield call(services.destroyJobApplicationStatus, payload.id);
        yield put({type: SUCCESS(DELETE(JOB_APPLICATION_STATUS)), response: payload.id});
        if (payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({type: FAILURE(DELETE(JOB_APPLICATION_STATUS)), error});
        if (payload.onFailure) payload.onFailure(error.response);

    }
}

export function* listDisponibility(payload) {
    let disponibility = yield select((state) => state.recruitment.JobApplications.Disponibility.list);
    try {
        if (!disponibility.length) {
            const response = yield call(services.getDisponibility, payload.params);
            yield put({type: SUCCESS(LIST(DISPONIBILITY)), response});
            if (payload.onSuccess) payload.onSuccess();
        }
    } catch (error) {
        yield put({type: FAILURE(LIST(DISPONIBILITY)), error});
    }
}
export function* listSource(payload) {
    let sources = yield select((state) => state.recruitment.JobApplications.Source.list);
    try {
        if (!sources.length) {
            sources = yield call(services.getJobApplicationsSource, payload.params);
        }
        yield put({type: SUCCESS(LIST(JOB_APPLICATION_SOURCE)), response: sources});
        if (payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({type: FAILURE(LIST(JOB_APPLICATION_SOURCE)), error});
    }
}

export function* postSource(payload) {
    try {
        const response = yield call(services.postJobApplicationSource, payload.jobApplicationSource);
        yield put({type: SUCCESS(POST(JOB_APPLICATION_SOURCE)), response});
        if (payload.onSuccess) payload.onSuccess();

    } catch (error) {
        yield put({type: FAILURE(POST(JOB_APPLICATION_SOURCE)), error});
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* updateSource(payload) {
    try {
        const response = yield call(services.putJobApplicationSource, payload.jobApplicationSource);
        yield put({type: SUCCESS(PUT(JOB_APPLICATION_SOURCE)), response});
        if (payload.onSuccess) payload.onSuccess();

    } catch (error) {
        yield put({type: FAILURE(PUT(JOB_APPLICATION_SOURCE)), error});
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroySource(payload) {
    try {
        yield call(services.destroyJobApplicationSource, payload.id);
        yield put({type: SUCCESS(DELETE(JOB_APPLICATION_SOURCE)), response: payload.id});
        if (payload.onSuccess) payload.onSuccess();

    } catch (error) {
        yield put({type: FAILURE(DELETE(JOB_APPLICATION_SOURCE)), error});
        if(payload.onFailure)payload.onFailure(error.response);
    }
}


export function* listTimeline(payload) {
    try {
        const response = yield call(services.getJobApplicationTimeline, payload.params);
        yield put({type: SUCCESS(LIST(JOB_APPLICATION_TIMELINE)), response});
    } catch (error) {
        yield put({type: FAILURE(LIST(JOB_APPLICATION_TIMELINE)), error});
    }
}

export function* count(payload) {
    try {
        const response = yield call(services.getJobApplicationsCount, payload.params);
        yield put({type: SUCCESS(GET(JOB_APPLICATIONS_COUNT)), response});
    } catch (error) {
        yield put({type: FAILURE(GET(JOB_APPLICATIONS_COUNT)), error});
    }
}

export function* get(payload) {
    try {
        const response = yield call(services.getJobApplication, payload.id);
        yield put({type: SUCCESS(GET(JOB_APPLICATIONS)), response});
        if (payload.onSuccess) payload.onSuccess(response);
    } catch (error) {
        yield put({type: FAILURE(GET(JOB_APPLICATIONS)), error});
    }
}


export function* post(payload) {
    try {
        const response = yield call(services.postJobApplication, payload.jobApplication);
        yield put({type: SUCCESS(POST(JOB_APPLICATIONS)), response});
        yield put({type: REQUEST(LIST(JOB_APPLICATIONS)), response});
    } catch (error) {
        yield put({type: FAILURE(POST(JOB_APPLICATIONS)), error});
    }
}

export function* postAttachment(payload) {
    try {
        const response = yield call(services.postAttachment,
            payload.attachment,
            payload.onProgress,
            payload.onSuccess
        );
        yield put({type: SUCCESS(POST(ATTACHMENT)), response});
    } catch (error) {
        payload.onError(error);
        yield put({type: FAILURE(POST(ATTACHMENT)), error});
    }
}

export function* postComment(payload) {
    try {
        const response = yield call(services.postComment,
            payload.comment,
        );
        yield put({type: SUCCESS(POST(JOB_APPLICATION_COMMENTS)), response});
        if(!!response && payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({type: FAILURE(POST(JOB_APPLICATION_COMMENTS)), error});
        payload.onFailure(error);
    }
}

export function* putComment(payload) {
    try {
        const response = yield call(services.putComment,
            payload.comment,
        );
        yield put({type: SUCCESS(PUT(JOB_APPLICATION_COMMENTS)), response});
    } catch (error) {
        yield put({type: FAILURE(PUT(JOB_APPLICATION_COMMENTS)), error});
    }
}

export function* putJobApplicationAssignment(payload) {
    try {
        const response = yield call(services.putJobApplicationAssignment,
            payload.jobApplicationAssignment,
        );
        yield put({type: SUCCESS(PUT(JOB_APPLICATION_ASSIGNMENTS)), response});
    } catch (error) {
        yield put({type: FAILURE(PUT(JOB_APPLICATION_ASSIGNMENTS)), error});
    }
}

export function* listAttachments(payload) {
    try {
        const response = yield call(services.listAttachments, payload.params);
        yield put({type: SUCCESS(LIST(ATTACHMENT)), response});
    } catch (error) {
        payload.onError();
        yield put({type: FAILURE(LIST(ATTACHMENT)), error});
    }
}


export function* update(payload) {
    try {
        const response = yield call(services.putJobApplication, payload.jobApplication);
        if (payload.onSuccess) payload.onSuccess();
        yield put({type: SUCCESS(PUT(JOB_APPLICATIONS)), response});
        const params = {'jobApplicationId.equals': response.id, sort: 'createdAt,desc'};
        yield put(getJobApplicationTimeline(params));
        yield put(getJobApplicationComments(params));
        yield put(getJobApplicationAssignments(params));

    } catch (error) {
        yield put({type: FAILURE(PUT(JOB_APPLICATIONS)), error});
    }
}

export function* addAttachmentsToNewApplication(payload) {
    try {
        const response = yield call(services.addAttachmentsToNewApplicationService, payload.jobApplication);
        if (payload.onSuccess) payload.onSuccess();
        yield put({type: SUCCESS(PUT(NEW_JOB_APPLICATIONS_ATTACHMENTS)), response});
    } catch (error) {
        yield put({type: FAILURE(PUT(NEW_JOB_APPLICATIONS_ATTACHMENTS)), error});
    }
}

export function* destroy(payload) {
    try {
        yield call(services.destroyJobApplication, payload.id);
        yield put({type: SUCCESS(DELETE(JOB_APPLICATIONS))});
        if(payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({type: FAILURE(DELETE(JOB_APPLICATIONS)), error});
    }
}

export function* destroyMultiple(payload) {
    try {
        yield all(payload.ids.map(id => call(services.destroyJobApplication, id)));
        yield put({type: SUCCESS(DELETE(MULTIPLE(JOB_APPLICATIONS)))});
        if(payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({type: FAILURE(DELETE(MULTIPLE(JOB_APPLICATIONS))), error});
    }
}

export function* destroyAttachment(payload) {
    try {
        yield call(services.destroyAttachment, payload.id);
        yield put({type: SUCCESS(DELETE(ATTACHMENT)), response: payload.id});
    } catch (error) {
        yield put({type: FAILURE(DELETE(ATTACHMENT)), error});
    }
}

export function* deleteComment(payload) {
    try {
        yield call(services.deleteComment, payload.id);
        yield put({type: SUCCESS(DELETE(JOB_APPLICATION_COMMENTS)), response: payload.id});
        if(payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({type: FAILURE(DELETE(JOB_APPLICATION_COMMENTS)), error});
    }
}

export function* deleteJobApplicationAssignment(payload) {
    try {
        yield call(services.deleteJobApplicationAssignment, payload.id);
        yield put({type: SUCCESS(DELETE(JOB_APPLICATION_ASSIGNMENTS)), response: payload.id});
        if(payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({type: FAILURE(DELETE(JOB_APPLICATION_ASSIGNMENTS)), error});
    }
}

export function* comments(payload) {
    try {
        const response = yield call(services.comments, payload.params);
        yield put({type: SUCCESS(LIST(JOB_APPLICATION_COMMENTS)), response});
    } catch (error) {
        yield put({type: FAILURE(DELETE(JOB_APPLICATION_COMMENTS)), error});
    }
}

export function* jobApplicationAssignments(payload) {
    try {
        const response = yield call(services.jobApplicationAssignments, payload.params);
        yield put({type: SUCCESS(LIST(JOB_APPLICATION_ASSIGNMENTS)), response});
    } catch (error) {
        yield put({type: FAILURE(DELETE(JOB_APPLICATION_ASSIGNMENTS)), error});
    }
}

export function* updateAllJobStatus(payload) {
    try {
        const response = yield call(services.putAllJobApplicationStatus, payload.jobStatus);
        yield put({type: SUCCESS(PUT(ALL_JOB_APPLICATION_STATUS)), response});
    } catch (error) {
        yield put({type: FAILURE(PUT(ALL_JOB_APPLICATION_STATUS)), error});
    }
}

export function* archive(payload) {
    try {
        const response = yield call(services.archiveJobApplication, payload.id);
        yield put({ type: SUCCESS(ARCHIVE(JOB_APPLICATIONS)), response });
        if(payload.onSuccess)payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(ARCHIVE(JOB_APPLICATIONS)), error });
    }
}

export function* favorite(payload) {
    try {
        const response = yield call(services.favoriteJobApplication, payload.id, payload.technologyId);
        yield put({ type: SUCCESS(FAVORITE(JOB_APPLICATIONS)), response });
        if(payload.onSuccess)payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(FAVORITE(JOB_APPLICATIONS)), error });
    }
}
export function* unfavorite(payload) {
    try {
        const response = yield call(services.unfavoriteJobApplication, payload.id, payload.technologyId);
        yield put({ type: SUCCESS(UNFAVORITE(JOB_APPLICATIONS)), response });
        if(payload.onSuccess)payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(UNFAVORITE(JOB_APPLICATIONS)), error });
    }
}

export function* requestUpdate({id, onSuccess, onError}) {
    try {
        yield call(services.requestUpdate, id);
        yield put({ type: SUCCESS(POST(REQUEST_UPDATE_JOB_APPLICATION)) });
        if(onSuccess) onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(POST(REQUEST_UPDATE_JOB_APPLICATION)), error });
        if(onError) onError();
    }
}

export function* watchJobApplications() {
    yield takeLatest(REQUEST(LIST(JOB_APPLICATIONS)), list);
    yield takeLatest(REQUEST(FAVORITE(JOB_APPLICATIONS)), favorite);
    yield takeLatest(REQUEST(UNFAVORITE(JOB_APPLICATIONS)), unfavorite);
    yield takeLatest(REQUEST(ARCHIVE(JOB_APPLICATIONS)), archive);
    yield takeLatest(REQUEST(LIST(ATTACHMENT)), listAttachments);
    yield takeLatest(REQUEST(LIST(JOB_APPLICATION_STATUS)), listStatus);
    yield takeLatest(REQUEST(POST(JOB_APPLICATION_STATUS)), postStatus);
    yield takeLatest(REQUEST(PUT(JOB_APPLICATION_STATUS)), updateStatus);
    yield takeLatest(REQUEST(DELETE(JOB_APPLICATION_STATUS)), destroyStatus);
    yield takeLatest(REQUEST(LIST(DISPONIBILITY)), listDisponibility);
    yield takeLatest(REQUEST(LIST(JOB_APPLICATION_SOURCE)), listSource);
    yield takeLatest(REQUEST(POST(JOB_APPLICATION_SOURCE)), postSource);
    yield takeLatest(REQUEST(PUT(JOB_APPLICATION_SOURCE)), updateSource);
    yield takeLatest(REQUEST(DELETE(JOB_APPLICATION_SOURCE)), destroySource);
    yield takeLatest(REQUEST(LIST(JOB_APPLICATION_TIMELINE)), listTimeline);
    yield takeLatest(REQUEST(LIST(JOB_APPLICATION_COMMENTS)), comments);
    yield takeLatest(REQUEST(LIST(JOB_APPLICATION_ASSIGNMENTS)), jobApplicationAssignments);
    yield takeLatest(REQUEST(GET(JOB_APPLICATIONS_COUNT)), count);
    yield takeLatest(REQUEST(GET(JOB_APPLICATIONS)), get);
    yield takeLatest(REQUEST(POST(JOB_APPLICATIONS)), post);
    yield takeLatest(REQUEST(POST(ATTACHMENT)), postAttachment);
    yield takeLatest(REQUEST(PUT(JOB_APPLICATIONS)), update);
    yield takeLatest(REQUEST(PUT(NEW_JOB_APPLICATIONS_ATTACHMENTS)), addAttachmentsToNewApplication);
    yield takeEvery(REQUEST(DELETE(JOB_APPLICATIONS)), destroy);
    yield takeLatest(REQUEST(DELETE(MULTIPLE(JOB_APPLICATIONS))), destroyMultiple);
    yield takeEvery(REQUEST(DELETE(ATTACHMENT)), destroyAttachment);
    yield takeEvery(REQUEST(POST(JOB_APPLICATION_COMMENTS)), postComment);
    yield takeEvery(REQUEST(PUT(JOB_APPLICATION_COMMENTS)), putComment);
    yield takeEvery(REQUEST(DELETE(JOB_APPLICATION_COMMENTS)), deleteComment);
    yield takeEvery(REQUEST(PUT(JOB_APPLICATION_ASSIGNMENTS)), putJobApplicationAssignment);
    yield takeEvery(REQUEST(DELETE(JOB_APPLICATION_ASSIGNMENTS)), deleteJobApplicationAssignment);
    yield takeEvery(SUCCESS(DELETE(JOB_APPLICATION_ASSIGNMENTS)), list);
    yield takeEvery(REQUEST(PUT(JOB_APPLICATION_ASSIGNMENTS)), putJobApplicationAssignment);
    yield takeEvery(REQUEST(DELETE(JOB_APPLICATION_ASSIGNMENTS)), deleteJobApplicationAssignment);
    yield takeEvery(SUCCESS(DELETE(JOB_APPLICATION_ASSIGNMENTS)), list);
    yield takeEvery(REQUEST(PUT(ALL_JOB_APPLICATION_STATUS)), updateAllJobStatus);
    yield takeLatest(REQUEST(POST(REQUEST_UPDATE_JOB_APPLICATION)), requestUpdate);

}

export * from './myJobApplications';
export * from './archivedJobApplications';
export * from './favoriteJobApplications';
export * from './newJobApplications';
