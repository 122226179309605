import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App/assets/scss/ReportIssue/style.scss';
import './App/assets/scss/EditText/style.scss';
import './App/assets/scss/Style.scss';
import './App/assets/scss/EditInput/style.scss';

import configureStore from './redux';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Keycloak from 'keycloak-js';
import {
    initOptions,
    keycloakConfig,
    isKeycloakDisabled
} from './keycloak/config';
import App from './App';
import { init } from './axios';

const redux = {
    actions: {},
    reducers: {},
    watchers: [],
};

const { store, persistor } = configureStore(redux);
let axios, kc;
const AppContainer = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
);
const rootElement = document.getElementById('root');

if (isKeycloakDisabled) {
    ReactDOM.render(<AppContainer />, rootElement);
} else {
    kc = new Keycloak(keycloakConfig);
    axios = init(process.env.REACT_APP_SERVER_API_URL, kc);
    try {
        kc.init(initOptions).then((authenticated) => {
            if (authenticated) {
                store.getState().keycloak = kc;
                ReactDOM.render(<AppContainer />, rootElement);
            } else {
                kc.login();
            }
        });
    } catch (error) {
        ReactDOM.render(<div />, rootElement);
    }
}

export { store, axios };
