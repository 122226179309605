import React from 'react';
import {Link, matchPath, Route, withRouter} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import classNames from 'classnames';
let routes= [];
const getPaths = (pathname) => {
    const paths = ['/'];

    if (pathname === '/') return paths;

    pathname.split('/').reduce((prev, curr) => {
        const currPath = `${prev}/${curr}`;
        paths.push(currPath);
        return currPath;
    });
    return paths;
};

const findRouteName = (url) => {
    const aroute = routes.find(route => matchPath(url, {path: route.path, exact: route.exact}));
    return (aroute && aroute.name) ? aroute.name : null;
};

const findRouteClassName = (url) => {
    const aroute = routes.find(route => matchPath(url, {path: route.path, exact: route.exact}));
    return (aroute && aroute.className) ? aroute.className : null;
};

let currentRouteName = [];
const BreadcrumbsItem = ({match}) => {
    const routeName = findRouteName(match.url);
    const routeClassName = findRouteClassName(match.url);
    currentRouteName = [...currentRouteName,routeName];
    if (routeName) {
        return (
            match.isExact ?
                <BreadcrumbItem active>{routeName}</BreadcrumbItem>
                :
                <BreadcrumbItem className={routeClassName}>
                    <Link to={match.url || ''}>
                        {routeName}
                    </Link>
                </BreadcrumbItem>
        );
    }
    return null;
};

const Breadcrumbs = (args, props) => {
    const paths = getPaths(args.location.pathname);
    const items = paths.map((path, i) => <Route key={i.toString()} path={path} component={BreadcrumbsItem} {...props}/>);
    return (
        <Breadcrumb>
            {items.length>2?items:''}
        </Breadcrumb>
    );
};

const defaultProps = {
    tag: 'div',
    className: '',
    appRoutes: [{path: '/', exact: true, name: 'Home', component: null}]
};

const NovyBreadcrumbs = (props) => {
    const {className, tag: Tag, appRoutes, ...attributes} = props;
    routes = appRoutes;
    delete attributes.children;
    const classes = classNames(className);

    return (
        <Tag className={classNames(classes, 'd-flex justify-content-between')}>
            <h4 className={classNames('novy-page-title', findRouteClassName(props.location.pathname))}>{props.hasPageTitle?props.pageTitle:findRouteName(props.location.pathname)}</h4>
            <Route path="/:path" component={Breadcrumbs} {...attributes} {...props} />
        </Tag>
    );
};

NovyBreadcrumbs.defaultProps = defaultProps;

export default withRouter(NovyBreadcrumbs);
