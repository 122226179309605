import React from 'react';
import Dropzone from 'react-dropzone';
import { t } from '../../../i18n';

export default ({ onDrop, multiple, placeholder }) => {
    return (
        <Dropzone onDrop={onDrop} multiple={multiple}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="text-center m-0 p-4">
                            <i className="fa fa-plus-circle fa-3x" />
                            <span className="d-block mt-3">
                                {placeholder
                                    ? placeholder
                                    : t('drag_and_drop_files_here')}
                            </span>
                        </p>
                    </div>
                </section>
            )}
        </Dropzone>
    );
};
