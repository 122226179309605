import {ACTIVE_MODULE_CHANGE, PAGE_TITLE} from '../../constants';
import {CLEAR, SET} from '../../ActionType';


const INIT_STATE = {
    activeModule: {},
    sidebarOpen: true,
    pageTitle: null,
};


export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case ACTIVE_MODULE_CHANGE:
            return {...state, activeModule: action.payload};
        case 'SIDEBAR_CHANGED': return {...state, sidebarOpen: action.payload};

        case SET(PAGE_TITLE):
            return {...state, pageTitle: action.title};
        case CLEAR(PAGE_TITLE):
            return {...state, pageTitle: null};

        default:
            return state;
    }
}
