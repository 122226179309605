import {call, put} from 'redux-saga/effects';
import get from "lodash/get";
import {login, getAccount, getCurrentCollaborator, passwordReset, requestPasswordApi, changePassword} from '../../../../services/auth';
import {takeLatest} from 'redux-saga/effects';
import {RESET_PASSWORD, GET_USER, GET_CURRENT_COLLABORATOR, LOGIN_USER, REQUEST_PASSWORD, CHANGE_PASSWORD} from '../../constants';
import {FAILURE, SUCCESS, REQUEST} from '../../ActionType';

const ERROR_USER_NOT_FOUND = "user could not be found";

export function* index(payload) {
    try {
        const response = yield call(login, payload);
        yield put({ type: SUCCESS(LOGIN_USER), response, onSuccess: payload.onSuccess });
    } catch(error) {
        yield put({ type: FAILURE(LOGIN_USER), error });
    }
}

export function* resetPassword(payload) {
    try {
        const response = yield call(passwordReset, payload.request);
        yield put({ type: SUCCESS(RESET_PASSWORD), response });
    } catch(error) {
        yield put({ type: FAILURE(RESET_PASSWORD), error });
    }
}

export function* requestPassword(payload) {
    try {
        const response = yield call(requestPasswordApi, payload.email);
        yield put({ type: SUCCESS(REQUEST_PASSWORD), response });
    } catch(error) {
        yield put({ type: FAILURE(REQUEST_PASSWORD), error });
    }
}

export function* passwordChange(payload) {
    try {
        const response = yield call(changePassword, payload.request);
        yield put({ type: SUCCESS(CHANGE_PASSWORD), response });
    } catch(error) {
        yield put({ type: FAILURE(CHANGE_PASSWORD), error });
    }
}

export function* account(payload) {
    try {
        const response = yield call(getAccount);
        yield put({type: SUCCESS(GET_USER), response});
        if(payload.onSuccess)
            payload.onSuccess();
    } catch(error) {
        const userNotFound =
            get(error, 'response.data.status') === 401 &&
            get(error, 'response.data.title', '').toLowerCase().indexOf(ERROR_USER_NOT_FOUND) > -1;
        yield put({ type: FAILURE(GET_USER), error, userNotFound });
    }
}

export function* currentCollaborator() {
    try {
        const response = yield call(getCurrentCollaborator);
        yield put({type: SUCCESS(GET_CURRENT_COLLABORATOR), response});
    } catch(error) {
        const response = get(error, 'response', {});
        const collaboratorNotFound = response.status === 404;
        yield put({ type: FAILURE(GET_CURRENT_COLLABORATOR), error, collaboratorNotFound });
    }
}

export function* watchUserAuthentication() {
    yield takeLatest(REQUEST(LOGIN_USER), index);
    yield takeLatest(REQUEST(RESET_PASSWORD), resetPassword);
    yield takeLatest(REQUEST(CHANGE_PASSWORD), passwordChange);
    yield takeLatest(REQUEST(REQUEST_PASSWORD), requestPassword);
    yield takeLatest(SUCCESS(LOGIN_USER), account);
    yield takeLatest(REQUEST(GET_USER), account);
    yield takeLatest(SUCCESS(GET_USER), currentCollaborator);
    yield takeLatest(REQUEST(GET_CURRENT_COLLABORATOR), currentCollaborator);
}
