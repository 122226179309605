import {
    LOGIN_ENDPOINT,
    ACCOUNT_ENDPOINT,
    CURRENT_COLLABORATOR_ENDPOINT,
    PASSWORD_RESET_ENDPOINT,
    PASSWORD_REQUEST_ENDPOINT,
    CHANGE_PASSWORD_ENDPOINT,
} from './endpoints';

import { axios } from '../../index';

export const login = (request) => {
    request.rememberMe = true;
    return axios
        .post(LOGIN_ENDPOINT, request.user)
        .then((response) => response.data);
};
export const passwordReset = (request) => {
    return axios
        .post(PASSWORD_RESET_ENDPOINT, request)
        .then((response) => response.data);
};
export const requestPasswordApi = ({ email }) => {
    return axios
        .post(PASSWORD_REQUEST_ENDPOINT, email, {
            headers: { 'Content-Type': 'text/plain' },
        })
        .then((response) => response.data);
};
export const changePassword = (request) => {
    return axios
        .post(CHANGE_PASSWORD_ENDPOINT, request)
        .then((response) => response.data);
};

export const getAccount = () => {
    return axios.get(ACCOUNT_ENDPOINT).then((response) => response.data);
};

export const getCurrentCollaborator = () =>
    axios
        .get(CURRENT_COLLABORATOR_ENDPOINT)
        .then((response) => ({
            ...response.data,
            job: response.data.job.name,
        }));
