import recruitmentNavs from '../src/App/navs/RecruitmentNavs';
import { AUTHORITIES } from './App/utils/constants';
import { t } from './i18n';
import './App/style/pilotage/index.scss';

export default {
    items: [
        {
            name: t('recruitment'),
            external: false,
            url: '/',
            icon: 'fa fa-user-plus',
            roles: [AUTHORITIES.ADMIN, AUTHORITIES.RH, AUTHORITIES.USER],
            sideBarNavs: recruitmentNavs,
            style: {
                navBarClassName: 'novy-navbar',
                navItemClassName: 'novy-header-nav-link-recruitment',
                sideBarClasseName: 'novy-nav-item',
            },
            home: '/dashboard',
            permissions: 'recruitment.manageRecruitment',
        },
        {
            name: t('collaborators'),
            external: true,
            url: process.env.REACT_APP_PUBLIC_BASE_URL,
            icon: 'fa fa-users',
            roles: [AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.RH],
            sideBarNavs: recruitmentNavs,
            style: {
                navBarClassName: 'novy-navbar-collab',
                navItemClassName: 'novy-header-nav-link-collab',
                sideBarClasseName: 'novy-nav-item-collab',
            },
            home: process.env.REACT_APP_PUBLIC_BASE_URL,
            permissions: 'collaborators.manageCollaborators',
        },
        {
            name: t('administration'),
            external: true,
            url: process.env.REACT_APP_SERVER_API_URL_ADMIN,
            icon: 'fa fa-cog',
            roles: [AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.RH],
            sideBarNavs: recruitmentNavs,
            style: {
                navBarClassName: 'novy-navbar-admin',
                navItemClassName: 'novy-header-nav-link-admin',
                sideBarClasseName: 'novy-nav-item-admin',
            },
            home: process.env.REACT_APP_SERVER_API_URL_ADMIN + '/audit',
            permissions: 'administration.manageAdministration',
        },
        {
            name: t('pilotage'),
            external: true,
            url: process.env.REACT_APP_PILOTAGE_WEBAPP_URL,
            icon: 'fas fa-chart-line',
            roles: [AUTHORITIES.ADMIN, AUTHORITIES.RH],
            sideBarNavs: null,
            style: {
                navBarClassName: 'novy-navbar-pilotage',
                navItemClassName: 'novy-header-nav-link-pilotage',
                sideBarClasseName: 'novy-nav-item-pilotage',
            },
            home: process.env.REACT_APP_PILOTAGE_WEBAPP_URL,
            permissions: 'pilotage.managePilotage',
        },
    ],
};
