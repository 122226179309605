import { call, put, takeLatest } from 'redux-saga/effects';

import { COLLABORATORS } from '../../constants';
import {
    FAILURE,
    LIST,
    REQUEST,
    SUCCESS,
} from '../../../../redux/ActionType';
import { getCollaboratorsFromRecruitment } from '../../../services/Collaborators';

export function* list(payload) {
    try {
        const response = yield call(
            getCollaboratorsFromRecruitment,
            payload.params
        );
        yield put({ type: SUCCESS(LIST(COLLABORATORS)), response });
        if (payload.onSuccess) {
            payload.onSuccess(response.list);
        }
    } catch (error) {
        yield put({ type: FAILURE(LIST(COLLABORATORS)), error });
        if (payload.onFailure) {
            payload.onFailure(error.response.data);
        }
    }
}

export function* watchCollaborators() {
    yield takeLatest(REQUEST(LIST(COLLABORATORS)), list);
}
