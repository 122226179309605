import {axios} from '../../../index';
import { DASHBOARD_LATEST_JOB_APPLICATIONS_ENDPOINT, DASHBOARD_LATEST_JOB_OPPORTUNITIES_ENDPOINT, DASHBOARD_NOTIFICATIONS_ENDPOINT } from '../endpoints';

export const getDashboardLatestJobApplications = (currentCollaboratorId) => {
    return axios
        .get(DASHBOARD_LATEST_JOB_APPLICATIONS_ENDPOINT, {params: {currentCollaboratorId}})
        .then(response => response.data);
};

export const getDashboardLatestJobOpportunities = () => {
    return axios
        .get(DASHBOARD_LATEST_JOB_OPPORTUNITIES_ENDPOINT)
        .then(response => response.data);
};

export const getDashboardNotifications = (currentCollaboratorId) => {
    return axios
        .get(DASHBOARD_NOTIFICATIONS_ENDPOINT, {params: {currentCollaboratorId}})
        .then(response => response.data);
};
