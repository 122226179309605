import { axios } from '../../../../index';
import { PER_PAGE } from '../../../../App/utils/constants';
import {
    JAVA_AGENCY_ENDPOINT,
    COLLABORATOR,
    COLLABORATOR_JAVA,
    JAVA_COLLABORATOR_STATUE_ENDPOINT,
    STATUE_PROFESSIONNEL_ENDPOINT,
    JAVA_CONTRACT_TYPE_ENDPOINT,
    JAVA_JOB_ENDPOINT,
    JAVA_SERVICE_ENDPOINT,
    USER_ENDPOINT,
    COLLABORATORS_AS_RESOURCES,
    CODES,
    VALIDATIONS_ENDPOINT,
    JAVA_COLLABORATOR_TASKS_ENDPOINT,
    SEARCH_COLLABORATOR_TASKS_ENDPOINT,
    COLLABORATORS_REDUCED,
    SEARCH_COLLABORATORS,
    JAVA_COLLABORATOR_MY_TASKS_ENDPOINT,
    JAVA_COLLABORATORS__AGENCIES,
} from '../endpoints';

export const showCollaboratorService = () =>
    axios.get(COLLABORATOR).then((response) => response.data);

export const archivedCollaboratorService = () =>
    axios
        .get(COLLABORATORS_REDUCED + '?activated.equals=false')
        .then((response) => response.data);

export const activeCollaboratorService = () =>
    axios
        .get(COLLABORATORS_REDUCED + '?activated.equals=true')
        .then((response) => response.data);

export const listActiveCollaboratorService = (params = {}) =>
    axios
        .get(
            params && params.query
                ? SEARCH_COLLABORATORS
                : COLLABORATORS_REDUCED,
            {
                params: {
                    sort: 'createdAt,desc',
                    size: PER_PAGE,
                    'activated.equals': true,
                    ...params,
                },
            }
        )
        .then((response) => {
            return {
                list: response.data,
                count: parseInt(response.headers['x-total-count']),
            };
        });

export const getCollaborator = (id) => {
    return axios
        .get(`${COLLABORATOR_JAVA}/${id}`)
        .then((response) => response.data);
};

export const getCollaboratorProfile = (id) => {
    return axios
        .get(`${COLLABORATOR_JAVA}/${id}/profile`)
        .then((response) => response.data);
};
export const putCollaboratorReducedProfile = (id, reducedProfil) => {
    return axios
        .put(`${COLLABORATOR_JAVA}/${id}/reducedProfil`, reducedProfil)
        .then((response) => response.data);
};

export const putCollaboratorProfileImage = (id, image) => {
    const frm = new FormData();
    frm.append('image', image);
    return axios
        .post(`${COLLABORATOR_JAVA}/${id}/image`, frm)
        .then((response) => response.data);
};

export const postCollaborator = (collaborator) => {
    return axios
        .post(COLLABORATOR_JAVA, collaborator)
        .then((response) => response.data);
};

export const postUser = (collaborator) => {
    return axios
        .post(USER_ENDPOINT, collaborator)
        .then((response) => response.data);
};

export const putUser = (collaborator) => {
    return axios
        .put(`${USER_ENDPOINT}`, collaborator)
        .then((response) => response.data);
};

/* export const putCollaborator = (id, collaborator) => {
    return axios.put(`${COLLABORATOR}/${id}`, collaborator)
        .then(response => response.data);
}; */

export const putCollaborator = (id, collaborator) => {
    return axios
        .put(`${COLLABORATOR_JAVA}`, collaborator)
        .then((response) => response.data);
};

export const archiveCollaborator = (id) => {
    return axios
        .patch(`${COLLABORATOR_JAVA}/${id}/archive`)
        .then((response) => response.data);
};

export const unarchiveCollaborator = (id) => {
    return axios
        .patch(`${COLLABORATOR_JAVA}/${id}/unarchive`)
        .then((response) => response.data);
};

export const destroyCollaborator = (id) => {
    return axios
        .delete(`${COLLABORATOR_JAVA}/${id}`)
        .then((response) => response.data);
};

export const getJobs = () =>
    axios.get(JAVA_JOB_ENDPOINT).then((response) => response.data);
export const getContractType = () =>
    axios.get(JAVA_CONTRACT_TYPE_ENDPOINT).then((response) => response.data);
export const getService = () =>
    axios.get(JAVA_SERVICE_ENDPOINT).then((response) => response.data);
export const getCollaboratorStatue = () =>
    axios
        .get(JAVA_COLLABORATOR_STATUE_ENDPOINT)
        .then((response) => response.data);
export const getStatueProfessionnel = () =>
    axios.get(STATUE_PROFESSIONNEL_ENDPOINT).then((response) => response.data);

export const getAgencies = () =>
    axios.get(JAVA_AGENCY_ENDPOINT).then((response) => response.data);

export const getCollaboratorsAgencies = () =>
    axios.get(JAVA_COLLABORATORS__AGENCIES).then((response) => response.data);

export const getCollaboratorsAsResources = () =>
    axios.get(COLLABORATORS_AS_RESOURCES).then((response) => response.data);

export const getCodesAndEmails = () =>
    axios.get(CODES).then((response) => response.data);

export const getCollaboratorTasksJava = (params) => {
    const { collaboratorId } = params;
    delete params.collaboratorId;
    return axios
        .get(
            params && params.query
                ? SEARCH_COLLABORATOR_TASKS_ENDPOINT
                : JAVA_COLLABORATOR_MY_TASKS_ENDPOINT(collaboratorId),
            { params }
        )
        .then((response) => ({
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        }));
};

export const destroyValidation = (validationId) => {
    return axios.delete(`${VALIDATIONS_ENDPOINT}/${validationId}`);
};

export const putTaskRaf = (task) => {
    return axios
        .put(`${JAVA_COLLABORATOR_TASKS_ENDPOINT}`, task)
        .then((response) => response.data);
};
