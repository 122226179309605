import { axios } from "../../../index";
import {
  CANDIDATES_COUNT_ENDPOINT,
  CANDIDATES_ENDPOINT,
  PARSER_ENDPOINT,
  SEARCH_CANDIDATES_ENDPOINT,
  PARSER_FR_ENDPOINT
} from "../endpoints";
import { PER_PAGE } from "../../utils/constants";

export const getCandidates = params => {
  return axios
    .get(
      params && params.query ? SEARCH_CANDIDATES_ENDPOINT : CANDIDATES_ENDPOINT,
      {
        params: params || {
          size: PER_PAGE,
          page: 0,
          sort: "id,desc",
          "isArchived.equals": false
        }
      }
    )
    .then(response => ({
      list: response.data,
      count: parseInt(response.headers["x-total-count"])
    }));
};

export const getCandidate = id => {
  return axios
    .get(`${CANDIDATES_ENDPOINT}/${id}`)
    .then(response => ({ candidate: response.data }));
};

export const getCandidatesCount = params => {
  return axios
    .get(CANDIDATES_COUNT_ENDPOINT, { params })
    .then(response => response.data);
};

export const postCandidate = candidate => {
  return axios
    .post(CANDIDATES_ENDPOINT, candidate)
    .then(response => response.data);
};
export const putCandidate = candidate => {
  return axios
    .put(CANDIDATES_ENDPOINT, candidate)
    .then(response => response.data);
};

export const archiveCandidate = id => {
  return axios
    .put(`${CANDIDATES_ENDPOINT}/${id}/archive`)
    .then(response => response.data);
};

export const destroyCandidate = id => {
  return axios
    .delete(`${CANDIDATES_ENDPOINT}/${id}`)
    .then(response => response.data);
};

export const postAttachmentForParser = (attachment, onSuccess, onProgress) => {
  const config = {
    onUploadProgress: onProgress
  };
  return axios.post(PARSER_ENDPOINT, attachment, config).then(response => {
    onSuccess(response.data);
    return { candidate: response.data };
  });
};

export const postAttachmentForParserFR = (
  attachment,
  onSuccess,
  onProgress
) => {
  const config = {
    onUploadProgress: onProgress
  };
  return axios.post(PARSER_FR_ENDPOINT, attachment, config).then(response => {
    onSuccess(response.data);
    return { candidate: response.data };
  });
};
