import {
    CLEAR,
    DELETE,
    FAILURE,
    GET,
    LIST,
    POST,
    PUT,
    REQUEST,
    SUCCESS,
} from '../../../../../redux/ActionType';
import { GATEWAY_AGENCY } from '../../../constants';

const initialState = {
    loading: false,
    list: [],
    agency: {},
    errorMessage: null,
    count: 0,
};

function deleteSuccess(state, id) {
    const list = state.list.filter((e) => e.id !== id);
    state = Object.assign({}, state, { list, count: state.count - 1 });
    return state;
}

const Agencies = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case REQUEST(LIST(GATEWAY_AGENCY)): {
            return { ...state, loading: true };
        }
        case SUCCESS(LIST(GATEWAY_AGENCY)):
            return {
                ...state,
                loading: false,
                list: response.list,
                count: response.count,
            };
        case FAILURE(LIST(GATEWAY_AGENCY)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(GET(GATEWAY_AGENCY)):
            return { ...state, loading: true, agency: {} };
        case SUCCESS(GET(GATEWAY_AGENCY)):
            return { ...state, loading: false, agency: response.agency };
        case FAILURE(GET(GATEWAY_AGENCY)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(PUT(GATEWAY_AGENCY)):
            return { ...state, loading: true };
        case SUCCESS(PUT(GATEWAY_AGENCY)):
            return { ...state, loading: false, agency: {} };
        case FAILURE(PUT(GATEWAY_AGENCY)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(POST(GATEWAY_AGENCY)):
            return { ...state, loading: true };
        case SUCCESS(POST(GATEWAY_AGENCY)):
            return { ...state, loading: false, agency: response };
        case FAILURE(POST(GATEWAY_AGENCY)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(DELETE(GATEWAY_AGENCY)):
            return { ...state, loading: true };
        case SUCCESS(DELETE(GATEWAY_AGENCY)):
            return deleteSuccess(state, response);
        case FAILURE(DELETE(GATEWAY_AGENCY)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case CLEAR(GATEWAY_AGENCY):
            return {
                ...state,
                agency: {},
                errorMessage: null,
                list: [],
                loading: false,
                count: 0,
            };
        default:
            return state;
    }
};

export { Agencies };
