import React, {Component} from 'react';

class NovyFooter extends Component{
    render() {
        return (
            <div className="main d-flex align-items-center justify-content-center">
                <span className='align-self-center'><a href="http://novy.novelis.io/">Novy</a> {this.props.version} &copy; {new Date().getFullYear()} Novelis.</span>
            </div>
        );
    }
}
export default NovyFooter;
