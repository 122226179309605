import React from 'react';
import {NavLink} from 'react-router-dom';
import {Badge, Nav, NavItem, NavLink as RsNavLink, Row} from 'reactstrap';
import classNames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ClickOutHandler from 'react-onclickout';
import {get} from 'lodash';
import 'react-perfect-scrollbar/dist/css/styles.css';
import avatar from "../../assets/img/avatar.png";


const defaultProps = {
    tag: 'nav',
    navConfig: {
        items: [
            {
                name: 'Dashboard',
                url: '/',
                icon: 'icon-speedometer',
                badge: {variant: 'info', text: 'NEW'}
            }]
    },
    isOpen: false
};

const handleClick = (e) => {
    e.preventDefault();
    e.currentTarget.parentElement.classList.toggle('open');
};

const activeRoute = (routeName, location) => {
    if (location)
        return location.pathname.includes(routeName)
            ? 'nav-item nav-dropdown open'
            : 'nav-item nav-dropdown';
    else return 'nav-item nav-dropdown';
};

const hideMobile = () => {
    if (document.body.classList.contains('sidebar-show')) {
        document.body.classList.toggle('sidebar-show');
    }
};

const hasPermission = (permissions, permission) => {
    return get(permissions, "modulePermissions." + permission, false);
};

// nav list
const navList = (items, location, auth, props) => items.map((item, index) => {
    return hasPermission(auth, item.permission) ? navType(item, index, location, props) : false;
});

// nav type
const navType = (item, idx, location, props) => {
    if (props.user)
        return (
            item.title ? navTitle(item, idx)
                : item.divider ? navDivider(item, idx)
                : item.label ? navLabel(item, idx)
                    : item.children ? navDropdown(item, idx, location, props)
                        : navItem(item, idx, props)
        );
};

// nav list section title
const navTitle = (title, key) => {
    const classes = classNames('nav-title', title.class);
    return <li key={key} className={classes}>{navWrapper(title)} </li>;
};

// simple wrapper for nav-title item
const navWrapper = (item) => {
    return item.wrapper && item.wrapper.element ? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name) : item.name;
};

// nav list divider
const navDivider = (divider, key) => {
    const classes = classNames('divider', divider.class);
    return <li key={key} className={classes}/>;
};

// nav label with nav link
const navLabel = (item, key) => {
    const classes = {
        item: classNames('hidden-cn', item.class),
        link: classNames('nav-label', item.class ? item.class : ''),
        icon: classNames(
            'nav-icon',
            !item.icon ? 'fa fa-circle' : item.icon,
            item.label.variant ? `text-${item.label.variant}` : '',
            item.label.class ? item.label.class : '',
        )
    };
    return (
        navLink(item, key, classes)
    );
};

// nav dropdown
const navDropdown = (item, key, location, props) => {

    const classes = {
        item: classNames(item.class),
        link: classNames('nav-link',
            'nav-dropdown-toggle',
            'text-decoration-none',
            'novy-nav-item',
            item.variant ? `nav-link-${item.variant}` : ''),
        icon: classNames('nav-icon', item.icon)
    };
    const url = item.url ? item.url : '';
    const itemIcon = <div className={classNames('d-inline ',{'ml-1 mr-3': props.isOpen})}><i
        className={classNames({'fa-2x ': props.isOpen} + classes.icon)}/></div>;
    const chevronIconRight = <div className={classNames('d-inline ml-3')}><i
        className={classNames({'fa-2x ': props.isOpen} + 'nav-icon fa fa-chevron-right')}/></div>;

    const itemBadge = navBadge(item.badge);
    const attributes = item.attributes || {};
    const navItemClassName = props.activeModule.style.sideBarClasseName;

    return props.isOpen?(
        <li key={key} className={'nav-item nav-dropdown w-100' + activeRoute(item.url, location)}>
            <NavLink to={url} className={classNames(classes.link, 'p-3 d-flex flex-row bd-highlight justify-content-between ripple')}
                     activeClassName={navItemClassName} onClick={handleClick} {...attributes}
            >
                <div>
                    {itemIcon}<span className={classNames('text-left', {
                    'novy_small_side_bar_name_closed': !props.isOpen,
                    'novy_small_side_bar_name_open': props.isOpen
                })}>{item.name}</span>{itemBadge}
                </div>
                <div>
                    {chevronIconRight}
                </div>
            </NavLink>
            <ul className="nav-dropdown-items list-unstyled ml-3">
                {navList(item.children,location,props.auth,props)}
            </ul>
        </li>):<ul className="nav-dropdown-items list-unstyled ml-0">
        {navList(item.children,location,props.auth,props)}
    </ul>;
};

// nav item with nav link
const navItem = (item, key, props) => {

    const classes = {
        item: classNames(item.class),
        link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
        icon: classNames('nav-icon', item.icon)
    };
    return (
        navLink(item, key, classes, props)
    );
};

// nav link
const navLink = (item, key, classes, props) => {
    const url = item.url ? item.url : '';
    const itemIcon = <div className={classNames('d-inline', {'ml-1 mr-3': props.isOpen})}><i
        className={classNames({'fa-2x ': props.isOpen} + classes.icon)}/></div>;
    const itemBadge = navBadge(item.badge);
    const attributes = item.attributes || {};
    const navItemClassName = props.activeModule.style.sideBarClasseName;
    return (
        <NavItem key={key} className={'p-0 ripple ' + classes.item + props.isOpen ? 'w-100' : 'w-25'}>
            {attributes.disabled ?
                <RsNavLink href={''} className={'ripple d-flex flex-row bd-highlight' + classes.link} {...attributes}>
                    {itemIcon}
                    <span className={classNames('text-left', {
                        'novy_small_side_bar_name_closed': !props.isOpen,
                        'novy_small_side_bar_name_open': props.isOpen
                    })}>{item.name}</span>{itemBadge}
                </RsNavLink>
                :
                isExternal(url) ?
                    <RsNavLink href={url} className={classNames('p-3 d-flex flex-row bd-highlight ripple', navItemClassName, classes.link)}
                               {...attributes}>
                        {itemIcon}<span className={classNames('text-left ripple', {
                        'novy_small_side_bar_name_closed': !props.isOpen,
                        'novy_small_side_bar_name_open': props.isOpen
                    })}>{item.name}</span>{itemBadge}
                    </RsNavLink>
                    :
                    <NavLink to={url}
                             className={classNames('p-3 d-flex flex-row bd-highlight ripple', navItemClassName, classes.link)}
                             activeClassName={navItemClassName + ' novy-text-primary font-weight-bold active'} onClick={hideMobile} {...attributes}
                    >
                        {itemIcon}<span className={classNames('text-left', {
                        'novy_small_side_bar_name_closed': !props.isOpen,
                        'novy_small_side_bar_name_open': props.isOpen
                    })}>{item.name}</span>{itemBadge}
                    </NavLink>
            }
        </NavItem>
    );
};

// badge addon to NavItem
const navBadge = (badge) => {
    if (badge) {
        const classes = classNames(badge.class);
        return (
            <Badge className={classes} color={badge.variant}>{badge.text}</Badge>
        );
    }
    return null;
};

const isExternal = (url) => {
    const link = url ? url.substring(0, 4) : '';
    return link === 'http';
};

const NovySidebar = (props) => {
    const {className, children, navConfig, location, user, image, isOpen, auth, ...attributes} = props;
    delete attributes.isOpen;
    delete attributes.staticContext;
    delete attributes.Tag;

    const navClasses = classNames(className, 'w-100');
    const inProfile = location && location.pathname.includes('/user') ? ' focusProfile' : '';

    return (
        <ClickOutHandler onClickOut={(e) => props.onClickOut(e)}>
            <div
                className={classNames('novy-sidebar d-none d-lg-block show border-0', {'novy_small_side_bar': !isOpen})}>
                <PerfectScrollbar className={navClasses} >
                    <div className={'p-1' + inProfile}>
                        <Row className='mx-auto mt-3 mb-1 d-flex justify-content-center'>
                            <a className='text-center' onClick={() => {
                                const module = props.navs.items.find(n => n.name === 'Collaborateurs');
                                props.onActiveModuleChanged(module);
                                props.history.push('/collaborators/user');
                            }}>
                                <img alt="profile"
                                     src={image ? image : avatar}
                                     className={classNames("my-2 mx-0 rounded rounded-circle ",{'profile-image':props.isOpen, 'profile-image-small':!props.isOpen})}
                                />
                            </a>
                        </Row>
                        <Row className={classNames('mx-auto d-flex justify-content-center', {'novy_small_side_bar_name_closed':!props.isOpen})}>
                                <a className='text-center' onClick={() => {
                                    const module = props.navs.items.find(n => n.name === 'Collaborateurs');
                                    props.onActiveModuleChanged(module);
                                    props.history.push('/collaborators/user');
                                }}>
                                    <span className='novy-text-primary profile-name font-weight-bold'>{user.lastName && (user.firstName + ' ' + user.lastName.toUpperCase())}</span>
                                    <br/>
                                    <span className='text-secondary small'>{user.job?user.job:''}</span>

                                    <hr/>
                                </a>

                        </Row>
                    </div>

                    <Nav vertical className={classNames({'novy_small_side_bar': !isOpen})}>
                        {navList(navConfig.items, location, auth, props)}
                    </Nav>
                </PerfectScrollbar>
            </div>

        </ClickOutHandler>
    );
};


NovySidebar.defaultProps = defaultProps;


export default NovySidebar;
