import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as services from '../../../../services/RT/Agencies';

import { GATEWAY_AGENCY } from '../../../constants';
import {
    ARCHIVE,
    DELETE,
    FAILURE,
    GET,
    LIST,
    POST,
    PUT,
    REQUEST,
    SUCCESS,
} from '../../../../../redux/ActionType';
import { stopSubmit } from 'redux-form';
import { get as lodashGet } from 'lodash';
import { t } from '../../../../../i18n';

export function* list(payload) {
    try {
        const response = yield call(services.getAgencies, payload.params);
        yield put({ type: SUCCESS(LIST(GATEWAY_AGENCY)), response });
    } catch (error) {
        yield put({ type: FAILURE(LIST(GATEWAY_AGENCY)), error });
    }
}

export function* get(payload) {
    try {
        const response = yield call(services.getAgency, payload.id);
        yield put({ type: SUCCESS(GET(GATEWAY_AGENCY)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(GATEWAY_AGENCY)), error });
    }
}

export function* post(payload) {
    try {
        const response = yield call(services.postAgency, payload.agency);
        yield put({ type: SUCCESS(POST(GATEWAY_AGENCY)), response });
        if (payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({ type: FAILURE(POST(GATEWAY_AGENCY)), error });
        if (lodashGet(error, 'response.status') === 409)
            yield put(
                stopSubmit('suppliers/referentiel', {
                    code: t('agency_code_already_exists'),
                })
            );
        else if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* update(payload) {
    try {
        const response = yield call(services.putAgency, payload.agency);
        yield put({ type: SUCCESS(PUT(GATEWAY_AGENCY)), response });
        if (payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({ type: FAILURE(PUT(GATEWAY_AGENCY)), error });
        if (lodashGet(error, 'response.status') === 409)
            yield put(
                stopSubmit('suppliers/referentiel', {
                    code: t('agency_code_already_exists'),
                })
            );
        else if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroy(payload) {
    try {
        yield call(services.destroyAgency, payload.id);
        yield put({
            type: SUCCESS(DELETE(GATEWAY_AGENCY)),
            response: payload.id,
        });
        if (payload.onSuccess) payload.onSuccess();
    } catch (error) {
        yield put({ type: FAILURE(DELETE(GATEWAY_AGENCY)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* watchAgencies() {
    yield takeLatest(REQUEST(LIST(GATEWAY_AGENCY)), list);
    yield takeLatest(SUCCESS(PUT(GATEWAY_AGENCY)), list);
    yield takeLatest(SUCCESS(POST(GATEWAY_AGENCY)), list);
    yield takeLatest(SUCCESS(DELETE(GATEWAY_AGENCY)), list);
    yield takeLatest(SUCCESS(ARCHIVE(GATEWAY_AGENCY)), list);
    yield takeLatest(REQUEST(GET(GATEWAY_AGENCY)), get);
    yield takeLatest(REQUEST(POST(GATEWAY_AGENCY)), post);
    yield takeLatest(REQUEST(PUT(GATEWAY_AGENCY)), update);
    yield takeEvery(REQUEST(DELETE(GATEWAY_AGENCY)), destroy);
}
