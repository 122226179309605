import {CLEAR, DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS, MULTIPLE} from '../../../../redux/ActionType';
import {
    ALL_INTERVIEWERS,

    INTERVIEWERS,
} from '../../constants';

const initialState = {
    loading: false,
    list: [],
    interviewer:{},
    count:0,
    errorMessage:null,
};

const Interviewers = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {

        case REQUEST(LIST(INTERVIEWERS)):
            return {...state, loading: true};
        case SUCCESS(LIST(INTERVIEWERS)):
            return {...state, loading: false, list: response.list, count: response.count};
        case FAILURE(LIST(INTERVIEWERS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(LIST(ALL_INTERVIEWERS)):
            return {...state, loading: true};
        case SUCCESS(LIST(ALL_INTERVIEWERS)):
            return {...state, loading: false, list: response.list, count: response.count};
        case FAILURE(LIST(ALL_INTERVIEWERS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(GET(INTERVIEWERS)):
            return {...state, loading: true, interviewer:{}};
        case SUCCESS(GET(INTERVIEWERS)):
            return {...state, loading: false, interviewer: response.interviewer};
        case FAILURE(GET(INTERVIEWERS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(PUT(INTERVIEWERS)):
            return {...state, loading: true};
        case SUCCESS(PUT(INTERVIEWERS)):
            return {...state, loading: false, interviewer: {}};
        case FAILURE(PUT(INTERVIEWERS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(INTERVIEWERS)):
            return {...state, loading: true};
        case SUCCESS(POST(INTERVIEWERS)):
            return {...state, loading: false, interviewer: {}};
        case FAILURE(POST(INTERVIEWERS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(INTERVIEWERS)):
            return {...state, loading: true};
        case SUCCESS(DELETE(INTERVIEWERS)):
            return {...state, loading: false};
        case FAILURE(DELETE(INTERVIEWERS)):
            return {...state, errorMessage: action.error, loading: false};

        case REQUEST(DELETE(MULTIPLE(INTERVIEWERS))):
            return {...state, loading: true};
        case SUCCESS(DELETE(MULTIPLE(INTERVIEWERS))):
            return {...state, loading: false};
        case FAILURE(DELETE(MULTIPLE(INTERVIEWERS))):
            return {...state, errorMessage: action.error, loading: false};

        case 'CLEAR_ERRORS':
            return {...state, errorMessage:null};
        case CLEAR(INTERVIEWERS):
            return {...state, interviewer:initialState.interviewer, errorMessage:null, list:[], loading:false, count:0};
        default:
            return state;
    }
};
export {Interviewers};
