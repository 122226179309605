import { JOB_APPLICATIONS_BY_TECHNOLOGY } from "../../constants";
import { LIST, REQUEST } from "../../../../redux/ActionType";

export const getJobApplicationsByTechnology = (technologyId, params) => {
    return {
        type: REQUEST(LIST(JOB_APPLICATIONS_BY_TECHNOLOGY)),
        payload: {
            technologyId,
            params
        }
    };
};
