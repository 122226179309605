import { axios } from '../../../../index';
import { GATEWAY_AGENCY_ENDPOINT } from '../../endpoints';

export const getAgencies = (params) => {
    return axios
        .get(GATEWAY_AGENCY_ENDPOINT, {
            params: {
                ...(params || { sort: 'id,desc', 'isDeleted.equals': false }),
                size: 1000,
            },
        })
        .then((response) => ({
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        }));
};

export const getAgency = (id) => {
    return axios
        .get(`${GATEWAY_AGENCY_ENDPOINT}/${id}`)
        .then((response) => ({ agency: response.data }));
};

export const postAgency = (agency) => {
    return axios
        .post(GATEWAY_AGENCY_ENDPOINT, agency)
        .then((response) => response.data);
};
export const putAgency = (agency) => {
    return axios
        .put(GATEWAY_AGENCY_ENDPOINT, agency)
        .then((response) => response.data);
};

export const destroyAgency = (id) => {
    return axios
        .delete(`${GATEWAY_AGENCY_ENDPOINT}/${id}`)
        .then((response) => response.data);
};
