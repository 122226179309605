import {ACTIVE_MODULE_CHANGE, PAGE_TITLE} from '../../constants';
import {CLEAR, SET} from '../../ActionType';

export const OnChangeModule = (module)=>{
    return {
        type: ACTIVE_MODULE_CHANGE,
        payload : module
    };
};


export const onSideBarChanged = (open)=>{
    return {
        type: 'SIDEBAR_CHANGED',
        payload : open
    };
};




export const setPageTitle = (title)=>{
    return {
        type: SET(PAGE_TITLE),
        title
    };
};


export const clearPageTitle = ()=>{
    return {
        type: CLEAR(PAGE_TITLE)
    };
};


