import { axios } from '../../../index';
import {
    JOB_OPPORTUNITIES_AS_RESOURCES_ENDPOINT,
    JOB_OPPORTUNITIES_COUNT_ENDPOINT,
    JOB_OPPORTUNITIES_ENDPOINT,
    JOB_OPPORTUNITY_STATUS_ENDPOINT,
    SEARCH_JOB_OPPORTUNITIES_ENDPOINT,
} from '../endpoints';
import { PER_PAGE } from '../../utils/constants';

export const getJobOpportunities = (params) => {
    return axios
        .get(
            params && params.query
                ? SEARCH_JOB_OPPORTUNITIES_ENDPOINT
                : JOB_OPPORTUNITIES_ENDPOINT,
            { params: params || { size: PER_PAGE, page: 0, sort: 'id,desc' } }
        )
        .then((response) => ({
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        }));
};

export const getJobOpportunitiesAsResources = (params) => {
    return axios
        .get(JOB_OPPORTUNITIES_AS_RESOURCES_ENDPOINT, {
            params: params || { size: 1000, page: 0, sort: 'id,desc' },
        })
        .then((response) => ({ listAsResources: response.data }));
};

export const getJobOpportunity = (id) => {
    return axios
        .get(`${JOB_OPPORTUNITIES_ENDPOINT}/${id}`)
        .then((response) => ({ jobOpportunity: response.data }));
};

export const getJobOpportunityStatuses = (params) => {
    return axios
        .get(JOB_OPPORTUNITY_STATUS_ENDPOINT, {
            params: { ...params, size: 1000, sort: 'id,desc' },
        })
        .then((response) => response.data);
};

export const postJobOpportunityStatus = (jobOpportunityStatus) => {
    return axios
        .post(JOB_OPPORTUNITY_STATUS_ENDPOINT, jobOpportunityStatus)
        .then((response) => response.data);
};
export const putJobOpportunityStatus = (jobOpportunityStatus) => {
    return axios
        .put(JOB_OPPORTUNITY_STATUS_ENDPOINT, jobOpportunityStatus)
        .then((response) => response.data);
};
export const destroyJobOpportunityStatus = (id) => {
    return axios
        .delete(`${JOB_OPPORTUNITY_STATUS_ENDPOINT}/${id}`)
        .then((response) => response.data);
};

export const getJobOpportunitiesCount = (params) => {
    return axios
        .get(JOB_OPPORTUNITIES_COUNT_ENDPOINT, { params })
        .then((response) => response.data);
};

export const postJobOpportunity = (jobOpportunity) => {
    return axios
        .post(JOB_OPPORTUNITIES_ENDPOINT, jobOpportunity)
        .then((response) => response.data);
};
export const putJobOpportunity = (jobOpportunity) => {
    return axios
        .put(JOB_OPPORTUNITIES_ENDPOINT, jobOpportunity)
        .then((response) => response.data);
};
export const destroyJobOpportunity = (id) => {
    return axios
        .delete(`${JOB_OPPORTUNITIES_ENDPOINT}/${id}`)
        .then((response) => response.data);
};
