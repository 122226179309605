import React, {Component, Fragment} from 'react';
import {Card, Col,Modal, Nav, Navbar, NavItem, Row} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {get} from 'lodash';

import logo from '../../assets/img/logo.png';
import classNames from 'classnames';
import {t} from '../../../i18n';

class NovyHeader extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            isHeaderOpen:false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    onShow = () => {
        const module = this.props.navs.items.find(n => n.name === 'Collaborateurs');
        this.props.onActiveModuleChanged(module);
        this.props.history.push('/collaborators/user');
    };


    render() {
        const { activeModule, user, navs} = this.props;
        const firstModules =navs.items.filter(e=>e.name !== this.props.activeModule.name).filter(e=>get(user.permissions.modulePermissions,e.permissions)).slice(0,2);
        const navBarClassName = activeModule.style.navBarClassName;
        const navItemClassName = activeModule.style.navItemClassName + ' ';
        const inProfile = this.props.location.pathname.includes('/user') ? ' focusProfile' : '';
        const activeLink = (currentPath, url) => inProfile === '' ? currentPath.includes(url) ? 'active' : '' : '';
        const NovyHeaderNavs = () => {
            return (
                <Modal isOpen={this.state.isHeaderOpen} size='lg' toggle={()=>this.setState({isHeaderOpen:!this.state.isHeaderOpen})}>
                    <div className='d-flex flex-wrap flex-column justify-content-center'>
                        <Row className='my-3'>
                            <Col>
                                <h3 className='text-center'>{t('apps')}</h3>
                                <hr className='mb-3'/>
                            </Col>
                        </Row>
                        <Row className='mx-3 my-1 mb-3'>
                            {navs.items.map((nav, idx) => {
                                return  get(user.permissions.modulePermissions,nav.permissions)?(
                                    <Col xs={12} sm={6} md={4} lg={3} className='my-2' key={idx}>
                                        <Card className={classNames('ripple border module_card my-auto w-100 h-100 align-center text-center')} >
                                            <NavItem
                                                className={'d-flex justify-content-center align-items-center h-100 my-auto list-unstyled ' + navItemClassName + activeLink(this.props.location.pathname, nav.url)}
                                            >
                                                {
                                                    nav.external === true
                                                    ? <a className="d-flex justify-content-center flex-column h-100 w-100" href={nav.url}
                                                    ><i className={classNames('mb-3 mt-2 apps_icons' , nav.style.navBarClassName, nav.icon)} /> <span className={nav.style.navBarClassName}>{nav.name}</span>
                                                    </a>
                                                    : <NavLink onClick={() => {
                                                        this.props.onActiveModuleChanged(nav);
                                                        this.setState({ isOpen: false, isHeaderOpen:false });
                                                    }} className="d-flex justify-content-center flex-column h-100 w-100" to={nav.url}
                                                    ><i className={classNames('mb-3 mt-2 apps_icons' , nav.style.navBarClassName, nav.icon)} /> <span className={nav.style.navBarClassName}>{nav.name}</span>
                                                    </NavLink>
                                                }
                                                
                                            </NavItem>
                                        </Card>
                                    </Col>
                                ):false;
                            })
                            }
                        </Row>
                    </div>

                </Modal>
            );
        };

        const NovyHeaderNavItems = () => {
            return firstModules.map((nav, idx) => {
                return  get(user.permissions.modulePermissions,nav.permissions)?(
                    <NavItem key={idx}
                             className={'p-3 d-inline ' + navItemClassName + activeLink(this.props.location.pathname, nav.url)}
                    >
                        {
                            nav.external === true
                            ? <a className="p-3 novy-color-primary" href={nav.url}
                            ><i className={'mr-2 ' + nav.icon} /> {nav.name}</a>
                            : <NavLink onClick={() => {
                                this.props.onActiveModuleChanged(nav);
                                this.setState({ isOpen: false });
                            }} className="p-3 novy-color-primary" to={nav.url}
                            ><i className={'mr-2 ' + nav.icon} /> {nav.name}</NavLink>
                        }
                    </NavItem>
                ):false;
            });
        };

        return ( !this.props.user.lastName ? <Fragment/> :
                <Navbar className={navBarClassName + ' novy_navbar_background fixed-top'} expand="md">
                    <button onClick={this.props.toggleSideBar}
                            className='border-0 navbar-toggler d-block d-sm-block d-md-none d-lg-none btn-novy-sub_modules'
                    >
                        {this.props.isSideBarOpen?<i className='fa fa-chevron-left text-secondary' /> : <i className='fa fa-chevron-right text-secondary' />}
                    </button>
                    <img alt='Novy' height={45} src={logo}
                         onClick={() => {
                             const module = this.props.navs.items.find(n => n.name === 'Collaborateurs');
                             this.props.onActiveModuleChanged(module);
                             this.props.history.push('/collaborators');
                         }}
                    />
                    <div>
                        <button className='btn btn-novy-modules d-inline-block d-md-none'
                                onClick={() => this.setState({isHeaderOpen: true})}>
                            <i className='fa fa-th-large'/></button>

                        <button className='btn btn-novy-modules d-inline-block d-md-none' onClick={() => {
                            const module = this.props.navs.items.find(n => n.name === 'Collaborateurs');
                            this.props.onActiveModuleChanged(module);
                            window.open(`${process.env.REACT_APP_PUBLIC_BASE_URL}/collaborators/CollaboratorDetails`, '_blank')
                        }}>
                            <i className='fa fa-cog'/>
                        </button>

                        <button className='btn btn-novy-modules d-inline-block d-md-none' onClick={this.props.onLogout}><i
                            className='fa fa-power-off'/></button>

                    </div>
                    <button onClick={this.props.toggleSideBar}
                            className='border-0 navbar-toggler d-none d-sm-none d-md-block d-lg-block btn-novy-sub_modules'
                    >
                        {this.props.isSideBarOpen?<i className='fa fa-chevron-left text-secondary' /> : <i className='fa fa-chevron-right text-secondary' />}
                    </button>


                    <Nav className="ml-auto d-none d-md-block" navbar>
                        <NovyHeaderNavItems/>
                        {
                            firstModules.length <= navs.items.length && <button className='btn btn-novy-modules'
                                                                                onClick={() => this.setState({isHeaderOpen: true})}>
                                <i className='fa fa-th-large'/></button>
                        }


                        <button className='btn btn-novy-modules' onClick={() => {
                            const module = this.props.navs.items.find(n => n.name === 'Collaborateurs');
                            this.props.onActiveModuleChanged(module);
                            window.open(`${process.env.REACT_APP_PUBLIC_BASE_URL}/collaborators/CollaboratorDetails`, '_blank')
                        }}>
                            <i className='fa fa-cog'/>
                        </button>

                        <button className='btn btn-novy-modules' onClick={this.props.onLogout}><i
                            className='fa fa-power-off'/></button>
                    </Nav>
                    {this.state.isHeaderOpen && <NovyHeaderNavs/>}

                </Navbar>
        );
    }
}


export default NovyHeader;
