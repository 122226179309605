import { combineReducers } from 'redux';
import {isKeycloakDisabled} from '../../../keycloak/config';
import Auth from './auth';
import { reducer as formReducer } from 'redux-form';
import layout from './layout';
import preferences from './preferences';
import dataTables from './dataTables';
import recruitment from '../../redux/reducers/recrutement'
import collaborator from '../../../modules/Collaborators/redux/reducers';
export default (reducers) => {
    const defaultReducers = {
        Auth,
        form: formReducer,
        layout,
        preferences,
        recruitment,
        dataTables,
        collaborator,
    };
    if(!isKeycloakDisabled)
        defaultReducers.keycloak = (keycloak = {}) => keycloak;

    return combineReducers({
        ...defaultReducers,
        ...reducers
    });
}
