import {axios} from '../../../index';
import {
    INTERVIEW_LOCATIONS_ENDPOINT,
    INTERVIEW_MEDIUMS_ENDPOINT,
    INTERVIEW_TYPES_ENDPOINT,
    INTERVIEWERS_ENDPOINT,
    INTERVIEWS_COUNT_ENDPOINT,
    INTERVIEWS_ENDPOINT,
    SEARCH_INTERVIEWS_ENDPOINT
} from '../endpoints';
import {PER_PAGE} from '../../utils/constants';

export const getInterviews = (params) => {
    return axios.get(params &&params.query ? SEARCH_INTERVIEWS_ENDPOINT : INTERVIEWS_ENDPOINT, {params: params||{size: PER_PAGE, page: 0, sort:'id,desc'}})
        .then(response => ({list: response.data, count: parseInt(response.headers['x-total-count'])}));
};

export const getInterviewers = (params) => {
    return axios.get(INTERVIEWERS_ENDPOINT, {params})
        .then(response => response.data);
};

export const getInterviewTypes = (params) => {
    return axios.get(INTERVIEW_TYPES_ENDPOINT, {params:{...params, size: 1000, sort:'id,desc'}})
        .then(response => response.data);
};
export const postInterviewType = (interviewType) => {
    return axios.post(INTERVIEW_TYPES_ENDPOINT, interviewType)
        .then(response => response.data);

};
export const putInterviewType = (interviewType) => {
    return axios.put(INTERVIEW_TYPES_ENDPOINT, interviewType)
        .then(response => response.data);
};
export const destroyInterviewType = (id) => {
    return axios.delete(`${INTERVIEW_TYPES_ENDPOINT}/${id}`)
        .then(response => response.data);
};


export const getInterviewLocations = (params) => {
    return axios.get(INTERVIEW_LOCATIONS_ENDPOINT, {params:{...params, size: 1000, sort:'id,desc'}})
        .then(response => response.data);
};
export const postInterviewLocation = (interviewLocation) => {
    return axios.post(INTERVIEW_LOCATIONS_ENDPOINT, interviewLocation)
        .then(response => response.data);

};
export const putInterviewLocation = (interviewLocation) => {
    return axios.put(INTERVIEW_LOCATIONS_ENDPOINT, interviewLocation)
        .then(response => response.data);
};
export const destroyInterviewLocation = (id) => {
    return axios.delete(`${INTERVIEW_LOCATIONS_ENDPOINT}/${id}`)
        .then(response => response.data);
};


export const getInterviewMediums = (params) => {
    return axios.get(INTERVIEW_MEDIUMS_ENDPOINT, {params:{...params, size: 1000}})
        .then(response => response.data.reverse());
};
export const postInterviewMedium = (interviewMedium) => {
    return axios.post(INTERVIEW_MEDIUMS_ENDPOINT, interviewMedium)
        .then(response => response.data);

};
export const putInterviewMedium = (interviewMedium) => {
    return axios.put(INTERVIEW_MEDIUMS_ENDPOINT, interviewMedium)
        .then(response => response.data);
};
export const destroyInterviewMedium = (id) => {
    return axios.delete(`${INTERVIEW_MEDIUMS_ENDPOINT}/${id}`)
        .then(response => response.data);
};


export const getInterview = (id) => {
    return axios.get(`${INTERVIEWS_ENDPOINT}/${id}`)
        .then(response => ({interview: response.data}));
};

export const getInterviewsCount = (params) => {
    return axios.get(INTERVIEWS_COUNT_ENDPOINT, {params})
        .then(response => response.data);

};

export const postInterview = (interview) => {
    return axios.post(INTERVIEWS_ENDPOINT, interview)
        .then(response => response.data);

};
export const putInterview = (interview) => {
    return axios.put(INTERVIEWS_ENDPOINT, interview)
        .then(response => response.data);

};
export const destroyInterview = (id) => {
    return axios.delete(`${INTERVIEWS_ENDPOINT}/${id}`)
        .then(response => response.data);
};
