export const NOVELIS_EMAIL_ENDING = '@novelis.io';
export const NOVY_REC_MME = 'MME';
export const NOVY_COLLAB_MME = 'Mme';

export const SHAREPOINT_RH_LINK = 'https://novelisconsulting.sharepoint.com/:f:/r/Documents%20partages/RH%20ET%20RECRUTEMENT/Documents/';
export const SHAREPOINT_INDEPENDENTS_FOLDER_NAME = 'Independants';
export const SHAREPOINT_PERSONAL_FOLDER_NAME = 'Dossier du Personnel';

export const PER_PAGE = 12;
export const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
export const AUTHORITIES = {
    ADMIN: 'ROLE_ADMIN',
    USER: 'ROLE_USER',
    RH: 'ROLE_RH'
};