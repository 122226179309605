import {call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import * as services from '../../../services/Interviews';

import {INTERVIEW_LOCATIONS, INTERVIEW_MEDIUMS, INTERVIEW_TYPES, INTERVIEWS, INTERVIEWS_COUNT} from '../../constants';
import {DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS} from '../../../../redux/ActionType';


export function* list(payload) {
    yield delay(300);

    try {
        const params = payload.params ? payload.params : yield select(state => state.dataTables.dataTableState.interviews.params);
        const response = yield call(services.getInterviews, params);
        yield put({ type: SUCCESS(LIST(INTERVIEWS)), response });
    } catch(error) {
        yield put({ type: FAILURE(LIST(INTERVIEWS)), error });
    }
}

export function* listInterviewTypes(payload) {
    try {
        const response = yield call(services.getInterviewTypes, payload.params);
        yield put({ type: SUCCESS(LIST(INTERVIEW_TYPES)), response });
    } catch(error) {
        yield put({ type: FAILURE(LIST(INTERVIEW_TYPES)), error });
    }
}
export function* postInterviewType(payload) {
    try {
        const response = yield call(services.postInterviewType, payload.interviewType);
        yield put({ type: SUCCESS(POST(INTERVIEW_TYPES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(POST(INTERVIEW_TYPES)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* updateInterviewType(payload) {
    try {
        const response = yield call(services.putInterviewType, payload.interviewType);
        yield put({ type: SUCCESS(PUT(INTERVIEW_TYPES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(PUT(INTERVIEW_TYPES)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroyInterviewType(payload) {
    try {
        yield call(services.destroyInterviewType, payload.id);
        yield put({ type: SUCCESS(DELETE(INTERVIEW_TYPES)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(INTERVIEW_TYPES)), error });
        if(payload.onFailure)payload.onFailure(error.response);

    }
}



export function* listInterviewLocations(payload) {
    let location = yield select((state) => state.recruitment.Interviews.Locations.list);
    try {
        if(!location.length){const response = yield call(services.getInterviewLocations, payload.params);
            yield put({ type: SUCCESS(LIST(INTERVIEW_LOCATIONS)), response });}
    } catch(error) {
        yield put({ type: FAILURE(LIST(INTERVIEW_LOCATIONS)), error });
    }
}
export function* postInterviewLocation(payload) {
    try {
        const response = yield call(services.postInterviewLocation, payload.interviewLocation);
        yield put({ type: SUCCESS(POST(INTERVIEW_LOCATIONS)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(POST(INTERVIEW_LOCATIONS)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* updateInterviewLocation(payload) {
    try {
        const response = yield call(services.putInterviewLocation, payload.interviewLocation);
        yield put({ type: SUCCESS(PUT(INTERVIEW_LOCATIONS)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(PUT(INTERVIEW_LOCATIONS)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroyInterviewLocation(payload) {
    try {
        yield call(services.destroyInterviewLocation, payload.id);
        yield put({ type: SUCCESS(DELETE(INTERVIEW_LOCATIONS)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(INTERVIEW_LOCATIONS)), error });
        if(payload.onFailure)payload.onFailure(error.response);

    }
}

export function* listInterviewMediums(payload) {
    let medium = yield select((state) => state.recruitment.Interviews.Mediums.list);
    try {
        if(!medium.length){
            const response = yield call(services.getInterviewMediums, payload.params);
            yield put({ type: SUCCESS(LIST(INTERVIEW_MEDIUMS)), response });
        }
    } catch(error) {
        yield put({ type: FAILURE(LIST(INTERVIEW_MEDIUMS)), error });
    }
}
export function* postInterviewMedium(payload) {
    try {
        const response = yield call(services.postInterviewMedium, payload.interviewMedium);
        yield put({ type: SUCCESS(POST(INTERVIEW_MEDIUMS)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(POST(INTERVIEW_MEDIUMS)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* updateInterviewMedium(payload) {
    try {
        const response = yield call(services.putInterviewMedium, payload.interviewMedium);
        yield put({ type: SUCCESS(PUT(INTERVIEW_MEDIUMS)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(PUT(INTERVIEW_MEDIUMS)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroyInterviewMedium(payload) {
    try {
        yield call(services.destroyInterviewMedium, payload.id);
        yield put({ type: SUCCESS(DELETE(INTERVIEW_MEDIUMS)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(INTERVIEW_MEDIUMS)), error });
        if(payload.onFailure)payload.onFailure(error.response);
    }
}


export function* get(payload) {
    try {
        const response = yield call(services.getInterview, payload.id);
        yield put({ type: SUCCESS(GET(INTERVIEWS)), response });
    } catch(error) {
        yield put({ type: FAILURE(GET(INTERVIEWS)), error });
    }
}

export function* count(payload) {
    try {
        const response = yield call(services.getInterviewsCount, payload.params);
        yield put({ type: SUCCESS(GET(INTERVIEWS_COUNT)), response });
    } catch(error) {
        yield put({ type: FAILURE(GET(INTERVIEWS_COUNT)), error });
    }
}

export function* post(payload) {
    try {
        const response = yield call(services.postInterview, payload.interview);
        yield put({ type: SUCCESS(POST(INTERVIEWS)), response });
        payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(POST(INTERVIEWS)), error });
        payload.onFailure(error);
    }
}

export function* update(payload) {
    try {
        const response = yield call(services.putInterview, payload.interview);
        yield put({ type: SUCCESS(PUT(INTERVIEWS)), response });
        payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(PUT(INTERVIEWS)), error });
        payload.onFailure(error);

    }
}

export function* destroy(payload) {
    try {

        yield call(services.destroyInterview, payload.id);
        yield put({ type: SUCCESS(DELETE(INTERVIEWS)), response:payload.id });
        if (payload.onSuccess)payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(DELETE(INTERVIEWS)), error });
    }
}

export function* watchInterviews() {
    yield takeLatest(REQUEST(LIST(INTERVIEWS)), list);
    yield takeLatest(REQUEST(LIST(INTERVIEW_TYPES)), listInterviewTypes);
    yield takeLatest(REQUEST(POST(INTERVIEW_TYPES)), postInterviewType);
    yield takeLatest(REQUEST(PUT(INTERVIEW_TYPES)), updateInterviewType);
    yield takeLatest(REQUEST(DELETE(INTERVIEW_TYPES)), destroyInterviewType);
    yield takeLatest(REQUEST(LIST(INTERVIEW_LOCATIONS)), listInterviewLocations);
    yield takeLatest(REQUEST(POST(INTERVIEW_LOCATIONS)), postInterviewLocation);
    yield takeLatest(REQUEST(PUT(INTERVIEW_LOCATIONS)), updateInterviewLocation);
    yield takeLatest(REQUEST(DELETE(INTERVIEW_LOCATIONS)), destroyInterviewLocation);
    yield takeLatest(REQUEST(LIST(INTERVIEW_MEDIUMS)), listInterviewMediums);
    yield takeLatest(REQUEST(POST(INTERVIEW_MEDIUMS)), postInterviewMedium);
    yield takeLatest(REQUEST(PUT(INTERVIEW_MEDIUMS)), updateInterviewMedium);
    yield takeLatest(REQUEST(DELETE(INTERVIEW_MEDIUMS)), destroyInterviewMedium);
    yield takeLatest(REQUEST(GET(INTERVIEWS)), get);
    yield takeLatest(REQUEST(GET(INTERVIEWS_COUNT)), count);
    yield takeLatest(REQUEST(POST(INTERVIEWS)), post);
    yield takeLatest(REQUEST(PUT(INTERVIEWS)), update);
    yield takeEvery(REQUEST(DELETE(INTERVIEWS)), destroy);

}
