import {axios} from '../../../index';
import {
    EMAIL_MODEL_ENDPOINT,
    EMAIL_NOTIFICATIONS_ENDPOINT, MAIL_HISTORIES_ENDPOINT,
    NOTIFICATIONS_COUNT_ENDPOINT,
    NOTIFICATIONS_ENDPOINT, SEARCH_EMAIL_MODEL_ENDPOINT, SEARCH_MAIL_HISTORIES_ENDPOINT,
    SEARCH_NOTIFICATIONS_ENDPOINT
} from '../endpoints';

export const getNotifications = (params) => {
    return axios.get(params &&params.query ? SEARCH_NOTIFICATIONS_ENDPOINT : NOTIFICATIONS_ENDPOINT, {params})
        .then(response => ({list: response.data, count: parseInt(response.headers['x-total-count'])}));
};

export const getNotification = (id) => {
    return axios.get(`${NOTIFICATIONS_ENDPOINT}/${id}`)
        .then(response => ({notification: response.data}));
};

export const getNotificationsCount = (params) => {
    return axios.get(NOTIFICATIONS_COUNT_ENDPOINT, {params})
        .then(response => response.data);

};

export const postNotification = (notification) => {
    return axios.post(NOTIFICATIONS_ENDPOINT, notification)
        .then(response => response.data);

};

export const postEmailNotification = (email) => {
    return axios.post(EMAIL_NOTIFICATIONS_ENDPOINT, email)
        .then(response => response.data);

};
export const putNotification = (notification) => {
    return axios.put(NOTIFICATIONS_ENDPOINT, notification)
        .then(response => response.data);

};
export const destroyNotification = (id) => {
    return axios.delete(`${NOTIFICATIONS_ENDPOINT}/${id}`)
        .then(response => response.data);
};

export const getEmailModels = (params) => {
    return axios.get(params &&params.query ? SEARCH_EMAIL_MODEL_ENDPOINT : EMAIL_MODEL_ENDPOINT, {params})
        .then(response => response.data);
};

export const getSentMailHistories = (params) => {
    return axios.get(params &&params.query ? SEARCH_MAIL_HISTORIES_ENDPOINT : MAIL_HISTORIES_ENDPOINT, {params})
        .then(response => response.data);
};

