import {CLEAR, DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS} from '../../../../redux/ActionType';
import {
    EMAIL_NOTIFICATIONS,
    NOTIFICATIONS,
    NOTIFICATIONS_COUNT,
    EMAIL_MODELS,
    SENT_MAIL_HISTORY
} from '../../constants';

const initialState = {
    loading: false,
    list: [],
    candidate:{},
    Email:{},
    errorMessage:null,
    count:0,
    emailModels : [],
    sentMailsHistories: []
};

function deleteSuccess(state, id) {
    const list=state.list.filter(e=>e.id!==id);
    state = Object.assign({},state,{list,count:state.count-1});
    return state;
}

const Notifications = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {

        case REQUEST(LIST(NOTIFICATIONS)):
            return {...state, loading: true};
        case SUCCESS(LIST(NOTIFICATIONS)):
            return {...state, loading: false, list: response.list, count: response.count};
        case FAILURE(LIST(NOTIFICATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(GET(NOTIFICATIONS)):
            return {...state, loading: true, candidate:{}};
        case SUCCESS(GET(NOTIFICATIONS)):
            return {...state, loading: false, candidate: response.candidate};
        case FAILURE(GET(NOTIFICATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(GET(NOTIFICATIONS_COUNT)):
            return {...state, loading: true};
        case SUCCESS(GET(NOTIFICATIONS_COUNT)):
            return {...state, loading: false, Count: response};
        case FAILURE(GET(NOTIFICATIONS_COUNT)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(PUT(NOTIFICATIONS)):
            return {...state, loading: true};
        case SUCCESS(PUT(NOTIFICATIONS)):
            return {...state, loading: false, candidate: {}};
        case FAILURE(PUT(NOTIFICATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(NOTIFICATIONS)):
            return {...state, loading: true};
        case SUCCESS(POST(NOTIFICATIONS)):
            return {...state, loading: false, candidate: response};
        case FAILURE(POST(NOTIFICATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(EMAIL_NOTIFICATIONS)):
            return {...state, loading: true};
        case SUCCESS(POST(EMAIL_NOTIFICATIONS)):
            return {...state, loading: false, Email: response};
        case FAILURE(POST(EMAIL_NOTIFICATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(NOTIFICATIONS)):
            return {...state, loading: true};
        case SUCCESS(DELETE(NOTIFICATIONS)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(NOTIFICATIONS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(NOTIFICATIONS):
            return {...state, candidate:{}, errorMessage:null, list:[], loading:false, count:0};

        case REQUEST(LIST(EMAIL_MODELS)):
            return {...state, loading: true};
        case SUCCESS(LIST(EMAIL_MODELS)):
            return {...state, loading: false, emailModels: response};
        case FAILURE(LIST(EMAIL_MODELS)):
            return {...state, errorMessage: action.error.message, loading: false};
        case REQUEST(LIST(SENT_MAIL_HISTORY)):
            return {...state, loading: true};
        case SUCCESS(LIST(SENT_MAIL_HISTORY)):
            return {...state, loading: false, sentMailsHistories: response};
        case FAILURE(LIST(SENT_MAIL_HISTORY)):
            return {...state, errorMessage: action.error.message, loading: false};

        default:
            return state;
    }
};
export {Notifications};
