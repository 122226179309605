export const REQUEST_PASSWORD = 'REQUEST_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const EMAIL_NOTIFICATIONS = 'EMAIL_NOTIFICATIONS';
export const NOTIFICATIONS_COUNT = 'NOTIFICATIONS_COUNT';
export const LOGIN_USER = 'LOGIN_USER';
export const GET_USER = 'GET_USER';
export const GET_CURRENT_COLLABORATOR = 'GET_CURRENT_COLLABORATOR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const ACTIVE_MODULE_CHANGE = 'ACTIVE_MODULE_CHANGE';

export const PRERERENCES = 'PRERERENCES';
export const EMAIL_MODELS = 'EMAIL_MODELS';
export const SENT_MAIL_HISTORY = 'SENT_MAIL_HISTORY';

export const DATATABLES_STATE = 'DATATABLES_STATE';
export const RESET_DATATABLES_STATE = 'RESET_DATATABLES_STATE';

export const PAGE_TITLE = 'PAGE_TITLE';

export const CANDIDATES = 'CANDIDATES';
export const CANDIDATES_COUNT = 'CANDIDATES_COUNT';

export const DISPONIBILITY = 'DISPONIBILITY';

export const JOB_APPLICATIONS = 'JOB_APPLICATIONS';
export const NEW_JOB_APPLICATIONS_ATTACHMENTS = 'NEW_JOB_APPLICATIONS_ATTACHMENTS';
export const JOB_APPLICATION_STATUS = 'JOB_APPLICATION_STATUS';
export const JOB_APPLICATION_SOURCE = 'JOB_APPLICATION_SOURCE';
export const JOB_APPLICATION_TIMELINE = 'JOB_APPLICATION_TIMELINE';
export const JOB_APPLICATIONS_COUNT = 'JOB_APPLICATIONS_COUNT';
export const JOB_APPLICATION_COMMENTS = 'JOB_APPLICATION_COMMENTS';
export const JOB_APPLICATION_ASSIGNMENTS = 'JOB_APPLICATION_ASSIGNMENTS';
export const ALL_JOB_APPLICATION_STATUS = 'ALL_JOB_APPLICATION_STATUS';

export const JOB_OPPORTUNITIES = 'JOB_OPPORTUNITIES';
export const JOB_OPPORTUNITIES_COUNT = 'JOB_OPPORTUNITIES_COUNT';
export const JOB_OPPORTUNITY_STATUS = 'JOB_OPPORTUNITY_STATUS';

export const INTERVIEWS = 'INTERVIEWS';
export const INTERVIEWERS = 'INTERVIEWERS';
export const ALL_INTERVIEWERS = 'ALL_INTERVIEWERS';
export const INTERVIEWS_COUNT = 'INTERVIEWS_COUNT';
export const INTERVIEW_TYPES = 'INTERVIEW_TYPES';
export const INTERVIEW_LOCATIONS = 'INTERVIEW_LOCATIONS';
export const INTERVIEW_MEDIUMS = 'INTERVIEW_MEDIUMS';

export const CITIES = 'CITIES';
export const COUNTRIES = 'COUNTRIES';
export const TECHNOLOGIES = 'TECHNOLOGIES';
export const AGENCIES = 'AGENCIES';

export const ATTACHMENT = 'ATTACHMENT';

export const COLLABORATORS = 'COLLABORATORS';

export const PARSER_ATTACHMENT = 'PARSER_ATTACHMENT';

export const PARSER_ATTACHMENT_FR = 'PARSER_ATTACHMENT_FR';

export const JOB_OPPORTUNITIES_AS_RESOURCES = 'JOB_OPPORTUNITIES_AS_RESOURCES';

export const REQUEST_UPDATE_JOB_APPLICATION = 'REQUEST_UPDATE_JOB_APPLICATION';

export const AUTOHIRING_CAMPAIGNS = 'AUTOHIRING_CAMPAIGNS';

export const AUTOHIRING_INVITATIONS = 'AUTOHIRING_INVITATIONS';

export const AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS = 'AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS';



/*
Dashboard
*/
export const DASHBOARD_LATEST_JOB_APPLICATIONS = 'DASHBOARD_LATEST_JOB_APPLICATIONS';
export const DASHBOARD_LATEST_JOB_OPPORTUNITIES = 'DASHBOARD_LATEST_JOB_OPPORTUNITIES';
export const DASHBOARD_NOTIFICATIONS = 'DASHBOARD_NOTIFICATIONS';


/*
My Job Applications
*/
export const MY_JOB_APPLICATIONS = 'MY_JOB_APPLICATIONS';


/*
Archived Applications
*/
export const ARCHIVED_JOB_APPLICATIONS = 'ARCHIVED_JOB_APPLICATIONS';

/*
Favorite Job Applications
*/
export const JOB_APPLICATIONS_BY_TECHNOLOGY = 'JOB_APPLICATIONS_BY_TECHNOLOGY';

/*
New Applications
*/
export const NEW_JOB_APPLICATIONS = 'NEW_JOB_APPLICATIONS';

export const AUDIT_ACTION = 'AUDIT_ACTION';
export const PERMISSIONS_TEMPLATE = 'PERMISSIONS_TEMPLATE';
export const GATEWAY_AGENCY = 'GATEWAY_AGENCY';
export const IMPUTATION_REMINDER_EXCLUSIONS = 'IMPUTATION_REMINDER_EXCLUSIONS';
export const IMPUTATION_REMINDER_INCLUSIONS = 'IMPUTATION_REMINDER_INCLUSIONS';
