const RECRUITMENT_API = 'recruitment/api';

export const CANDIDATES_ENDPOINT = `${RECRUITMENT_API}/candidates`;
export const CANDIDATES_COUNT_ENDPOINT = `${RECRUITMENT_API}/candidates/count`;
export const SEARCH_CANDIDATES_ENDPOINT = `${RECRUITMENT_API}/_search/candidates`;

export const DISPONIBILITY_ENDPOINT = `${RECRUITMENT_API}/disponibilities`;

export const NOTIFICATIONS_ENDPOINT = `${RECRUITMENT_API}/notifications`;
export const NOTIFICATIONS_COUNT_ENDPOINT =
  `${RECRUITMENT_API}/notifications/count`;
export const EMAIL_NOTIFICATIONS_ENDPOINT =
  `${RECRUITMENT_API}/email-notifications`;
export const SEARCH_NOTIFICATIONS_ENDPOINT =
  `${RECRUITMENT_API}/_search/notifications`;

export const INTERVIEWS_ENDPOINT = `${RECRUITMENT_API}/interviews`;
export const INTERVIEWERS_ENDPOINT = `${RECRUITMENT_API}/interviewers`;
export const INTERVIEWS_COUNT_ENDPOINT = `${RECRUITMENT_API}/interviews/count`;
export const SEARCH_INTERVIEWS_ENDPOINT = `${RECRUITMENT_API}/_search/interviews`;
export const SEARCH_INTERVIEWERS_ENDPOINT =
  `${RECRUITMENT_API}/_search/interviewers`;
export const INTERVIEW_TYPES_ENDPOINT = `${RECRUITMENT_API}/interview-types`;
export const INTERVIEW_LOCATIONS_ENDPOINT =
  `${RECRUITMENT_API}/interview-locations`;
export const INTERVIEW_MEDIUMS_ENDPOINT = `${RECRUITMENT_API}/interview-mediums`;

export const JOB_APPLICATIONS_ENDPOINT = `${RECRUITMENT_API}/job-applications`;

export const NEW_JOB_APPLICATIONS_ATTACHMENTS_ENDPOINT = `${RECRUITMENT_API}/job-applications/new-application-attachments`;

export const SEARCH_JOB_APPLICATIONS_ENDPOINT =
  `${RECRUITMENT_API}/_search/job-applications`;
export const JOB_OPPORTUNITIES_ENDPOINT = `${RECRUITMENT_API}/job-opportunities`;
export const JOB_OPPORTUNITIES_COUNT_ENDPOINT =
  `${RECRUITMENT_API}/job-opportunities/count`;
export const JOB_OPPORTUNITY_STATUS_ENDPOINT =
  `${RECRUITMENT_API}/job-opportunity-statuses`;

export const SEARCH_JOB_OPPORTUNITIES_ENDPOINT =
  `${RECRUITMENT_API}/_search/job-opportunities`;
export const JOB_APPLICATIONS_STATUS_ENDPOINT =
  `${RECRUITMENT_API}/job-application-statuses`;
export const JOB_APPLICATIONS_SOURCE_ENDPOINT =
  `${RECRUITMENT_API}/job-application-sources`;
export const JOB_APPLICATIONS_TIMELINE_ENDPOINT =
  `${RECRUITMENT_API}/job-application-timelines`;
export const JOB_APPLICATIONS_COUNT_ENDPOINT =
  `${RECRUITMENT_API}/job-applications/count`;
export const JOB_APPLICATIONS_COMMENTS_ENDPOINT = `${RECRUITMENT_API}/comments`;
export const JOB_APPLICATIONS_ASSIGNMENTS_ENDPOINT = `${RECRUITMENT_API}/jobApplicationAssignments`;
export const ALL_JOB_APPLICATIONS_STATUS_ENDPOINT =
  `${RECRUITMENT_API}/job-application-statuses/all`;

export const ATTACHMENTS_ENDPOINT = `${RECRUITMENT_API}/attachments`;

export const SEARCH_EMAIL_MODEL_ENDPOINT =
  `${RECRUITMENT_API}/_search/email-models`;
export const EMAIL_MODEL_ENDPOINT = `${RECRUITMENT_API}/email-models`;

export const SEARCH_MAIL_HISTORIES_ENDPOINT =
  `${RECRUITMENT_API}/_search/sent-mail-histories`;
export const MAIL_HISTORIES_ENDPOINT = `${RECRUITMENT_API}/sent-mail-histories`;

export const COLLABORATORS_ENDPOINT = `${RECRUITMENT_API}/collaborators`;

export const PARSER_ENDPOINT = `${RECRUITMENT_API}/resume/parse`;

export const PARSER_FR_ENDPOINT = `${RECRUITMENT_API}/resume/parse_cv_fr`;

export const JOB_OPPORTUNITIES_AS_RESOURCES_ENDPOINT =
  `${RECRUITMENT_API}/job-opportunities-list`;

export const REQUEST_UPDATE_JOB_APPLICATIONS_ENDPOINT =
  `${RECRUITMENT_API}/job-application-request-update`;

export const AUTOHIRING_CANDIDATE_INVITATIONS_ENDPOINT =
  `${RECRUITMENT_API}/candidateInvitations`;

export const AUTOHIRING_CAMPAIGN_ENDPOINT =
  `${RECRUITMENT_API}/campaigns`;

export const AUTOHIRING_CANDIDATE_CAMPAIGN_RESULT_ENDPOINT =
  `${RECRUITMENT_API}/campaignResultsByCandidate`;

/*
* Dashboard
*/
export const DASHBOARD_LATEST_JOB_APPLICATIONS_ENDPOINT = `${RECRUITMENT_API}/job-applications/latest`;
export const DASHBOARD_LATEST_JOB_OPPORTUNITIES_ENDPOINT = `${RECRUITMENT_API}/job-opportunities/latest`;
export const DASHBOARD_NOTIFICATIONS_ENDPOINT = `${RECRUITMENT_API}/notifications/dashboard`;

/*
* My Job Applications
*/
export const MY_JOB_APPLICATIONS_ENDPOINT = `${RECRUITMENT_API}/job-applications/my-job-applications/collaborator/`;
export const SEARCH_MY_JOB_APPLICATIONS_ENDPOINT = `${RECRUITMENT_API}/_search/job-applications/my-job-applications/collaborator/`;

/*
* Archived Job Applications
*/
export const ARCHIVED_JOB_APPLICATIONS_ENDPOINT = `${RECRUITMENT_API}/job-applications/archived`;
export const SEARCH_ARCHIVED_JOB_APPLICATIONS_ENDPOINT = `${RECRUITMENT_API}/_search/job-applications/archived`;

/*
* Archived Job Applications
*/
export const NEW_JOB_APPLICATIONS_ENDPOINT = `${RECRUITMENT_API}/job-applications/new`;
export const SEARCH_NEW_JOB_APPLICATIONS_ENDPOINT = `${RECRUITMENT_API}/_search/job-applications/new`;
export const GATEWAY_AGENCY_ENDPOINT = 'api/agencies';

export const AUDIT_ENDPOINT = 'api/audits';
export const PERMISSIONS_ENDPOINT = 'api/permissions-templates';
export const SEARCH_PERMISSIONS_ENDPOINT = 'api/_search/permissions-templates';
export const IMPUTATION_REMINDER_EXCLUSIONS_ENDPOINT = '/collaborator/api/imputation_reminder_exclusions';
export const IMPUTATION_REMINDER_INCLUSIONS_ENDPOINT = '/collaborator/api/imputation_reminder_inclusions';