import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as services from '../../../services/Dashboard';
import { getMyJobApplications } from '../../../services/JobApplications';

import { DASHBOARD_LATEST_JOB_APPLICATIONS, DASHBOARD_LATEST_JOB_OPPORTUNITIES, DASHBOARD_NOTIFICATIONS } from '../../constants';
import { REQUEST, FAILURE, GET, SUCCESS } from '../../../../redux/ActionType';

export function* getLatestJobApplications() {
    try {
        const params = {page: 0, size: 10, 'jobApplicationStatus.in': 'A qualifier'};
        const collaboratorId = yield select(state => state.Auth.collaborator.id);
        const payload = yield call(getMyJobApplications, collaboratorId, params);
        yield put({
            type: SUCCESS(GET(DASHBOARD_LATEST_JOB_APPLICATIONS)),
            payload: payload.list
        });
    } catch (error) {
        yield put({
            type: FAILURE(GET(DASHBOARD_LATEST_JOB_APPLICATIONS)),
            payload: error
        });
    }
}

export function* getLatestJobOpportunities() {
    try {
        const payload = yield call(services.getDashboardLatestJobOpportunities);
        yield put({
            type: SUCCESS(GET(DASHBOARD_LATEST_JOB_OPPORTUNITIES)),
            payload
        });
    } catch (error) {
        yield put({
            type: FAILURE(GET(DASHBOARD_LATEST_JOB_OPPORTUNITIES)),
            payload: error
        });
    }
}

export function* getNotifications() {
    try {
        const collaboratorId = yield select(state => state.Auth.collaborator.id);
        const {list, total} = yield call(services.getDashboardNotifications, collaboratorId);
        yield put({
            type: SUCCESS(GET(DASHBOARD_NOTIFICATIONS)),
            payload: {list, total}
        });
    } catch (error) {
        yield put({
            type: FAILURE(GET(DASHBOARD_NOTIFICATIONS)),
            payload: error
        });
    }
}

export function* watchDashboard() {
    yield takeLatest(REQUEST(GET(DASHBOARD_LATEST_JOB_APPLICATIONS)), getLatestJobApplications);
    yield takeLatest(REQUEST(GET(DASHBOARD_LATEST_JOB_OPPORTUNITIES)), getLatestJobOpportunities);
    yield takeLatest(REQUEST(GET(DASHBOARD_NOTIFICATIONS)), getNotifications);
}
