import {axios} from '../../../index';
import {COLLABORATORS_ENDPOINT} from '../endpoints';

export const getCollaboratorsFromRecruitment = () => {
    return axios.get(COLLABORATORS_ENDPOINT).then(response => {
        return response.data;
    });
};

export const getCollaborator = (id) => {
    return axios.get(`${COLLABORATORS_ENDPOINT}/${id}`)
        .then(response => ({collaborator: response.data}));
};
