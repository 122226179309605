import { all } from 'redux-saga/effects';
import { watchUserAuthentication } from './auth';
import { watchRecruitment } from '../../redux/sagas/recrutement';
import { watchCollaborator } from '../../../modules/Collaborators/redux/sagas';

export function* rootSaga() {
    yield all([
        watchUserAuthentication(),
        watchRecruitment(),
        watchCollaborator(),
    ]);
}
