import {call, put, takeLatest} from 'redux-saga/effects';
import * as services from '../../../services/Autohiring';

import {AUTOHIRING_CAMPAIGNS, AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS, AUTOHIRING_INVITATIONS} from '../../constants';
import {FAILURE, LIST, POST, REQUEST, SUCCESS} from '../../../../redux/ActionType';

export function* list(payload) {
    try {
        const response = yield call(services.getCampaigns, payload.params);
        yield put({ type: SUCCESS(LIST(AUTOHIRING_CAMPAIGNS)), response });
        if (payload.onSuccess) {
            payload.onSuccess(response.list);
        }
    } catch (error) {
        yield put({ type: FAILURE(LIST(AUTOHIRING_CAMPAIGNS)), error });
    }
}

export function* listCandidateCampaignResults(payload) {
    try {
        const response = yield call(services.getCandidateCampaignResults, payload.id, payload.params);
        yield put({ type: SUCCESS(LIST(AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS)), response });
        if (payload.onSuccess) {
            payload.onSuccess(response.list);
        }
    } catch (error) {
        yield put({ type: FAILURE(LIST(AUTOHIRING_CAMPAIGNS)), error });
    }
}

export function* post(payload) {
    try {
        const response = yield call(services.postInvitation, payload.invitation);
        yield put({ type: SUCCESS(POST(AUTOHIRING_INVITATIONS)), response });
        if(payload.onSuccess) payload.onSuccess(response);
    } catch (error) {
        yield put({ type: FAILURE(POST(AUTOHIRING_INVITATIONS)), error });
        if(payload.onFailure) payload.onFailure(error);

    }
}
export function* watchAutohiring() {
    yield takeLatest(REQUEST(LIST(AUTOHIRING_CAMPAIGNS)), list);
    yield takeLatest(REQUEST(LIST(AUTOHIRING_CANDIDATE_CAMPAIGN_RESULTS)), listCandidateCampaignResults);
    yield takeLatest(REQUEST(POST(AUTOHIRING_INVITATIONS)), post);

}
