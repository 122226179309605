import {PRERERENCES} from '../../constants';

const INIT_STATE = {
    preferences:{
        absence_activity_view:'calendar',
        absence_activity_calendar: 1,
        mImputations_calendar: 0,
        Imputations_calendar: 0,
        user_calendar: 1,
        projects: []
    }
};

export default function (state = INIT_STATE, action) {
    switch (action.type) {
        case PRERERENCES :
            return {...state, preferences: action.preferences};
        default:
            return state;
    }
}
