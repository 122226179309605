import { axios } from '../../../index';
import {
    ALL_JOB_APPLICATIONS_STATUS_ENDPOINT,
    ATTACHMENTS_ENDPOINT,
    DISPONIBILITY_ENDPOINT,
    JOB_APPLICATIONS_COMMENTS_ENDPOINT,
    JOB_APPLICATIONS_ASSIGNMENTS_ENDPOINT,
    JOB_APPLICATIONS_COUNT_ENDPOINT,
    JOB_APPLICATIONS_ENDPOINT,
    JOB_APPLICATIONS_SOURCE_ENDPOINT,
    JOB_APPLICATIONS_STATUS_ENDPOINT,
    JOB_APPLICATIONS_TIMELINE_ENDPOINT,
    SEARCH_JOB_APPLICATIONS_ENDPOINT,
    REQUEST_UPDATE_JOB_APPLICATIONS_ENDPOINT,
    SEARCH_MY_JOB_APPLICATIONS_ENDPOINT,
    SEARCH_ARCHIVED_JOB_APPLICATIONS_ENDPOINT,
    NEW_JOB_APPLICATIONS_ATTACHMENTS_ENDPOINT,
    SEARCH_NEW_JOB_APPLICATIONS_ENDPOINT,
    MY_JOB_APPLICATIONS_ENDPOINT,
} from '../endpoints';
import { PER_PAGE } from '../../utils/constants';
import { TECHNOLOGIES_ENDPOINT } from '../shared/endpoints';

export const archiveJobApplication = (id) => {
    return axios
        .put(`${JOB_APPLICATIONS_ENDPOINT}/${id}/archive`)
        .then((response) => response.data);
};

export const favoriteJobApplication = (id, technologyId) => {
    return axios
        .put(`${JOB_APPLICATIONS_ENDPOINT}/${id}/favorite/${technologyId}`)
        .then((response) => response.data);
};

export const unfavoriteJobApplication = (id, technologyId) => {
    return axios
        .put(`${JOB_APPLICATIONS_ENDPOINT}/${id}/unfavorite/${technologyId}`)
        .then((response) => response.data);
};

export const comments = (params) => {
    return axios
        .get(JOB_APPLICATIONS_COMMENTS_ENDPOINT, { params })
        .then((response) => response.data);
};

export const jobApplicationAssignments = (params) => {
    return axios
        .get(JOB_APPLICATIONS_ASSIGNMENTS_ENDPOINT, { params })
        .then((response) => response.data);
};

export const getJobApplications = (params) => {
    const endpoint =
        params && params.query
            ? SEARCH_JOB_APPLICATIONS_ENDPOINT
            : JOB_APPLICATIONS_ENDPOINT;
    const tempParams = {
        ...(params || {
            size: PER_PAGE,
            page: 0,
            sort: 'applicationDate,desc',
        }),
    };

    const listAllParams = buildParams(tempParams);

    return axios.get(endpoint, { params: listAllParams }).then((response) => {
        return {
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        };
    });
};

const buildParams = (params) => {
    const out = new URLSearchParams();

    Object.keys(params).forEach((k) => {
        if (params[k] === null || params[k] === undefined) delete params[k];
    });

    if (!params['sort'] || params['sort'].includes('id,')) {
        params['sort'] = 'applicationDate,desc';
    }

    Object.keys(params).forEach((k) => out.append(k, params[k]));
    out.append('sort', 'id,desc');

    return out;
};

export const getJobApplicationsByTechnology = (technologyId, params) => {
    const endpoint = technologyId
        ? `${TECHNOLOGIES_ENDPOINT}/${technologyId}/job-applications`
        : `${TECHNOLOGIES_ENDPOINT}/job-applications`;

    const tempParams = {
        ...(params || {
            size: PER_PAGE,
            page: 0,
            sort: 'applicationDate,desc',
        }),
    };

    const favoriteParams = buildParams(tempParams);

    return axios.get(endpoint, { params: favoriteParams }).then((response) => {
        return {
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        };
    });
};

export const getJobApplicationsStatus = (params) => {
    return axios
        .get(JOB_APPLICATIONS_STATUS_ENDPOINT, {
            params: { ...params, size: 1000, sort: 'id,desc' },
        })
        .then((response) => response.data);
};
export const postJobApplicationStatus = (jobApplicationStatus) => {
    return axios
        .post(JOB_APPLICATIONS_STATUS_ENDPOINT, jobApplicationStatus)
        .then((response) => response.data);
};
export const putJobApplicationStatus = (jobApplicationStatus) => {
    return axios
        .put(JOB_APPLICATIONS_STATUS_ENDPOINT, jobApplicationStatus)
        .then((response) => response.data);
};
export const destroyJobApplicationStatus = (id) => {
    return axios
        .delete(`${JOB_APPLICATIONS_STATUS_ENDPOINT}/${id}`)
        .then((response) => response.data);
};
export const getDisponibility = (params) => {
    return axios
        .get(DISPONIBILITY_ENDPOINT, { params })
        .then((response) => response.data);
};
export const getJobApplicationsSource = (params) => {
    return axios
        .get(JOB_APPLICATIONS_SOURCE_ENDPOINT, {
            params: { ...params, size: 1000, sort: 'id,desc' },
        })
        .then((response) => response.data);
};
export const postJobApplicationSource = (jobApplicationSource) => {
    return axios
        .post(JOB_APPLICATIONS_SOURCE_ENDPOINT, jobApplicationSource)
        .then((response) => response.data);
};
export const putJobApplicationSource = (jobApplicationSource) => {
    return axios
        .put(JOB_APPLICATIONS_SOURCE_ENDPOINT, jobApplicationSource)
        .then((response) => response.data);
};
export const destroyJobApplicationSource = (id) => {
    return axios
        .delete(`${JOB_APPLICATIONS_SOURCE_ENDPOINT}/${id}`)
        .then((response) => response.data);
};

export const getJobApplicationTimeline = (params) => {
    return axios
        .get(JOB_APPLICATIONS_TIMELINE_ENDPOINT, { params })
        .then((response) => response.data);
};

export const getJobApplicationsCount = (params) => {
    return axios
        .get(JOB_APPLICATIONS_COUNT_ENDPOINT, {
            params: params || { 'isArchived.equals': false },
        })
        .then((response) => response.data);
};

export const getJobApplication = (id) => {
    return axios
        .get(`${JOB_APPLICATIONS_ENDPOINT}/${id}`)
        .then((response) => response.data);
};
export const postJobApplication = (jobApplication) => {
    return axios
        .post(JOB_APPLICATIONS_ENDPOINT, jobApplication)
        .then((response) => response.data);
};
export const putJobApplication = (jobApplication) => {
    return axios
        .put(JOB_APPLICATIONS_ENDPOINT, jobApplication)
        .then((response) => response.data);
};

export const addAttachmentsToNewApplicationService = (jobApplication) => {
    return axios
        .put(NEW_JOB_APPLICATIONS_ATTACHMENTS_ENDPOINT, jobApplication)
        .then((response) => response.data);
};

export const destroyJobApplication = (id) => {
    return axios
        .delete(`${JOB_APPLICATIONS_ENDPOINT}/${id}`)
        .then((response) => response.data);
};

export const destroyAttachment = (id) => {
    return axios
        .delete(`${ATTACHMENTS_ENDPOINT}/${id}`)
        .then((response) => response.data);
};

export const postAttachment = (attachment, onProgress, onSuccess) => {
    const config = {
        onUploadProgress: onProgress,
    };
    return axios
        .post(ATTACHMENTS_ENDPOINT, attachment, config)
        .then((response) => {
            onSuccess(response.data);
            return response.data;
        });
};

export const postComment = (comment) => {
    return axios
        .post(JOB_APPLICATIONS_COMMENTS_ENDPOINT, comment)
        .then((response) => response.data);
};
export const putComment = (comment) => {
    return axios
        .put(JOB_APPLICATIONS_COMMENTS_ENDPOINT, comment)
        .then((response) => response.data);
};
export const deleteComment = (id) => {
    return axios
        .delete(JOB_APPLICATIONS_COMMENTS_ENDPOINT + '/' + id)
        .then((response) => response.data);
};

export const putJobApplicationAssignment = (jobApplicationAssignment) => {
    return axios
        .put(JOB_APPLICATIONS_ASSIGNMENTS_ENDPOINT, jobApplicationAssignment)
        .then((response) => response.data);
};
export const deleteJobApplicationAssignment = (id) => {
    return axios
        .delete(`${JOB_APPLICATIONS_ASSIGNMENTS_ENDPOINT}/${id}`)
        .then((response) => response.data);
};

export const listAttachments = (params) => {
    return axios
        .get(ATTACHMENTS_ENDPOINT, { params })
        .then((response) => response.data);
};
export const downloadAttachment = (id) => {
    return axios
        .get(`${ATTACHMENTS_ENDPOINT}/${id}/download`)
        .then((response) => response.data);
};

export const putAllJobApplicationStatus = (status) => {
    return axios
        .put(ALL_JOB_APPLICATIONS_STATUS_ENDPOINT, status)
        .then((response) => response.data);
};

export const requestUpdate = (id) =>
    axios.post(`${REQUEST_UPDATE_JOB_APPLICATIONS_ENDPOINT}/${id}`);

/*
 ** My Job Applications
 */

export const getMyJobApplications = (collaboratorId, params) => {
    const endpoint =
        params && params.query
            ? `${SEARCH_MY_JOB_APPLICATIONS_ENDPOINT}${collaboratorId}`
            : `${MY_JOB_APPLICATIONS_ENDPOINT}${collaboratorId}`;

    const tempParams = {
        ...(params || {
            size: PER_PAGE,
            page: 0,
            sort: 'applicationDate,desc',
        }),
        'isArchived.equals': false,
    };

    const myParams = buildParams(tempParams);

    return axios.get(endpoint, { params: myParams }).then((response) => {
        return {
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        };
    });
};

/*
 ** Archived Job Applications
 */

export const getArchivedJobApplications = (params) => {
    const endpoint =
        params && params.query
            ? SEARCH_ARCHIVED_JOB_APPLICATIONS_ENDPOINT
            : JOB_APPLICATIONS_ENDPOINT;

    const tempParams = {
        ...(params || {
            size: PER_PAGE,
            page: 0,
            sort: 'applicationDate,desc',
        }),
        'isArchived.equals': true,
    };

    const archiveParams = buildParams(tempParams);

    return axios.get(endpoint, { params: archiveParams }).then((response) => {
        return {
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        };
    });
};

export const getNewJobApplications = (params) => {
    const endpoint =
        params && params.query
            ? SEARCH_NEW_JOB_APPLICATIONS_ENDPOINT
            : JOB_APPLICATIONS_ENDPOINT;

    const tempParams = {
        ...(params || {
            size: PER_PAGE,
            page: 0,
            sort: 'applicationDate,desc',
        }),
        'isArchived.equals': false,
        'jobApplicationStatus.in': 'Nouvelle',
    };

    const newParams = buildParams(tempParams);

    return axios.get(endpoint, { params: newParams }).then((response) => {
        return {
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        };
    });
};
