import {call, delay, put, takeEvery, takeLatest, all} from 'redux-saga/effects';
import * as services from '../../../services/Interviewers';

import {ALL_INTERVIEWERS, INTERVIEWERS,} from '../../constants';
import {DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS, MULTIPLE} from '../../../../redux/ActionType';

export function* list(payload) {
    yield delay(300);

    try {
        const response = yield call(services.getInterviewers, payload.params);
        yield put({type: SUCCESS(LIST(INTERVIEWERS)), response});
    } catch (error) {
        yield put({type: FAILURE(LIST(INTERVIEWERS)), error});
    }
}

export function* listAll(payload) {
    try {
        const response = yield call(services.getAllInterviewers, payload.params);
        yield put({ type: SUCCESS(LIST(ALL_INTERVIEWERS)), response });
    } catch(error) {
        yield put({ type: FAILURE(LIST(ALL_INTERVIEWERS)), error });
    }
}

export function* get(payload) {
    try {
        const response = yield call(services.getInterviewer, payload.id);
        yield put({ type: SUCCESS(GET(INTERVIEWERS)), response });
    } catch(error) {
        yield put({ type: FAILURE(GET(INTERVIEWERS)), error });
    }
}

export function* post(payload) {
    try {
        const response = yield call(services.postInterviewer, payload.interviewer);
        yield put({ type: SUCCESS(POST(INTERVIEWERS)), response });
        if(payload.onSuccess) payload.onSuccess(response);
    } catch(error) {
        yield put({ type: FAILURE(POST(INTERVIEWERS)), error });
    }
}

export function* update(payload) {
    try {
        const response = yield call(services.putInterviewer, payload.interviewer);
        yield put({ type: SUCCESS(PUT(INTERVIEWERS)), response });
    } catch(error) {
        yield put({ type: FAILURE(PUT(INTERVIEWERS)), error });
    }
}

export function* destroy(payload) {
    try {

        yield call(services.destroyInterviewer, payload.id);
        yield put({ type: SUCCESS(DELETE(INTERVIEWERS)), response:payload.id });
        if(payload.onSuccess)payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(INTERVIEWERS)), error });
    }
}

export function* destroyMultiple(payload) {
    try {
        yield all(payload.ids.map(id => call(services.destroyInterviewer, id)));
        yield put({ type: SUCCESS(DELETE(MULTIPLE(INTERVIEWERS))), response:payload.id });
    } catch(error) {
        yield put({ type: FAILURE(DELETE(MULTIPLE(INTERVIEWERS))), error });
    } finally {
        if(payload.callback) payload.callback();
    }
}

export function* watchInterviewers() {
    yield takeLatest(REQUEST(LIST(INTERVIEWERS)), list);
    yield takeLatest(REQUEST(LIST(ALL_INTERVIEWERS)), listAll);
    yield takeLatest(SUCCESS(PUT(INTERVIEWERS)), list);
    yield takeLatest(SUCCESS(POST(INTERVIEWERS)), list);
    yield takeLatest(REQUEST(GET(INTERVIEWERS)), get);
    yield takeLatest(REQUEST(POST(INTERVIEWERS)), post);
    yield takeLatest(REQUEST(PUT(INTERVIEWERS)), update);
    yield takeEvery(REQUEST(DELETE(INTERVIEWERS)), destroy);
    yield takeEvery(REQUEST(DELETE(MULTIPLE(INTERVIEWERS))), destroyMultiple);
}
