import { axios } from '../../../index';
import {
    INTERVIEWERS_ENDPOINT,
    SEARCH_INTERVIEWERS_ENDPOINT,
} from '../endpoints';
import { PER_PAGE } from '../../utils/constants';

export const getInterviewers = (params) => {
    return axios
        .get(
            params && params.query
                ? SEARCH_INTERVIEWERS_ENDPOINT
                : INTERVIEWERS_ENDPOINT,
            { params: params || { size: PER_PAGE, page: 0, sort: 'id,desc' } }
        )
        .then((response) => ({
            list: response.data,
            count: parseInt(response.headers['x-total-count']),
        }));
};
export const getAllInterviewers = (params) => {
    return params
        ? getInterviewers(params)
        : axios
              .get(INTERVIEWERS_ENDPOINT)
              .then((response) => ({ list: response.data }));
};

export const getInterviewer = (id) => {
    return axios
        .get(`${INTERVIEWERS_ENDPOINT}/${id}`)
        .then((response) => ({ interviewer: response.data }));
};

export const postInterviewer = (interviewer) => {
    return axios
        .post(INTERVIEWERS_ENDPOINT, interviewer)
        .then((response) => response.data);
};
export const putInterviewer = (interviewer) => {
    return axios
        .put(INTERVIEWERS_ENDPOINT, interviewer)
        .then((response) => response.data);
};
export const destroyInterviewer = (id) => {
    return axios
        .delete(`${INTERVIEWERS_ENDPOINT}/${id}`)
        .then((response) => response.data);
};
