import {CLEAR, DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS} from '../../../../redux/ActionType';
import {
    JOB_OPPORTUNITIES, JOB_OPPORTUNITIES_AS_RESOURCES,
    JOB_OPPORTUNITIES_COUNT,
    JOB_OPPORTUNITY_STATUS
} from '../../constants';

const initialState = {
    loading: false,
    list:[],
    jobOpportunity:{},
    Status:{list:[]},
    listAsResources:[]
};

function deleteSuccess(state, id) {
    const list=state.list.filter(e=>e.id!==id);
    state = Object.assign({},state,{list,count:state.count-1, loading:false});
    return state;
}

const JobOpportunities = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case REQUEST(LIST(JOB_OPPORTUNITIES)):
            return {...state, loading: true};
        case SUCCESS(LIST(JOB_OPPORTUNITIES)):
            return {...state, loading: false, list: response.list, count: response.count};
        case FAILURE(LIST(JOB_OPPORTUNITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(LIST(JOB_OPPORTUNITIES_AS_RESOURCES)):
            return {...state, loading: true};
        case SUCCESS(LIST(JOB_OPPORTUNITIES_AS_RESOURCES)):
            return {...state, loading: false, listAsResources: response.listAsResources};
        case FAILURE(LIST(JOB_OPPORTUNITIES_AS_RESOURCES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(GET(JOB_OPPORTUNITIES)):
            return {...state, loading: true};
        case SUCCESS(GET(JOB_OPPORTUNITIES)):
            return {...state, loading: false, jobOpportunity: response.jobOpportunity};
        case FAILURE(GET(JOB_OPPORTUNITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(LIST(JOB_OPPORTUNITY_STATUS)):
            return {...state, Status: {...state.Status, loading: true}};
        case SUCCESS(LIST(JOB_OPPORTUNITY_STATUS)):
            return {...state, Status: {list: response, loading: false}};
        case FAILURE(LIST(JOB_OPPORTUNITY_STATUS)):
            return {...state, errorMessage: action.error.message, loading:false, Status: {...state.Status, loading: false}};

        case REQUEST(PUT(JOB_OPPORTUNITY_STATUS)):
            return {...state, loading: true};
        case SUCCESS(PUT(JOB_OPPORTUNITY_STATUS)):
            return {...state, loading: false, jobOpportunityStatus: {}};
        case FAILURE(PUT(JOB_OPPORTUNITY_STATUS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(JOB_OPPORTUNITY_STATUS)):
            return {...state, loading: true};
        case SUCCESS(POST(JOB_OPPORTUNITY_STATUS)):
            return {...state, loading: false,jobOpportunityStatus:{}};
        case FAILURE(POST(JOB_OPPORTUNITY_STATUS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(JOB_OPPORTUNITY_STATUS)):
            return {...state, loading: true};
        case SUCCESS(DELETE(JOB_OPPORTUNITY_STATUS)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(JOB_OPPORTUNITY_STATUS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(JOB_OPPORTUNITY_STATUS):
            return {...state, Status:initialState.Status, errorMessage:null, list:[], loading:false};

        case REQUEST(GET(JOB_OPPORTUNITIES_COUNT)):
            return {...state, loading:true};
        case SUCCESS(GET(JOB_OPPORTUNITIES_COUNT)):
            return {...state, loading:false, Count: response};
        case FAILURE(GET(JOB_OPPORTUNITIES_COUNT)):
            return {...state, errorMessage: action.error.message, loading:false};

        case REQUEST(PUT(JOB_OPPORTUNITIES)):
            return {...state, loading: true};
        case SUCCESS(PUT(JOB_OPPORTUNITIES)):
            return {...state, loading: false, jobOpportunity: {}};
        case FAILURE(PUT(JOB_OPPORTUNITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(POST(JOB_OPPORTUNITIES)):
            return {...state, loading: true};
        case SUCCESS(POST(JOB_OPPORTUNITIES)):
            return {...state, loading: false,jobOpportunity:{}};
        case FAILURE(POST(JOB_OPPORTUNITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(JOB_OPPORTUNITIES)):
            return {...state, loading: true};
        case SUCCESS(DELETE(JOB_OPPORTUNITIES)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(JOB_OPPORTUNITIES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case CLEAR(JOB_OPPORTUNITIES):
            return {...state, jobOpportunity:{}, errorMessage:null, list:[], loading:false, count:0};
        default:
            return state;

    }
};
export {JobOpportunities};
