import {AUTHORITIES} from '../utils/constants';
import {t} from '../../i18n';

const items= [
    {
        name: t('dashboard'),
        url: '/dashboard',
        icon: 'fas fa-tachometer-alt',
        roles: [AUTHORITIES.ADMIN,AUTHORITIES.RH, AUTHORITIES.USER],
        permission:'recruitment.dashboard.manageDashboard'
    },
    {
        name: t('offers'),
        url: '/offers',
        icon: 'fa fa-book',
        roles: [AUTHORITIES.ADMIN,AUTHORITIES.RH],
        permission:'recruitment.jobOpportunities.manageJobOpportunities'
    },
    {
        name: t('applications'),
        url: '/applications/myapplications',
        icon: 'fa fa-file',
        roles: [AUTHORITIES.ADMIN,AUTHORITIES.RH],
        permission:'recruitment.jobApplications.manageJobApplications'

    },

    {
        name: t('candidates'),
        url: '/candidates',
        icon: 'fa fa-users',
        roles: [AUTHORITIES.ADMIN,AUTHORITIES.RH],
        permission:'recruitment.candidates.manageCandidates'
    },
    {
        name: t('interviews'),
        url: '/interviews',
        icon: 'fa fa-handshake',
        roles: [AUTHORITIES.ADMIN,AUTHORITIES.RH],
        permission:'recruitment.interviews.manageInterviews'

    },

    {
        name: t('interviewers'),
        url: '/interviewers',
        icon: 'fas fa-user-tie',
        roles: [AUTHORITIES.ADMIN,AUTHORITIES.RH],
        permission:'recruitment.interviewers.manageInterviewers'

    },
    {
        name: t('jobApplicationWorkflow'),
        url: '/workflow',
        icon: 'fas fa-project-diagram',
        roles: [AUTHORITIES.ADMIN,AUTHORITIES.RH],
        permission: 'recruitment.referenceTables.manageReferenceTables'

    }
    ,
    {
        name: t('rtables'),
        url: '/referenciel',
        icon: 'fas fa-clipboard-list',
        roles: [AUTHORITIES.ADMIN, AUTHORITIES.RH],
        permission: 'recruitment.referenceTables.manageReferenceTables'
    }

];
export default {items:items};

