import React from "react";
import { Button } from "reactstrap";

const StateButton = ({ isLoading, children, disabled, ...rest }) => (
  <Button {...rest} disabled={disabled || isLoading}>
    {isLoading ? (
      <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
    ) : (
      children
    )}
  </Button>
);
export default StateButton;
