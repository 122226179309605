import {FAILURE, LIST, REQUEST, SUCCESS} from '../../../../redux/ActionType';
import {COLLABORATORS} from '../../constants';

const initialState = {
    loading: false,
    list: [],
    errorMessage:null,
    count:0
};

const Collaborators = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {

        case REQUEST(LIST(COLLABORATORS)):
            return {...state, loading: true};
        case SUCCESS(LIST(COLLABORATORS)):
            return {...state, loading: false, list: response, count: response.count};
        case FAILURE(LIST(COLLABORATORS)):
            return {...state, errorMessage: action.error.message, loading: false};

        default:
            return state;
    }
};
export {Collaborators};
