import {
    ARCHIVE,
    CLEAR,
    DELETE,
    FAVORITE,
    GET,
    LIST,
    POST,
    PUT,
    REQUEST,
    UNFAVORITE,
    MULTIPLE
} from '../../../../redux/ActionType';
import {
    ALL_JOB_APPLICATION_STATUS,
    ATTACHMENT,
    DISPONIBILITY,
    JOB_APPLICATION_COMMENTS,
    JOB_APPLICATION_ASSIGNMENTS,
    JOB_APPLICATION_SOURCE,
    JOB_APPLICATION_STATUS,
    JOB_APPLICATION_TIMELINE,
    JOB_APPLICATIONS,
    JOB_APPLICATIONS_COUNT,
    REQUEST_UPDATE_JOB_APPLICATION,
    NEW_JOB_APPLICATIONS_ATTACHMENTS
} from '../../constants';

export * from './myJobApplications';
export * from './archivedJobApplications';
export * from './favoriteJobApplications';
export * from './newJobApplications';

export const getJobApplications = (params) => {
    return {
        type: REQUEST(LIST(JOB_APPLICATIONS)),
        params,
        loading: true,
    };
};

export const getJobApplication = (id, onSuccess) => {
    return {
        type: REQUEST(GET(JOB_APPLICATIONS)),
        id,
        onSuccess,
        loading: true,
    };
};

export const postJobApplication = (jobApplication, onSuccess) => {
    return {
        type: REQUEST(POST(JOB_APPLICATIONS)),
        jobApplication,
        onSuccess
    };
};
export const putJobApplication = (jobApplication, onSuccess) => {
    return {
        type: REQUEST(PUT(JOB_APPLICATIONS)),
        jobApplication,
        loading: true,
        onSuccess
    };
};

export const addAttachmentsToNewJobApplication = (jobApplication, onSuccess) => {
    return {
        type: REQUEST(PUT(NEW_JOB_APPLICATIONS_ATTACHMENTS)),
        jobApplication,
        onSuccess
    };
};

export const deleteJobApplications = (id, onSuccess) => {
    return {
        type: REQUEST(DELETE(JOB_APPLICATIONS)),
        id,
        onSuccess
    };
};

export const deleteMultipleJobApplications = (ids, onSuccess) => {
    return {
        type: REQUEST(DELETE(MULTIPLE(JOB_APPLICATIONS))),
        ids,
        onSuccess
    };
};

export const clearJobApplication = () => {
    return {
        type: CLEAR(JOB_APPLICATIONS)
    };
};

export const getJobApplicationStatus = (params) => {
    return {
        type: REQUEST(LIST(JOB_APPLICATION_STATUS)),
        params,
        loading: true,
    };
};

export const postJobApplicationStatus = (jobApplicationStatus, onSuccess, onFailure) => {
    return {
        type: REQUEST(POST(JOB_APPLICATION_STATUS)),
        jobApplicationStatus,
        loading: true,
        onSuccess,
        onFailure
    };
};
export const putJobApplicationStatus = (jobApplicationStatus, onSuccess, onFailure) => {
    return {
        type: REQUEST(PUT(JOB_APPLICATION_STATUS)),
        jobApplicationStatus,
        loading: true,
        onSuccess,
        onFailure
    };
};
export const deleteJobApplicationStatus = (id, onSuccess,onFailure) => {
    return {
        type: REQUEST(DELETE(JOB_APPLICATION_STATUS)),
        id,
        onSuccess, onFailure
    };
};
export const clearJobApplicationStatus = () => {
    return {
        type: CLEAR(JOB_APPLICATION_STATUS)
    };
};

export const getJobApplicationSource = (params) => {
    return {
        type: REQUEST(LIST(JOB_APPLICATION_SOURCE)),
        params,
        loading: true,
    };
};

export const postJobApplicationSource = (jobApplicationSource, onSuccess, onFailure) => {
    return {
        type: REQUEST(POST(JOB_APPLICATION_SOURCE)),
        jobApplicationSource,
        loading: true,
        onSuccess,
        onFailure
    };
};

export const putJobApplicationSource = (jobApplicationSource, onSuccess, onFailure) => {
    return {
        type: REQUEST(PUT(JOB_APPLICATION_SOURCE)),
        jobApplicationSource,
        loading: true,
        onSuccess,
        onFailure
    };
};
export const deleteJobApplicationSource = (id, onSuccess,onFailure) => {
    return {
        type: REQUEST(DELETE(JOB_APPLICATION_SOURCE)),
        id,
        onSuccess,onFailure
    };
};
export const clearJobApplicationSource = () => {
    return {
        type: CLEAR(JOB_APPLICATION_SOURCE)
    };
};
export const postAttachment = (attachment, onProgress, onSuccess, onError) => ({
    type: REQUEST(POST(ATTACHMENT)),
    attachment,
    onProgress,
    onSuccess,
    onError,
    loading: true
});

export const listAttachments = (params) => ({
    type: REQUEST(LIST(ATTACHMENT)),
    params,
    loading: true
});

export const deleteAttachment = (id) => {
    return {
        type: REQUEST(DELETE(ATTACHMENT)),
        id,
    };
};

export const postComment = (comment, onSuccess, onFailure) => ({
    type: REQUEST(POST(JOB_APPLICATION_COMMENTS)),
    comment,
    loading: true,
    onSuccess, onFailure
});
export const deleteComment = (id, onSuccess) => ({
    type: REQUEST(DELETE(JOB_APPLICATION_COMMENTS)),
    id,
    loading: true,
    onSuccess
});
export const updateComment = (comment) => ({
    type: REQUEST(PUT(JOB_APPLICATION_COMMENTS)),
    comment,
    loading: true
});

export const deleteJobApplicationAssignment = (id, onSuccess) => ({
    type: REQUEST(DELETE(JOB_APPLICATION_ASSIGNMENTS)),
    id,
    loading: true,
    onSuccess
});
export const updateJobApplicationAssignment = (jobApplicationAssignment) => ({
    type: REQUEST(PUT(JOB_APPLICATION_ASSIGNMENTS)),
    jobApplicationAssignment,
    loading: true
});

export const getJobApplicationTimeline = (params) => {
    return {
        type: REQUEST(LIST(JOB_APPLICATION_TIMELINE)),
        params,
        loading: true,
    };
};

export const getJobApplicationsCount = (params) => {
    return {
        type: REQUEST(GET(JOB_APPLICATIONS_COUNT)),
        params,
        loading: true
    };
};

export const getJobApplicationComments = (params) => {
    return {
        type: REQUEST(LIST(JOB_APPLICATION_COMMENTS)),
        params,
        loading: true,
    };
};

export const getJobApplicationAssignments = (params) => {
    return {
        type: REQUEST(LIST(JOB_APPLICATION_ASSIGNMENTS)),
        params,
        loading: true,
    };
};

export const updateAllJobApplicationStatus = (jobStatus) => {
    return {
        type: REQUEST(PUT(ALL_JOB_APPLICATION_STATUS)),
        jobStatus,
        loading: true
    };
};
export const getDisponibility = (params) => {
    return {
        type: REQUEST(LIST(DISPONIBILITY)),
        params,
        loading: true,
    };
};
export const archiveJobApplication = (id, onSuccess) => {
    return {
        type: REQUEST(ARCHIVE(JOB_APPLICATIONS)),
        id,
        onSuccess
    };
};
export const favoriteJobApplication = (id, technologyId, onSuccess) => {
    return {
        type: REQUEST(FAVORITE(JOB_APPLICATIONS)),
        id,
        technologyId,
        onSuccess
    };
};
export const unfavoriteJobApplication = (id, technologyId, onSuccess) => {
    return {
        type: REQUEST(UNFAVORITE(JOB_APPLICATIONS)),
        id,
        technologyId,
        onSuccess
    };
};

export const requestUpdateJobApplication = (id, onSuccess, onError) => {
    return {
        type: REQUEST(POST(REQUEST_UPDATE_JOB_APPLICATION)),
        id,
        onSuccess,
        onError
    };
};
