import {
  CLEAR,
  DELETE,
  FAILURE,
  GET,
  LIST,
  POST,
  PUT,
  REQUEST,
  SUCCESS
} from "../../../../redux/ActionType";
import {
  CANDIDATES,
  CANDIDATES_COUNT,
  PARSER_ATTACHMENT,
  PARSER_ATTACHMENT_FR
} from "../../constants";

const initialState = {
  loading: false,
  list: [],
  candidate: {},
  count: 0,
  errorMessage: undefined,
  formErrorMessage: undefined
};

function deleteSuccess(state, id) {
  const list = state.list.filter(e => e.id !== id);
  state = Object.assign({}, state, { list, count: state.count - 1 });
  return state;
}

const Candidates = (state = initialState, action) => {
  const response = action.response;
  switch (action.type) {
    case REQUEST(LIST(CANDIDATES)):
      return { ...state, loading: true };
    case SUCCESS(LIST(CANDIDATES)):
      return {
        ...state,
        loading: false,
        list: response.list,
        count: response.count
      };
    case FAILURE(LIST(CANDIDATES)):
      return { ...state, errorMessage: action.error.message, loading: false };

    case REQUEST(GET(CANDIDATES)):
      return { ...state, loading: true, candidate: {} };
    case SUCCESS(GET(CANDIDATES)):
      return { ...state, loading: false, candidate: response.candidate };
    case FAILURE(GET(CANDIDATES)):
      return { ...state, errorMessage: action.error.message, loading: false };

    case REQUEST(GET(CANDIDATES_COUNT)):
      return { ...state, loading: true };
    case SUCCESS(GET(CANDIDATES_COUNT)):
      return { ...state, loading: false, Count: response };
    case FAILURE(GET(CANDIDATES_COUNT)):
      return { ...state, errorMessage: action.error.message, loading: false };

    case REQUEST(PUT(CANDIDATES)):
      return { ...state, loading: true };
    case SUCCESS(PUT(CANDIDATES)):
      return { ...state, loading: false, candidate: {} };
    case FAILURE(PUT(CANDIDATES)):
      return { ...state, formErrorMessage: action.error.message, loading: false };

    case REQUEST(POST(CANDIDATES)):
      return { ...state, loading: true };
    case SUCCESS(POST(CANDIDATES)):
      return { ...state, loading: false, candidate: response };
    case FAILURE(POST(CANDIDATES)):
      return { ...state, formErrorMessage: action.error.message, loading: false };

    case REQUEST(DELETE(CANDIDATES)):
      return { ...state, loading: true };
    case SUCCESS(DELETE(CANDIDATES)):
      return deleteSuccess(state, response);
    case FAILURE(DELETE(CANDIDATES)):
      return { ...state, errorMessage: action.error.message, loading: false };

    case CLEAR(CANDIDATES):
      return {
        ...state,
        candidate: {},
        errorMessage: null,
        list: [],
        loading: false,
        count: 0
      };

    case SUCCESS(POST(PARSER_ATTACHMENT)):
      return { ...state, candidate: response.candidate, loading: false };

    case SUCCESS(POST(PARSER_ATTACHMENT_FR)):
      return {
        ...state,
        candidate: response.candidate,
        errorMessage: undefined,
        loading: false
      };
    case FAILURE(POST(PARSER_ATTACHMENT_FR)):
      return {
        ...state,
        candidate: {},
        errorMessage: action.error.message,
        loading: false
      };
    default:
      return state;
  }
};
export { Candidates };
