import React from 'react';
import {t} from '../../i18n';

const RecruitmentDashboard = React.lazy(() => import('../pages/Dashboard'));
const Candidates = React.lazy(() => import('../pages/Candidates'));
const Candidate = React.lazy(() => import('../pages/Candidates/show'));
const AddCandidate = React.lazy(() => import('../pages/Candidates/create'));

const Interviewers = React.lazy(() => import('../pages/Interviewers'));
const RRTables = React.lazy(() => import('../pages/RTables'));

const Interviews = React.lazy(() => import('../pages/Interviews'));
const AddInterview = React.lazy(() => import('../pages/Interviews/create'));

const JobOpportunities = React.lazy(() => import('../pages/JobOpportunities'));
const JobOpportunity = React.lazy(() => import('../pages/JobOpportunities/show'));
const AddJobOpportunity = React.lazy(() => import('../pages/JobOpportunities/create'));
const JobApplications = React.lazy(() => import('../pages/JobApplications'));
const JobApplicationsFavorites = React.lazy(() => import('../pages/JobApplications/favorites'));
const JobApplication = React.lazy(() => import('../pages/JobApplications/show'));
const AddJobApplication = React.lazy(() => import('../pages/JobApplications/create'));
const EditJobApplication = React.lazy(() => import('../pages/JobApplications/edit'));
const JobWorkflow = React.lazy(() => import('../pages/JobApplicationWorkflow'));
export const recruitmentRoutes =[

    { path: '/', exact: true, name: t('recruitment'), component: RecruitmentDashboard, permission: 'recruitment.dashboard.manageDashboard', private: true, className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/dashboard', exact: true, name: t('dashboard'), component: RecruitmentDashboard, permission: 'recruitment.dashboard.manageDashboard', private: true, className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/candidates', exact: true, name: t('candidates'), component: Candidates, private: true, permission: 'recruitment.candidates.candidatesPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/candidates/create', exact: true, name: t('add_candidate'), component: AddCandidate, private: true, permission: 'recruitment.candidates.candidatesPermissions.create', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/candidates/:id/edit', exact: true, name: t('edit_candidate'), component: AddCandidate, private: true, permission: 'recruitment.candidates.candidatesPermissions.update', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/candidates/:id', exact: true, name: t('candidate'), component: Candidate, private: true, permission: 'recruitment.candidates.candidatesPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },

    { path: '/interviewers', exact: true, name: t('interviewers'), component: Interviewers, private: true, permission: 'recruitment.interviewers.interviewersPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/referenciel', exact: true, name: t('rtables'), component: RRTables, private: true, permission: 'recruitment.referenceTables.manageReferenceTables', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },

    { path: '/interviews', exact: true, name: t('interviews'), component: Interviews, private: true, permission: 'recruitment.interviews.interviewsPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/interviews/create', exact: true, name: t('add_interview'), component: AddInterview, private: true, permission: 'recruitment.interviews.interviewsPermissions.create', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/interviews/:iid/edit', exact: true, name: t('edit_interview'), component: AddInterview, private: true, permission: 'recruitment.interviews.interviewsPermissions.update', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },

    { path: '/offers', exact: true, name: t('offers'), component: JobOpportunities, private: true, permission: 'recruitment.jobOpportunities.jobOpportunitiesPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/offers/create', exact: true, name: t('add_offer'), component: AddJobOpportunity, private: true, permission: 'recruitment.jobOpportunities.jobOpportunitiesPermissions.create', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/offers/:id/edit', exact: true, name: t('edit_offer'), component: AddJobOpportunity, private: true, permission: 'recruitment.jobOpportunities.jobOpportunitiesPermissions.update', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/offers/:id', exact: true, name: t('offer'), component: JobOpportunity, private: true, permission: 'recruitment.jobOpportunities.jobOpportunitiesPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },

    { path: '/applications', exact: true, name: t('applications'), component: JobApplications, private: true, permission: 'recruitment.jobApplications.jobApplicationsPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/applications/newapplications', exact: true, name: t('new_job_applications'), component: JobApplications, private: true, permission: 'recruitment.jobApplications.jobApplicationsPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/applications/myapplications', exact: true, name: t('my_job_applications'), component: JobApplications, private: true, permission: ['recruitment.jobApplications.jobApplicationsPermissions.list', 'recruitment.jobApplications.jobApplicationsPermissions.qualify'], className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/applications/archives', exact: true, name: t('archived_job_applications'), component: JobApplications, private: true, permission: 'recruitment.jobApplications.jobApplicationsPermissions.list', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/applications/favorites', exact: true, name: t('starred_job_applications'), component: JobApplicationsFavorites, private: true, permission: ['recruitment.jobApplications.jobApplicationsPermissions.list', 'recruitment.jobApplications.jobApplicationsPermissions.qualify'], className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/applications/create', exact: true, name: t('add_application'), component: AddJobApplication, private: true, permission: 'recruitment.jobApplications.jobApplicationsPermissions.create', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/applications/create/:candidate', exact: true, name: t('add_application'), component: AddJobApplication, private: true, permission: 'recruitment.jobApplications.jobApplicationsPermissions.create', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/applications/:id/edit', exact: true, name: t('edit_application'), component: EditJobApplication, private: true, permission: 'recruitment.jobApplications.jobApplicationsPermissions.update', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
    { path: '/applications/:id', exact: true, name: t('application'), component: JobApplication, private: true, permission: ['recruitment.jobApplications.jobApplicationsPermissions.list', 'recruitment.jobApplications.jobApplicationsPermissions.qualify'], className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },

    { path: '/workflow', exact: true, name: t('workflow'), component: JobWorkflow, private: true, permission:'recruitment.referenceTables.manageReferenceTables', className:'breadcrumb-recruitment', fallbackPath: '/applications/myapplications' },
];
