import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import * as services from '../../../services/collaborator';
import {DELETE, FAILURE, GET, LIST, POST, PUT, ARCHIVE, UNARCHIVE, REQUEST, SUCCESS} from '../../../../../App/redux/ActionType';
import {
    COLLABORATOR_REDUCED_PROFILE,
    ACTIVE_COLLABORATOR,
    AGENCY,
    ARCHIVED_COLLABORATOR,
    CODE,
    COLLABORATOR,
    COLLABORATOR_DETAIL,
    COLLABORATOR_PROFILE,
    COLLABORATOR_STATUE,
    COLLABORATORS_AS_RESOURCES,
    CONTRACT_TYPE,
    JAVA_COLLABORATOR_TASKS,
    JOB,
    SERVICE,
    STATUE_PROFESSIONNEL,
    TASK_RAF,
    VALIDATION,
    COLLABORATORS_AGENCIES
} from '../../constants';
import {GET_CURRENT_COLLABORATOR} from '../../../../../redux/constants';

export function* get(payload) {
    try {
        const response = yield call(services.showCollaboratorService);
        yield put({ type: SUCCESS(GET(COLLABORATOR)), response });
        if (payload.onSuccess) payload.onSuccess(response);
    } catch (error) {
        yield put({ type: FAILURE(GET(COLLABORATOR)), error });
    }
}
export function* archived() {
    try {
        const response = yield call(services.archivedCollaboratorService);
        yield put({ type: SUCCESS(GET(ARCHIVED_COLLABORATOR)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(ARCHIVED_COLLABORATOR)), error });
    }
}

export function* active() {
    try {
        const response = yield call(services.activeCollaboratorService);
        yield put({ type: SUCCESS(GET(ACTIVE_COLLABORATOR)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(ACTIVE_COLLABORATOR)), error });
    }
}

export function* listActive(payload) {
    try {
        const response = yield call(services.listActiveCollaboratorService, payload.params);
        yield put({ type: SUCCESS(LIST(ACTIVE_COLLABORATOR)), response });
    } catch (error) {
        yield put({ type: FAILURE(LIST(ACTIVE_COLLABORATOR)), error });
    }
}

export function* post(payload) {
    try {
        const response = yield call(services.postCollaborator, payload.collaborator);
        yield put({ type: SUCCESS(POST(COLLABORATOR)), response });
        if (payload.onSuccess){
            payload.onSuccess(response);
        }
    } catch (error) {
        yield put({ type: FAILURE(POST(COLLABORATOR)), error });
        if (payload.onError){
            payload.onError(error);
        }
    }
}

export function* update(payload) {
    const currentCollab = yield select(state => state.Auth.collaborator);
    let response;
    try {
        if (payload.collaborator !== undefined) {
            response = yield call(services.putCollaborator, payload.id, payload.collaborator);
            if(payload.id === currentCollab.id){
                yield put({ type: SUCCESS(GET_CURRENT_COLLABORATOR), response:{...currentCollab, image:response.image} });
            }
        }
        else {
            response = yield call(services.putCollaborator, payload.id.id, payload.id);
        }
        yield put({ type: SUCCESS(PUT(COLLABORATOR)), response });
        if (payload.onSuccess){
            payload.onSuccess(response);
        }
    } catch (error) {
        yield put({ type: FAILURE(PUT(COLLABORATOR)), error });
    }
}

export function* destroy(payload) {
    try {
        yield call(services.destroyCollaborator, payload.id);
        yield put({ type: SUCCESS(DELETE(COLLABORATOR)), response: payload.id });
    } catch (error) {
        yield put({ type: FAILURE(DELETE(COLLABORATOR)), error });
    }
}

export function* show(payload) {
    try {
        const response = yield call(services.getCollaborator, payload.id);
        yield put({ type: SUCCESS(GET(COLLABORATOR_DETAIL)), response });

        if (payload.onSuccess) payload.onSuccess(response);
    } catch (error) {
        yield put({ type: FAILURE(GET(COLLABORATOR_DETAIL)), error });
    }
}

export function* getProfile(payload) {
    try {
        const response = yield call(services.getCollaboratorProfile, payload.id);
        yield put({ type: SUCCESS(GET(COLLABORATOR_PROFILE)), response });

        if (payload.onSuccess) payload.onSuccess(response);
    } catch (error) {
        yield put({ type: FAILURE(GET(COLLABORATOR_PROFILE)), error });
    }
}

export function* putProfileImage(payload) {
    try {
        const response = yield call(services.putCollaboratorProfileImage,payload.id, payload.image);
        yield put({ type: SUCCESS(GET(COLLABORATOR_PROFILE)), response });
        if (payload.onSuccess) payload.onSuccess(response);
    } catch (error) {
        yield put({ type: FAILURE(GET(COLLABORATOR_PROFILE)), error });
    }
}

export function* putCollaboratorReducedProfile(payload) {
    try {
        const response = yield call(services.putCollaboratorReducedProfile, payload.id, payload.reducedProfil);
        yield put({ type: SUCCESS(PUT(COLLABORATOR_REDUCED_PROFILE)), response });
        if (payload.onSuccess) payload.onSuccess(response);
    } catch (error) {
        yield put({ type: FAILURE(GET(COLLABORATOR_REDUCED_PROFILE)), error });
    }
}

export function* getCollaboratorTasksJava(payload) {
    try {
        const response = yield call(services.getCollaboratorTasksJava, payload.params, payload.collaboratorId);
        yield put({ type: SUCCESS(GET(JAVA_COLLABORATOR_TASKS)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(JAVA_COLLABORATOR_TASKS)), error });
    }
}

export function* getJob() {
    try {
        const response = yield call(services.getJobs);
        yield put({ type: SUCCESS(GET(JOB)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(JOB)), error });
    }
}

export function* getContractType() {
    try {
        const response = yield call(services.getContractType);
        yield put({ type: SUCCESS(GET(CONTRACT_TYPE)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(CONTRACT_TYPE)), error });
    }
}

export function* getService() {
    try {
        const response = yield call(services.getService);
        yield put({ type: SUCCESS(GET(SERVICE)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(SERVICE)), error });
    }
}

export function* Collaborators() {
    try {
        const response = yield call(services.getAgencies);
        yield put({ type: SUCCESS(GET(AGENCY)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(AGENCY)), error });
    }
}

export function* getCollaboratorStatue() {
    try {
        const response = yield call(services.getCollaboratorStatue);
        yield put({ type: SUCCESS(GET(COLLABORATOR_STATUE)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(COLLABORATOR_STATUE)), error });
    }
}

export function* getStatueProfessionnel() {
    try {
        const response = yield call(services.getStatueProfessionnel);
        yield put({ type: SUCCESS(GET(STATUE_PROFESSIONNEL)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(STATUE_PROFESSIONNEL)), error });
    }
}

export function* getCollaboratorsAsResources() {
    try {
        const response = yield call(services.getCollaboratorsAsResources);
        yield put({ type: SUCCESS(GET(COLLABORATORS_AS_RESOURCES)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(COLLABORATORS_AS_RESOURCES)), error });
    }
}

export function* getCodes() {
    try {
        const response = yield call(services.getCodesAndEmails);
        yield put({ type: SUCCESS(GET(CODE)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(CODE)), error });
    }
}

export function* destroyValidation(action) {
    const validationId = action.payload;
    try {
        yield call(services.destroyValidation, validationId);
        yield put({ type: SUCCESS(DELETE(VALIDATION)), response: validationId });
        if(action.onSuccess) action.onSuccess();
    } catch (error) {
        yield put({ type: FAILURE(DELETE(VALIDATION)), error });
    }
}

export function* updateTaskRaf(payload) {
    let response;
    try {
		response = yield call(services.putTaskRaf, payload.task);
        yield put({ type: SUCCESS(PUT(TASK_RAF)), response });
        if (payload.onSuccess){
            payload.onSuccess(response);
        }
    } catch (error) {
        yield put({ type: FAILURE(PUT(TASK_RAF)), error });
    }
}

export function* getCollaboratorsAgencies() {
    try {
        const response = yield call(services.getCollaboratorsAgencies);
        yield put({ type: SUCCESS(GET(COLLABORATORS_AGENCIES)), response });
    } catch (error) {
        yield put({ type: FAILURE(GET(COLLABORATORS_AGENCIES)), error });
    }
}

export function* archiveCollaborator(payload) {
    try {
        const response = yield call(services.archiveCollaborator, payload.id);
        yield put({ type: SUCCESS(ARCHIVE(COLLABORATOR)), response });
        if (payload.onSuccess) {
            payload.onSuccess(response);
        }
    } catch (error) {
        yield put({ type: FAILURE(ARCHIVE(COLLABORATOR)), error });
    }
}

export function* unarchiveCollaborator(payload) {
    try {
        const response = yield call(services.unarchiveCollaborator, payload.id);
        yield put({ type: SUCCESS(UNARCHIVE(ARCHIVED_COLLABORATOR)), response });
        if (payload.onSuccess) {
            payload.onSuccess(response);
        }
    } catch (error) {
        yield put({ type: FAILURE(UNARCHIVE(ARCHIVED_COLLABORATOR)), error });
    }
}

export function* watchCollaborators() {
    yield takeLatest(REQUEST(GET(COLLABORATOR)), get);
    yield takeLatest(REQUEST(GET(ARCHIVED_COLLABORATOR)), archived);
    yield takeLatest(REQUEST(GET(ACTIVE_COLLABORATOR)), active);
    yield takeLatest(REQUEST(LIST(ACTIVE_COLLABORATOR)), listActive);
    yield takeLatest(REQUEST(GET(JOB)), getJob);
    yield takeLatest(REQUEST(GET(CONTRACT_TYPE)), getContractType);
    yield takeLatest(REQUEST(GET(SERVICE)), getService);
    yield takeLatest(REQUEST(GET(AGENCY)), Collaborators);
    yield takeLatest(REQUEST(GET(COLLABORATOR_STATUE)), getCollaboratorStatue);
    yield takeLatest(REQUEST(GET(STATUE_PROFESSIONNEL)), getStatueProfessionnel);
    yield takeEvery(REQUEST(DELETE(COLLABORATOR)), destroy);
    yield takeLatest(REQUEST(POST(COLLABORATOR)), post);
    yield takeLatest(REQUEST(PUT(COLLABORATOR)), update);
    yield takeLatest(REQUEST(GET(COLLABORATOR_DETAIL)), show);
    yield takeLatest(REQUEST(GET(COLLABORATOR_PROFILE)), getProfile);
    yield takeLatest(REQUEST(PUT(COLLABORATOR_PROFILE)), putProfileImage);
    yield takeLatest(REQUEST(PUT(COLLABORATOR_REDUCED_PROFILE)), putCollaboratorReducedProfile);
    yield takeLatest(REQUEST(GET(JAVA_COLLABORATOR_TASKS)), getCollaboratorTasksJava);
    yield takeLatest(REQUEST(GET(COLLABORATORS_AS_RESOURCES)), getCollaboratorsAsResources);
    yield takeLatest(REQUEST(GET(CODE)), getCodes);
    yield takeEvery(REQUEST(DELETE(VALIDATION)), destroyValidation);
    yield takeLatest(REQUEST(PUT(TASK_RAF)), updateTaskRaf);
    yield takeLatest(REQUEST(GET(COLLABORATORS_AGENCIES)), getCollaboratorsAgencies);
    yield takeLatest(REQUEST(ARCHIVE(COLLABORATOR)), archiveCollaborator);
    yield takeLatest(REQUEST(UNARCHIVE(ARCHIVED_COLLABORATOR)), unarchiveCollaborator);
}
