import {
    CLEAR,
    DELETE,
    FAILURE,
    GET,
    LIST,
    POST,
    PUT,
    REQUEST,
    SUCCESS,
    MULTIPLE,
} from '../../../../redux/ActionType';
import cloneDeep from 'lodash/cloneDeep';
import {
    ALL_JOB_APPLICATION_STATUS,
    ATTACHMENT,
    DISPONIBILITY,
    JOB_APPLICATION_COMMENTS,
    JOB_APPLICATION_ASSIGNMENTS,
    JOB_APPLICATION_SOURCE,
    JOB_APPLICATION_STATUS,
    JOB_APPLICATION_TIMELINE,
    JOB_APPLICATIONS,
    JOB_APPLICATIONS_COUNT,
    REQUEST_UPDATE_JOB_APPLICATION,
} from '../../constants';
import moment from 'moment';

const initialState = {
    loading: false,
    list: [],
    jobApplication: { applicationDate: moment().format('YYYY-MM-DD') },
    errorMessage: null,
    count: 0,
    Status: { list: [], loading: false },
    Disponibility: { list: [] },
    Timeline: { list: [] },
    Comments: [],
    JobApplicationAssignments: [],
    Source: { list: [], loading: false },
    updatedMatrix: false,
    addedStatus: false,
    requestUpdateLoading: false,
};

function deleteSuccess(state, id) {
    const list = state.list.filter((e) => e.id !== id);
    state = Object.assign({}, state, { list, count: state.count - 1 });
    return state;
}

const JobApplications = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case REQUEST(LIST(JOB_APPLICATIONS)):
            return { ...state, loading: true };

        case SUCCESS(LIST(JOB_APPLICATIONS)):
            return {
                ...state,
                loading: false,
                list: response.list,
                count: response.count,
            };
        case FAILURE(LIST(JOB_APPLICATIONS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(LIST(JOB_APPLICATION_SOURCE)):
            return { ...state, Source: { ...state.Source, loading: true } };
        case SUCCESS(LIST(JOB_APPLICATION_SOURCE)):
            return { ...state, Source: { list: response, loading: false } };
        case FAILURE(LIST(JOB_APPLICATION_SOURCE)):
            return {
                ...state,
                Source: { ...state.Source, loading: false },
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(PUT(JOB_APPLICATION_SOURCE)):
            return { ...state, loading: true };
        case SUCCESS(PUT(JOB_APPLICATION_SOURCE)):
            return {
                ...state,
                loading: false,
                jobApplicationSource: cloneDeep(response),
            };
        case FAILURE(PUT(JOB_APPLICATION_SOURCE)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(POST(JOB_APPLICATION_SOURCE)):
            return { ...state, loading: true };
        case SUCCESS(POST(JOB_APPLICATION_SOURCE)):
            return { ...state, loading: false, jobApplicationSource: response };
        case FAILURE(POST(JOB_APPLICATION_SOURCE)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(DELETE(JOB_APPLICATION_SOURCE)):
            return { ...state, loading: true };
        case SUCCESS(DELETE(JOB_APPLICATION_SOURCE)):
            return deleteSuccess(state, response);
        case FAILURE(DELETE(JOB_APPLICATION_SOURCE)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case CLEAR(JOB_APPLICATION_SOURCE):
            return {
                ...state,
                Source: initialState.Source,
                errorMessage: null,
                list: [],
                loading: false,
            };

        case REQUEST(LIST(JOB_APPLICATION_STATUS)):
            return { ...state, Status: { ...state.Status, loading: true } };
        case SUCCESS(LIST(JOB_APPLICATION_STATUS)):
            return { ...state, Status: { list: response, loading: false } };
        case FAILURE(LIST(JOB_APPLICATION_STATUS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
                Status: { ...state.Status, loading: false },
            };

        case REQUEST(LIST(DISPONIBILITY)):
            return { ...state };
        case SUCCESS(LIST(DISPONIBILITY)):
            return { ...state, Disponibility: { list: response } };
        case FAILURE(LIST(DISPONIBILITY)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(PUT(JOB_APPLICATION_STATUS)):
            return { ...state, loading: true };
        case SUCCESS(PUT(JOB_APPLICATION_STATUS)):
            return { ...state, loading: false, jobApplicationStatus: {} };
        case FAILURE(PUT(JOB_APPLICATION_STATUS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(POST(JOB_APPLICATION_STATUS)):
            return { ...state, loading: true, addedStatus: true };
        case SUCCESS(POST(JOB_APPLICATION_STATUS)):
            return {
                ...state,
                loading: false,
                addedStatus: false,
                jobApplicationStatus: {},
            };
        case FAILURE(POST(JOB_APPLICATION_STATUS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
                addedStatus: false,
            };

        case REQUEST(DELETE(JOB_APPLICATION_STATUS)):
            return { ...state, loading: true };
        case SUCCESS(DELETE(JOB_APPLICATION_STATUS)):
            return deleteSuccess(state, response);
        case FAILURE(DELETE(JOB_APPLICATION_STATUS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case CLEAR(JOB_APPLICATION_STATUS):
            return {
                ...state,
                Status: initialState.Status,
                errorMessage: null,
                list: [],
                loading: false,
            };

        case REQUEST(GET(JOB_APPLICATIONS_COUNT)):
            return { ...state, loading: true };
        case SUCCESS(GET(JOB_APPLICATIONS_COUNT)):
            return { ...state, loading: false, Count: response };
        case FAILURE(GET(JOB_APPLICATIONS_COUNT)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(LIST(JOB_APPLICATION_TIMELINE)):
            return { ...state, loading: true };
        case SUCCESS(LIST(JOB_APPLICATION_TIMELINE)):
            return { ...state, loading: false, Timeline: { list: response } };
        case FAILURE(LIST(JOB_APPLICATION_TIMELINE)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(LIST(ATTACHMENT)):
            return { ...state, loading: true, attachments: [] };
        case SUCCESS(LIST(ATTACHMENT)):
            return { ...state, loading: false, attachments: response };
        case FAILURE(LIST(ATTACHMENT)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(LIST(JOB_APPLICATION_COMMENTS)):
            return { ...state, loading: true, Comments: [] };
        case SUCCESS(LIST(JOB_APPLICATION_COMMENTS)):
            return { ...state, loading: false, Comments: response };
        case FAILURE(LIST(JOB_APPLICATION_COMMENTS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(LIST(JOB_APPLICATION_ASSIGNMENTS)):
            return { ...state, loading: true, JobApplicationAssignments: [] };
        case SUCCESS(LIST(JOB_APPLICATION_ASSIGNMENTS)):
            return {
                ...state,
                loading: false,
                JobApplicationAssignments: response,
            };
        case FAILURE(LIST(JOB_APPLICATION_ASSIGNMENTS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(GET(JOB_APPLICATIONS)):
            return { ...state, loading: true };
        case SUCCESS(GET(JOB_APPLICATIONS)):
            return { ...state, loading: false, jobApplication: response };
        case FAILURE(GET(JOB_APPLICATIONS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(PUT(JOB_APPLICATIONS)):
            return { ...state, loading: true };
        case SUCCESS(PUT(JOB_APPLICATIONS)):
            return {
                ...state,
                loading: false,
                jobApplication: cloneDeep(response),
            };
        case FAILURE(PUT(JOB_APPLICATIONS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(POST(JOB_APPLICATIONS)):
            return { ...state, loading: true };
        case SUCCESS(POST(JOB_APPLICATIONS)):
            return { ...state, loading: false, jobApplication: response };
        case FAILURE(POST(JOB_APPLICATIONS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(DELETE(JOB_APPLICATIONS)):
            return { ...state, loading: true };
        case SUCCESS(DELETE(JOB_APPLICATIONS)):
            return {
                ...state,
                loading: false,
                jobApplication: initialState.jobApplication,
            };
        case FAILURE(DELETE(JOB_APPLICATIONS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(DELETE(MULTIPLE(JOB_APPLICATIONS))):
            return { ...state, loading: true };
        case SUCCESS(DELETE(MULTIPLE(JOB_APPLICATIONS))):
            return { ...state, loading: false };
        case FAILURE(DELETE(MULTIPLE(JOB_APPLICATIONS))):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case CLEAR(JOB_APPLICATIONS):
            return {
                ...state,
                jobApplication: initialState.jobApplication,
                errorMessage: null,
                list: [],
                loading: false,
                count: 0,
            };

        case REQUEST(DELETE(ATTACHMENT)):
            return { ...state, loading: true };
        case SUCCESS(DELETE(ATTACHMENT)):
            return deleteSuccess(state, response);
        case FAILURE(DELETE(ATTACHMENT)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(PUT(ALL_JOB_APPLICATION_STATUS)):
            return { ...state, loading: true, updatedMatrix: false };
        case SUCCESS(PUT(ALL_JOB_APPLICATION_STATUS)):
            return { ...state, loading: false, updatedMatrix: true };
        case FAILURE(PUT(ALL_JOB_APPLICATION_STATUS)):
            return {
                ...state,
                errorMessage: action.error.message,
                loading: false,
            };

        case REQUEST(POST(REQUEST_UPDATE_JOB_APPLICATION)):
            return { ...state, requestUpdateLoading: true };
        case SUCCESS(POST(REQUEST_UPDATE_JOB_APPLICATION)):
        case FAILURE(POST(REQUEST_UPDATE_JOB_APPLICATION)):
            return { ...state, requestUpdateLoading: false };

        default:
            return state;
    }
};

export { JobApplications };
export * from './myJobApplications';
export * from './archivedJobApplications';
export * from './favoriteJobApplications';
export * from './newJobApplications';
