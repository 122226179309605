import {call, put, select, takeLatest} from 'redux-saga/effects';

import {CITIES, COUNTRIES, TECHNOLOGIES} from '../../constants';
import {DELETE, FAILURE, LIST, POST, PUT, REQUEST, SUCCESS} from '../../../../redux/ActionType';
import * as services from '../../../services/shared';

export function* getCities(payload) {
    const city = yield select((state) => state.recruitment.Shared.Cities.list);
    try {
        if(!city.length){
            const response = yield call(services.getCities, payload.params);
            yield put({ type: SUCCESS(LIST(CITIES)), response });
        }
    } catch(error) {
        yield put({ type: FAILURE(LIST(CITIES)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* postCity(payload) {
    try {
        const response = yield call(services.postCity, payload.city);
        yield put({ type: SUCCESS(POST(CITIES)), response });
        if(payload.onSuccess) payload.onSuccess();
    } catch(error) {
        if (payload.onFailure) payload.onFailure(error.response);
        yield put({ type: FAILURE(POST(CITIES)), error });
    }
}

export function* updateCity(payload) {
    try {
        const response = yield call(services.putCity, payload.city);
        yield put({ type: SUCCESS(PUT(CITIES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(PUT(CITIES)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroyCity(payload) {
    try {
        yield call(services.destroyCity, payload.id);
        yield put({ type: SUCCESS(DELETE(CITIES)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(CITIES)), error });
        if (payload.onFailure) payload.onFailure(error.response);

    }
}

export function* getCountries() {
    const country = yield select((state)=>state.recruitment.Shared.Countries.list);
    try {
        if(!country.length){
            const response = yield call(services.getCountries);
            yield put({ type: SUCCESS(LIST(COUNTRIES)), response });
        }
    } catch(error) {
        yield put({ type: FAILURE(LIST(COUNTRIES)), error });
    }
}

export function* postCountry(payload) {
    try {
        const response = yield call(services.postCountry, payload.country);
        yield put({ type: SUCCESS(POST(COUNTRIES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        if (payload.onFailure) payload.onFailure(error.response);
        yield put({ type: FAILURE(POST(COUNTRIES)), error });
    }
}

export function* updateCountry(payload) {
    try {
        const response = yield call(services.putCountry, payload.country);
        yield put({ type: SUCCESS(PUT(COUNTRIES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(PUT(COUNTRIES)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroyCountry(payload) {
    try {
        yield call(services.destroyCountry, payload.id);
        yield put({ type: SUCCESS(DELETE(COUNTRIES)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(COUNTRIES)), error });
        if (payload.onFailure) payload.onFailure(error.response);

    }
}

export function* getTechnologies(payload) {
    try {
        const response = yield call(services.getTechnologies);
        yield put({ type: SUCCESS(LIST(TECHNOLOGIES)), response });
        if (payload.onSuccess)payload.onSuccess(response.list);
    } catch(error) {
        yield put({ type: FAILURE(LIST(TECHNOLOGIES)), error });
    }
}

export function* postTechnology(payload) {
    try {
        const response = yield call(services.postTechnology, payload.technology);
        yield put({ type: SUCCESS(POST(TECHNOLOGIES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        if (payload.onFailure) payload.onFailure(error.response);
        yield put({ type: FAILURE(POST(TECHNOLOGIES)), error });
    }
}

export function* updateTechnology(payload) {
    try {
        const response = yield call(services.putTechnology, payload.technology);
        yield put({ type: SUCCESS(PUT(TECHNOLOGIES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(PUT(TECHNOLOGIES)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* destroyTechnology(payload) {
    try {
        yield call(services.destroyTechnology, payload.id);
        yield put({ type: SUCCESS(DELETE(TECHNOLOGIES)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(DELETE(TECHNOLOGIES)), error });
        if (payload.onFailure) payload.onFailure(error.response);
    }
}

export function* watchShared() {
    yield takeLatest(REQUEST(LIST(CITIES)), getCities);
    yield takeLatest(REQUEST(POST(CITIES)), postCity);
    yield takeLatest(REQUEST(PUT(CITIES)), updateCity);
    yield takeLatest(REQUEST(DELETE(CITIES)), destroyCity);

    yield takeLatest(REQUEST(LIST(COUNTRIES)), getCountries);
    yield takeLatest(REQUEST(POST(COUNTRIES)), postCountry);
    yield takeLatest(REQUEST(PUT(COUNTRIES)), updateCountry);
    yield takeLatest(REQUEST(DELETE(COUNTRIES)), destroyCountry);

    yield takeLatest(REQUEST(LIST(TECHNOLOGIES)), getTechnologies);
    yield takeLatest(REQUEST(POST(TECHNOLOGIES)), postTechnology);
    yield takeLatest(REQUEST(PUT(TECHNOLOGIES)), updateTechnology);
    yield takeLatest(REQUEST(DELETE(TECHNOLOGIES)), destroyTechnology);
}
